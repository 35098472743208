<template>
  <v-row class="mt-6">
    <v-col>
      <v-expansion-panels focusable>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Neues Passwort anfordern</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-form
              @submit.prevent="onForgotPassword"
              ref="forgotPwForm"
              :value="valid"
            >
              <v-text-field
                v-model="mail"
                placeholder="E-Mail"
                :rules="[validateMail]"
                class="mt-4 mb-4"
                clearable
                id="forgotMail"
              >
              </v-text-field>
              <div class="d-flex justify-center">
                <v-btn type="submit" color="primary" rounded :disabled="!valid"
                  >Neues Passwort anfordern</v-btn
                >
              </div>
            </v-form>
          </v-expansion-panel-content>
            <v-alert
              :type="alertType"
              v-if="alert"
            >{{ alert }}</v-alert>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import axios from '../../utils/api';
import { mapState } from 'vuex';
export default {
  data: () => ({
    mail: '',
    valid: false,
    alert: false,
    alertType: null,
  }),
  methods: {
    validateMail(input) {
      const mailRegex = /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~\-äöüÄÖÜß]+@[a-zA-Z0-9-äöüÄÖÜß]+(?:\.[a-zA-Z0-9-äöüÄÖÜß]+)*$/;
      let result = input ? input.match(mailRegex) : null;
      result === null
        ? (result = 'Bitte geben Sie eine gültige E-Mailadresse ein')
        : (result = true);
      result === true ? (this.valid = true) : (this.valid = false);
      return result;
    },
    async onForgotPassword() {
      if (this.$refs.forgotPwForm.validate()) {
        this.$store.dispatch('setLoadingTrue');
        let formData = new FormData();
        formData.append('email', this.mail);
        try {
          const res = await axios.post('/API-USER/PWvergessen', formData);
          if (res.data == 'Diese Emailadresse ist nicht registriert!') {
            this.alertType = 'error';
            this.alert = 'Diese E-Mail-Adresse ist nicht registriert!';
          } else if (res.data.includes('Ein neues Passwort wurde versendet')){
            const mailRegex = /[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~\-äöüÄÖÜß]+@[a-zA-Z0-9-äöüÄÖÜß]+(?:\.[a-zA-Z0-9-äöüÄÖÜß]+)*$/;
            const mail = res.data.match(mailRegex);
            console.log('mail: ', mail);
            this.alertType = 'success';
            this.alert = mail ? `Ihr neues Passwort wurde per E-Mail an ${mail} verschickt.` : "Ihr neues Passwort wurde per E-Mail verschickt.";
          }
          setTimeout(() => {
            this.alert = false;
            this.alertType = null;
          }, 20000)
        } catch (err) {
          console.error(err);
        }
        this.$store.dispatch('setLoadingFalse');
      }
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
};
</script>

<style scoped>

.v-expansion-panels {
  --login-border-radius: 15px;
  border-radius: var(--login-border-radius);
}

  .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
    border-radius: var(--login-border-radius);
  }

.v-expansion-panels > *:last-child {
  border-bottom-left-radius: var(--login-border-radius);
    border-bottom-right-radius: var(--login-border-radius);
}

.v-expansion-panels > *:first-child {
  border-top-left-radius: var(--login-border-radius);
    border-top-right-radius: var(--login-border-radius);
}

.v-expansion-panel-header {
  border-top-left-radius: var(--login-border-radius);
  border-top-right-radius: var(--login-border-radius);
}

</style>
