<template>
  <div>
    <v-container>
      <h1 class="hl">
        Willkommen im Portal zur <br class="d-none d-lg-block" />Erfassung von
        Wassernutzungen im Bereich des Erftverbandes, <br />{{
          address + ' ' + username
        }}
      </h1>
      <v-sheet elevation="1" class="pa-12 mt-8">
        <v-row dense>
          <v-col class="justify-end d-flex">
            <a :href="hrefAttr" rel="noreferrer" target="_blank"
              >E-Mail an den Erftverband</a
            >
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <h3>Ihre Formulare</h3>
          </v-col>
        </v-row>
        <v-container
          fluid
          v-for="category in categories"
          :key="category.id"
          class="ps-0"
        >
          <h4>{{ category.name }}</h4>
          <v-row dense v-for="betrieb in category.betriebe" :key="betrieb.name">
            <v-col
              cols="12"
              sm="4"
              class="d-flex align-center"
              v-if="Object.keys(betrieb.forms).length > 0"
            >
              <span>{{ betrieb.name }} ({{ betrieb.mitglied }})</span>
            </v-col>
            <v-col v-for="form in betrieb.forms" :key="form.id" class="d-flex">
              <v-btn
                @click="handleFormClick(form)"
                :color="
                  form.status === 'pruefung' || form.status === 'erste_freigabe'
                    ? 'primary'
                    : form.status === 'freigegeben'
                    ? 'green'
                    : form.status === 'korrektur'
                    ? 'error'
                    : 'nicht ausgefüllt'
                    ? ''
                    : ''
                "
                class="form__btn"
              >
                {{ form.year }}
              </v-btn>
              <v-btn depressed icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{ ...tooltip }">mdi-calendar-edit </v-icon>
                  </template>
                  <span>zuletzt bearbeitet: {{ form.lastChanged }}</span
                  ><span v-if="form.lastChangedUser">
                    von: {{ form.lastChangedUser }}</span
                  >
                </v-tooltip>
              </v-btn>
              <v-btn depressed icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{ ...tooltip }">
                      mdi-email-fast-outline
                    </v-icon>
                  </template>
                  <!-- <span>Mail versandt: {{ form.mailSent }}</span> -->
                  <div v-html="form.mailHistory"></div>
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <v-col class="d-flex align-center">
            <v-icon color="#f5f5f5">mdi-circle</v-icon
            ><span> = nicht ausgefüllt</span>
          </v-col>
          <v-col class="d-flex align-center">
            <v-icon color="primary">mdi-circle</v-icon
            ><span> = in Prüfung</span>
          </v-col>
          <v-col class="d-flex align-center">
            <v-icon color="error">mdi-circle</v-icon
            ><span> = Korrektur erforderlich</span>
          </v-col>
          <v-col class="d-flex align-center">
            <v-icon color="green">mdi-circle</v-icon
            ><span> = abgeschlossen</span>
          </v-col>
          <v-col class="d-flex align-center">
            <v-icon>mdi-calendar-edit</v-icon><span> = letzte Bearbeitung</span>
          </v-col>
        </v-row>
      </v-sheet>
      <v-divider class="mt-5 mb-5"></v-divider>
    </v-container>
  </div>
</template>

<script>
import { getUserInfo } from '../../utils/auth';
import { mapState } from 'vuex';
import axios from '../../utils/api';
import helpers from '../helpers.js';
const { generateTimestamp } = helpers;

export default {
  data: () => ({
    categories: [],
    address: '',
    mail: '',
    year: '',
    memberNo: '',
    addressData: {},
  }),
  computed: {
    ...mapState({
      username: (state) => state.username,
      usertype: (state) => state.usertype,
      showNavDrawer: (state) => state.showNavDrawer,
      addressDialog: (state) => state.addressDialog,
      firstLogin: (state) => state.firstLogin,
    }),
    hrefAttr() {
      const href =
        'mailto:statistik@erftverband.de?subject=Mitgliedsnummer: ' +
        this.memberNo;
      return href;
    },
  },
  mounted() {
    this.beforeLoad();
    this.$forceUpdate();
  },
  methods: {
    generateTimestamp,
    currentYear() {
      return new Date().getFullYear();
    },
    async beforeLoad() {
      const userInfo = getUserInfo();
      const username = userInfo.ANSPRECH;
      const address = userInfo.ANSPRECH_ANREDE;
      this.address = address;
      this.$store.dispatch('setUser', username);
      this.memberNo = userInfo.MIMITGL;
      this.mail = userInfo.MAIL;
      this.year = this.currentYear();

      try {
        let res = await axios({
          url: `/API-USER/dashboard?mail=${this.mail}&jahr=`,
          method: 'GET',
        });
        let data = res.data;
        this.addressData = {
          title: data[0].ANSPRECH_ANREDE,
          firstName: data[0].ANSPRECH_VN,
          lastName: data[0].ANSPRECH,
          location1: data[0].BETRIEB1,
          location2: data[0].BETRIEB2,
          companyPart1: data[0].FIRMA1,
          companyPart2: data[0].FIRMA2,
          companyPart3: data[0].FIRMA3,
          email: data[0].MAIL,
          mimitgl: data[0].MIMITGL,
          tel: data[0].TELEFON,
          street: data[0].STRASSE1,
          zip: data[0].PLZ1,
          city: data[0].ORT1,
        };
        this.$store.dispatch('setAddressData', this.addressData);
        if (this.firstLogin === true) {
          this.$store.commit('setAddressDialog', {
            show: true,
            persistent: true,
          });
        }
        console.log('res.data ', data);
        this.buildDashboard(data);
      } catch (err) {
        console.error('Dashboard Request Error:', err);
      }
    },
    buildDashboard(data) {
      data.forEach((el) => {
        const formattedDatetime = new Date(el.lastchange).toLocaleString(
          'de-DE'
        );
        let mailHistory = el.mailhistory;
        mailHistory = mailHistory.replaceAll(/<li>,/g, '<li>');
        mailHistory = mailHistory.replaceAll(
          /(\d{4})-(\d{1,})-(\d{1,})/g,
          '$3.$2.$1'
        ); // format dates
        const newForm = {
          year: el.BEFRAG,
          name: '',
          status: el.status,
          id: '',
          mitglied: decodeURI(el.MIMITGL),
          firma: el.FIRMA1,
          firma2: el.FIRMA2,
          firma3: el.FIRMA3,
          betrieb: el.BETRIEB1,
          lastChanged: formattedDatetime,
          lastChangedUser: el.lastchange_user,
          mailHistory: mailHistory,
          // mailSent: mailSent,
          // mailTimestamp: mailTimestamp,
        };
        let firmaCommaBetrieb = '';
        el.FIRMA1 ? (firmaCommaBetrieb += el.FIRMA1) : null;
        el.FIRMA2 ? (firmaCommaBetrieb += ', ' + el.FIRMA2) : null;
        el.FIRMA3 ? (firmaCommaBetrieb += ', ' + el.FIRMA3) : null;
        el.BETRIEB1 ? (firmaCommaBetrieb += ', ' + el.BETRIEB1) : null;
        let mitgliedsNr = decodeURI(el.MIMITGL);
        if (el.FRAGEB_NR === '1') {
          const category = 'Öffentliche Wasserversorgung';
          newForm.name = 'form_oeff';
          if (!this.categories.some((e) => e.name === category)) {
            this.categories.push({ name: category, betriebe: {} });
          }
          this.categories.map((cat) => {
            if (cat.name === category) {
              if (!cat.betriebe[firmaCommaBetrieb]) {
                cat.betriebe[firmaCommaBetrieb] = {
                  name: firmaCommaBetrieb,
                  mitglied: mitgliedsNr,
                };
              }
              if (!cat.betriebe[firmaCommaBetrieb].forms) {
                cat.betriebe[firmaCommaBetrieb]['forms'] = {};
              }
              newForm.id =
                'form_oeff' +
                (Object.keys(cat.betriebe[firmaCommaBetrieb].forms).length + 1);
              cat.betriebe[firmaCommaBetrieb].forms[newForm.id] = newForm;
            }
          });
        } else if (el.FRAGEB_NR === '2') {
          const category = 'Gewerbliche Betriebe';
          newForm.name = 'form_gew';
          if (!this.categories.some((e) => e.name === category)) {
            this.categories.push({ name: category, betriebe: {} });
          }
          this.categories.map((cat) => {
            if (cat.name === category) {
              if (!cat.betriebe[firmaCommaBetrieb]) {
                cat.betriebe[firmaCommaBetrieb] = {
                  name: firmaCommaBetrieb,
                  mitglied: mitgliedsNr,
                };
              }
              if (!cat.betriebe[firmaCommaBetrieb].forms) {
                cat.betriebe[firmaCommaBetrieb]['forms'] = {};
              }
              newForm.id =
                'form_gew' +
                (Object.keys(cat.betriebe[firmaCommaBetrieb].forms).length + 1);
              cat.betriebe[firmaCommaBetrieb].forms[newForm.id] = newForm;
            }
          });
        } else if (el.FRAGEB_NR === '3') {
          const category = 'Kies- und Betonwerke';
          newForm.name = 'form_kibe';
          if (!this.categories.some((e) => e.name === category)) {
            this.categories.push({ name: category, betriebe: {} });
          }
          this.categories.map((cat) => {
            if (cat.name === category) {
              if (!cat.betriebe[firmaCommaBetrieb]) {
                cat.betriebe[firmaCommaBetrieb] = {
                  name: firmaCommaBetrieb,
                  mitglied: mitgliedsNr,
                };
              }
              if (!cat.betriebe[firmaCommaBetrieb].forms) {
                cat.betriebe[firmaCommaBetrieb]['forms'] = {};
              }
              newForm.id =
                'form_kibe' +
                (Object.keys(cat.betriebe[firmaCommaBetrieb].forms).length + 1);
              cat.betriebe[firmaCommaBetrieb].forms[newForm.id] = newForm;
            }
          });
        } else if (el.FRAGEB_NR === '4') {
          const category = 'Sport-/Freizeitanlagen';
          newForm.name = 'form_spofr';
          if (!this.categories.some((e) => e.name === category)) {
            this.categories.push({ name: category, betriebe: {} });
          }
          this.categories.map((cat) => {
            if (cat.name === category) {
              if (!cat.betriebe[firmaCommaBetrieb]) {
                cat.betriebe[firmaCommaBetrieb] = {
                  name: firmaCommaBetrieb,
                  mitglied: mitgliedsNr,
                };
              }
              if (!cat.betriebe[firmaCommaBetrieb].forms) {
                cat.betriebe[firmaCommaBetrieb]['forms'] = {};
              }
              newForm.id =
                'form_spofr' +
                (Object.keys(cat.betriebe[firmaCommaBetrieb].forms).length + 1);
              cat.betriebe[firmaCommaBetrieb].forms[newForm.id] = newForm;
            }
          });
        } else if (el.FRAGEB_NR === '5') {
          const category = 'Landwirtschaft';
          newForm.name = 'form_land';
          if (!this.categories.some((e) => e.name === category)) {
            this.categories.push({ name: category, betriebe: {} });
          }
          this.categories.map((cat) => {
            if (cat.name === category) {
              if (!cat.betriebe[firmaCommaBetrieb]) {
                cat.betriebe[firmaCommaBetrieb] = {
                  name: firmaCommaBetrieb,
                  mitglied: mitgliedsNr,
                };
              }
              if (!cat.betriebe[firmaCommaBetrieb].forms) {
                cat.betriebe[firmaCommaBetrieb]['forms'] = {};
              }
              newForm.id =
                'form_land' +
                (Object.keys(cat.betriebe[firmaCommaBetrieb].forms).length + 1);
              cat.betriebe[firmaCommaBetrieb].forms[newForm.id] = newForm;
            }
          });
        }
      });
    },
    handleFormClick(form) {
      let firmaCommaBetrieb = '';
      form.firma ? (firmaCommaBetrieb += form.firma) : null;
      form.firma2 ? (firmaCommaBetrieb += ', ' + form.firma2) : null;
      form.firma3 ? (firmaCommaBetrieb += ', ' + form.firma3) : null;
      form.betrieb ? (firmaCommaBetrieb += ', ' + form.betrieb) : null;
      this.$store.commit('setFirmaCommaBetrieb', firmaCommaBetrieb);
      this.$store.commit('setMitglied', form.mitglied);
      if (form.name === 'form_spofr' && form.mitglied.startsWith('5')) {
        this.$router.push({
          name: form.name + '_land',
          params: { username: form.mitglied, year: form.year },
        });
      } else {
        this.$router.push({
          name: form.name,
          params: { username: form.mitglied, year: form.year },
        });
      }
    },
  },
};
</script>
