<template>
  <div>
    <v-form
      @submit.prevent="onSubmit"
      ref="form"
      lazy-validation
      v-model="valid"
      :readonly="readonly"
      v-if="dataLoaded && !printing"
    >
      <v-container>
        <v-container>
          <v-row>
            <v-col>
              <h1>Erfassung von Wassernutzungen im Bereich des Erftverbandes</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>Landwirtschaft</h2>
            </v-col>
            <v-col
              v-if="mode !== 'first' && status !== 'ausstehend'"
              v-show="!printing"
            >
              <FirstSubBtn
                formName="form_land"
                :username="user"
                :year="year"
              ></FirstSubBtn>
            </v-col>
          </v-row>
        </v-container>
        <FormLegend
          :saveData="saveData"
          :lg="lg"
          :year="year"
          :readonly="readonly"
        />
  
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <v-row>
            <v-col cols="12">
              <h3>Eigengewinnung</h3>
              <span>Grundwasser aus Brunnen</span>
            </v-col>
          </v-row>
          <FormRowMulti
            v-for="(gewinnungsanlage, index) in gewinnungsanlagen"
            :key="gewinnungsanlage.id"
            :index="index"
            :data="gewinnungsanlagen"
            :dataSingle="gewinnungsanlage"
            :dataLy="gewinnungsanlagenLy"
            labelInputLabel="Bezeichnung der Wassergewinnungsanlage"
            fieldGroup="gewinnungsanlagen"
            idFieldName="FOENR_GES"
            :readonly="readonly"
            deletable="true"
            type="EG"
            :year="year"
            :lg="lg"
            :val1="gewinnungsanlage.value1"
            :val2="gewinnungsanlage.value2"
            val1LyName="FOEMENG_2"
            val2LyName="FOEMENG_1"
            sumLyName="FOEMENG_G"
            :rights="gewinnungsanlage.rights"
            @toggleMenu="toggleMenu"
            @updateValue="updateValue"
            @savePreviousId="savePreviousId"
            @sendIdUpdateRequest="sendIdUpdateRequest"
            @deleteEgRow="deleteEgRow"
            @handleInput="handleInput"
            @formatDigitsOnly="formatDigitsOnly"
          />
          <FormRowMultiSum
            :group="gewinnungsanlagen"
            :lg="lg"
            :readonly="readonly"
            sumText="Summe Eigengewinnung"
            :value1="calculateSum(gewinnungsanlagen, '2').toLocaleString('de-DE')"
            :value2="calculateSum(gewinnungsanlagen, '1').toLocaleString('de-DE')"
            :sum="
              calculateSum(gewinnungsanlagen, 'total').toLocaleString('de-DE')
            "
            :value1Ly="GWFOERD_2_ly"
            :value2Ly="GWFOERD_1_ly"
            :sumLy="GWFOERD_G_ly"
            :year="year"
            :addBtn="true"
            @add="add"
          />
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-expansion-panels class="oberfl">
          <v-expansion-panel>
            <v-expansion-panel-header
              ><div>
                <h3 class="expansion__hl">Eigengewinnung</h3>
                <span>Oberflächenwasser</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="0">
                <FormRowMulti
                  v-for="(wasserlauf, index) in wasserlaeufe"
                  :key="wasserlauf.id"
                  :index="index"
                  :data="wasserlaeufe"
                  :dataSingle="wasserlauf"
                  :dataLy="wasserlaeufeLy"
                  labelInputLabel="Bezeichnung der Wassergewinnungsanlage"
                  fieldGroup="wasserlaeufe"
                  idFieldName="FOENR_GES"
                  :readonly="readonly"
                  deletable="true"
                  type="EGO"
                  :year="year"
                  :lg="lg"
                  :val1="wasserlauf.value1"
                  :val2="wasserlauf.value2"
                  val1LyName="FOEMENG_2"
                  val2LyName="FOEMENG_1"
                  sumLyName="FOEMENG_G"
                  :rights="wasserlauf.rights"
                  @toggleMenu="toggleMenu"
                  @updateValue="updateValue"
                  @savePreviousId="savePreviousId"
                  @sendIdUpdateRequest="sendIdUpdateRequest"
                  @deleteEgRow="deleteEgRow"
                  @handleInput="handleInput"
                  @formatDigitsOnly="formatDigitsOnly"
                />
                <FormRowMultiSum
                  :group="wasserlaeufe"
                  :lg="lg"
                  :readonly="readonly"
                  sumText="Summe Eigengewinnung"
                  :value1="
                    calculateSum(wasserlaeufe, '2').toLocaleString('de-DE')
                  "
                  :value2="
                    calculateSum(wasserlaeufe, '1').toLocaleString('de-DE')
                  "
                  :sum="
                    calculateSum(wasserlaeufe, 'total').toLocaleString('de-DE')
                  "
                  :value1Ly="OWFOERD_2_ly"
                  :value2Ly="OWFOERD_1_ly"
                  :sumLy="OWFOERD_G_ly"
                  :year="year"
                  :addBtn="true"
                  @add="add"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="mt-5 mb-5"></v-divider>
  
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <div class="icon-wrapper">
            <v-icon>mdi-equal</v-icon>
          </div>
          <FormRowMultiSum
            :lg="lg"
            :readonly="readonly"
            sumText="Summe Eigengewinnung"
            :value1="
              (
                calculateSum(gewinnungsanlagen, '2') +
                calculateSum(wasserlaeufe, '2')
              ).toLocaleString('de-DE')
            "
            :value2="
              (
                calculateSum(gewinnungsanlagen, '1') +
                calculateSum(wasserlaeufe, '1')
              ).toLocaleString('de-DE')
            "
            :sum="
              (
                calculateSum(gewinnungsanlagen, 'total') +
                calculateSum(wasserlaeufe, 'total')
              ).toLocaleString('de-DE')
            "
            :value1Ly="wasseraufkommen_2_ly"
            :value2Ly="wasseraufkommen_1_ly"
            :sumLy="wasseraufkommen_G_ly"
            :year="year"
          />
        </v-card>
  
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <div class="icon-wrapper">
            <v-icon>mdi-plus</v-icon>
          </div>
          <v-row dense>
            <v-col cols="12">
              <h3>Fremdbezug</h3>
            </v-col>
          </v-row>
          <FormRowMulti
            v-for="(wasserlieferant, index) in wasserlieferanten"
            :key="wasserlieferant.id"
            :index="index"
            :data="wasserlieferanten"
            :dataSingle="wasserlieferant"
            :dataLy="wasserlieferantenLy"
            labelInputLabel="Bezeichnung des Wasserlieferanten"
            fieldGroup="wasserlieferanten"
            :readonly="readonly"
            deletable="true"
            :year="year"
            :lg="lg"
            :val1="wasserlieferant.value1"
            :val2="wasserlieferant.value2"
            :val1Ly="
              wasserlieferantenLy[index]
                ? wasserlieferantenLy[index].value1
                : null
            "
            :val2Ly="
              wasserlieferantenLy[index]
                ? wasserlieferantenLy[index].value2
                : null
            "
            :sumLy="
              wasserlieferantenLy[index] ? wasserlieferantenLy[index].sum : null
            "
            :variant="wasserlieferant.bezugsart"
            variantName="bezugsart"
            variantLabel="Bezugsart"
            variantHint="Bezugsart (e = Ersatzwasser, a = außerhalb EV, s = sonstiges Wasser)"
            @toggleMenu="toggleMenu"
            @updateValue="updateValue"
            @savePreviousId="savePreviousId"
            @sendIdUpdateRequest="sendIdUpdateRequest"
            @deleteEgRow="deleteEgRow"
            @handleInput="handleInput"
            @formatDigitsOnly="formatDigitsOnly"
            @remove="remove"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  status !== 'freigegeben' ? add(wasserlieferanten, true) : ''
                "
                fab
                dark
                small
                color="primary"
                v-on="on"
                v-bind="attrs"
                :disabled="status === 'freigegeben' || readonly ? true : false"
              >
                <v-icon v-show="!printing">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Zeile hinzufügen</span>
          </v-tooltip>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card
          class="ps-3 pe-3 pt-3 pb-3 blue__border"
          elevation="1"
          id="eigen-fremd-summe"
        >
          <div class="icon-wrapper">
            <v-icon>mdi-equal</v-icon>
          </div>
          <FormRowMultiSum
            :group="wasserlieferanten"
            :lg="lg"
            :readonly="readonly"
            sumText="∑ Grundwasser, Oberflächenwasser & Fremdbezug"
            :value1="calculateWaterOccurrenceLand('2').toLocaleString('de-DE')"
            :value2="calculateWaterOccurrenceLand('1').toLocaleString('de-DE')"
            :sum="calculateWaterOccurrenceLand('total').toLocaleString('de-DE')"
            :value1Ly="wasseraufkommen_2_ly"
            :value2Ly="wasseraufkommen_1_ly"
            :sumLy="wasseraufkommen_G_ly"
            :year="year"
            :addBtn="false"
            @add="add"
          />
          <v-row v-if="eigenFremdSummeHint !== ''">
            <v-col>
              <v-alert type="error">{{ eigenFremdSummeHint }}</v-alert>
              <v-checkbox
                label="Abweichung bestätigen"
                v-model="eigenFremdSummeCheck"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <h3>Aufgliederung der Nutzung</h3>
          <FormRow
            headline="Haus- u. Hofversorgung"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="hausversorgung"
            val1Name="HAUS_2"
            :value1Comment="hausversorgung.value1Comment"
            :val1_ly="HAUS_2_ly"
            val2Name="HAUS_1"
            :value2Comment="hausversorgung.value2Comment"
            :val2_ly="HAUS_1_ly"
            :note="hausversorgung.note"
            noteFieldName="note"
            :val1Dif="hausversorgung.HAUS_2Dif"
            :val2Dif="hausversorgung.HAUS_1Dif"
            :sum="HAUS_G"
            :sum_ly="HAUS_G_ly"
            fieldGroup="hausversorgung"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <FormRow
            headline="Viehbewirtschaftungen"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="viehbewirtschaftungen"
            val1Name="VIEH_2"
            :value1Comment="viehbewirtschaftungen.value1Comment"
            :val1_ly="VIEH_2_ly"
            val2Name="VIEH_1"
            :value2Comment="viehbewirtschaftungen.value2Comment"
            :val2_ly="VIEH_1_ly"
            :note="viehbewirtschaftungen.note"
            noteFieldName="note"
            :val1Dif="viehbewirtschaftungen.VIEH_2Dif"
            :val2Dif="viehbewirtschaftungen.VIEH_1Dif"
            :sum="VIEH_G"
            :sum_ly="VIEH_G_ly"
            fieldGroup="viehbewirtschaftungen"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <FormRow
            headline="Teichbefüllung"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="teichbefuellung"
            val1Name="TEICH_2"
            :value1Comment="teichbefuellung.value1Comment"
            :val1_ly="TEICH_2_ly"
            val2Name="TEICH_1"
            :value2Comment="teichbefuellung.value2Comment"
            :val2_ly="TEICH_1_ly"
            :note="teichbefuellung.note"
            noteFieldName="note"
            :val1Dif="teichbefuellung.TEICH_2Dif"
            :val2Dif="teichbefuellung.TEICH_1Dif"
            :sum="TEICH_G"
            :sum_ly="TEICH_G_ly"
            fieldGroup="teichbefuellung"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <FormRow
            headline="Feldberegnung"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="feldberegnung"
            val1Name="FELD_2"
            :value1Comment="feldberegnung.value1Comment"
            :val1_ly="FELD_2_ly"
            val2Name="FELD_1"
            :value2Comment="feldberegnung.value2Comment"
            :val2_ly="FELD_1_ly"
            :note="feldberegnung.note"
            noteFieldName="note"
            :val1Dif="feldberegnung.FELD_2Dif"
            :val2Dif="feldberegnung.FELD_1Dif"
            :sum="FELD_G"
            :sum_ly="FELD_G_ly"
            fieldGroup="feldberegnung"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <FormRow
            headline="Verdunstung"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="verdunstung"
            val1Name="VERDUNST_2"
            :value1Comment="verdunstung.value1Comment"
            :val1_ly="VERDUNST_2_ly"
            val2Name="VERDUNST_1"
            :value2Comment="verdunstung.value2Comment"
            :val2_ly="VERDUNST_1_ly"
            :note="verdunstung.note"
            noteFieldName="note"
            :val1Dif="verdunstung.VERDUNST_2Dif"
            :val2Dif="verdunstung.VERDUNST_1Dif"
            :sum="VERDUNST_G"
            :sum_ly="VERDUNST_G_ly"
            fieldGroup="verdunstung"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <FormRow
            headline="Abgabe ohne eigene Nutzung"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="abgabe"
            :labelComment="abgabe.labelComment"
            labelHint="Abgabeziel"
            val1Name="abgabe_2"
            :value1Comment="abgabe.value1Comment"
            :val1_ly="abgabe_2_ly"
            val2Name="abgabe_1"
            :value2Comment="abgabe.value2Comment"
            :val2_ly="abgabe_1_ly"
            :note="abgabe.note"
            noteFieldName="note"
            :val1Dif="abgabe.abgabe_2Dif"
            :val2Dif="abgabe.abgabe_1Dif"
            :sum="abgabe_G"
            :sum_ly="abgabe_G_ly"
            fieldGroup="abgabe"
            labelFieldName="ABGZIEL"
            labelFieldMaxLength="40"
            :variantFieldName="usertype === 1 ? 'ABGART' : null"
            variantFieldMaxLength="1"
            variantFieldLabel="Abgabeart"
            variantHint="Abgabeart (e = Ersatzwasser, a = außerhalb EV)"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <FormRow
            headline="Einleitung ohne Nutzung"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="einleitung"
            :labelComment="einleitung.labelComment"
            labelHint="Kennung Einleitung"
            val1Name="EINLEIT_2"
            :value1Comment="einleitung.value1Comment"
            :val1_ly="EINLEIT_2_ly"
            val2Name="EINLEIT_1"
            :value2Comment="einleitung.value2Comment"
            :val2_ly="EINLEIT_1_ly"
            noteFieldName="EINLEIT_note"
            :val1Dif="einleitung.EINLEIT_2Dif"
            :val2Dif="einleitung.EINLEIT_1Dif"
            :sum="EINLEIT_G"
            :sum_ly="EINLEIT_G_ly"
            fieldGroup="einleitung"
            labelFieldName="EINLEIT_label"
            labelFieldMaxLength="40"
            :variantFieldName="usertype === 1 ? 'EINLEIT_KZ' : null"
            variantFieldMaxLength="1"
            variantFieldLabel="Kennung Einleitung"
            variantHint="a = Anreicherung, e = Einleitung ohne Nutzung"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <FormRow
            headline="Einleitung in den Untergrund"
            :data="untergrund"
            val1Name="UNTERGR_2"
            :value1Comment="untergrund.value1Comment"
            :val1_ly="UNTERGR_2_ly"
            val2Name="UNTERGR_1"
            :value2Comment="untergrund.value2Comment"
            :val2_ly="UNTERGR_1_ly"
            :note="untergrund.note"
            noteFieldName="note"
            :val1Dif="untergrund.UNTERGR_2Dif"
            :val2Dif="untergrund.UNTERGR_1Dif"
            :sum="UNTERGR_G"
            :sum_ly="UNTERGR_G_ly"
            fieldGroup="untergrund"
            :year="year"
            :lg="lg"
            :readonly="readonly || usertype !== 1 ? true : false"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <v-row v-if="usertype === 1">
            <v-col>
              <span
                >Schollennummer zur Abwassereinleitung in den Untergrund:
                {{ UNTERGR_NR }}</span
              >
            </v-col>
          </v-row>
          <FormRow
            headline="Einleitung in einen Vorfluter"
            :data="vorfluter"
            val1Name="VORFLUT_2"
            :value1Comment="vorfluter.value1Comment"
            :val1_ly="VORFLUT_2_ly"
            val2Name="VORFLUT_1"
            :value2Comment="vorfluter.value2Comment"
            :val2_ly="VORFLUT_1_ly"
            :note="vorfluter.note"
            noteFieldName="note"
            :val1Dif="vorfluter.VORFLUT_2Dif"
            :val2Dif="vorfluter.VORFLUT_1Dif"
            :sum="VORFLUT_G"
            :sum_ly="VORFLUT_G_ly"
            fieldGroup="vorfluter"
            :year="year"
            :lg="lg"
            :readonly="readonly || usertype !== 1 ? true : false"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <v-row v-if="usertype === 1">
            <v-col>
              <span
                >Vorfluternummer zur Abwasserableitung in den Vorfluter:
                {{ VORFLUT_NR }}</span
              >
            </v-col>
          </v-row>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <v-row dense>
            <v-col
              cols="12"
              lg="3"
              xl="4"
              order="first"
              class="d-flex align-center"
            >
              <span>Wasserbedarf</span>
            </v-col>
            <v-col cols="12" order="2" sm="4" class="d-lg-none">
              <v-text-field
                :value="'Juli - Dez. ' + (year - 1)"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" :lg="lg" order-lg="2" order="3">
              <v-text-field
                :value="calcBedarf('2').toLocaleString('de-DE')"
                readonly
                class="txt-align-end"
                suffix="m³"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" :lg="lg" order-lg="3" order="4">
              <v-text-field
                disabled
                class="txt-align-end"
                suffix="m³"
                :value="BEDARF_2_ly"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" order="5" sm="4" class="d-lg-none">
              <v-text-field
                :value="'Jan. - Juni ' + year"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" :lg="lg" order-lg="4" order="6">
              <v-text-field
                :value="calcBedarf('1').toLocaleString('de-DE')"
                readonly
                class="txt-align-end"
                suffix="m³"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" :lg="lg" order-lg="5" order="7">
              <v-text-field
                disabled
                class="txt-align-end"
                suffix="m³"
                :value="BEDARF_1_ly"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="d-lg-none" order="8">
              <v-text-field value="Gesamt" readonly></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" :lg="lg" order-lg="6" order="9">
              <v-text-field
                :value="calcBedarf('total').toLocaleString('de-DE')"
                readonly
                class="txt-align-end"
                suffix="m³"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" :lg="lg" order-lg="7" order="10">
              <v-text-field
                disabled
                class="txt-align-end"
                suffix="m³"
                :value="BEDARF_G_ly"
              >
              </v-text-field>
            </v-col>
            <v-col class="d-none d-lg-block note__del__col" order="last" :lg="lg">
              <div style="width: 52px"></div>
            </v-col>
          </v-row>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-row dense data-section="row">
          <v-col cols="12" class="relative">
            <v-textarea
              outlined
              name="allgemeineBemerkungen"
              label="Allgemeine Bemerkungen"
              placeholder="Tragen Sie hier gegebenenfalls allgemeine Bemerkungen ein"
              v-model="allgemeineBemerkungen"
              data-tabindex="1"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-card class="ps-3 pe-3 pt-3 pb-3 mb-7" elevation="1">
          <v-row dense class="d-flex align-center">
            <v-col cols="1" class="d-flex justify-center">
              <v-icon color="primary">mdi-information-outline</v-icon>
            </v-col>
            <v-col>
              <p class="mb-0">
                Die Beantwortung der vorgenannten Fragen kann zu einer
                kostenpflichtigen Mitgliedschaft im Erftverband führen, sofern die
                Bedingungen gemäß Erftverbandsgesetz erfüllt sind. Das
                Erftverbandsgesetz ist im Internet unter
                <a href="https://www.erftverband.de">www.erftverband.de</a>
                abrufbar. Gerne senden wir es Ihnen auch in Papierform zu und
                stehen für etwaige Fragen zur Verfügung.
              </p>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="1"></v-col>
            <v-col sm="9" md="6" lg="4">
              <v-checkbox
                v-if="freiOrPruef"
                label="Ich habe den Hinweis gelesen und akzeptiere ihn"
                v-model="freiOrPruef"
                disabled
              ></v-checkbox>
              <v-checkbox
                v-else
                v-model="terms"
                label="Ich habe den Hinweis gelesen und akzeptiere ihn"
                :rules="[
                  (v) =>
                    !!v || 'Bitte akzeptieren Sie den Hinweis um fortzufahren',
                ]"
                required
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
        <v-row dense align="center" v-if="usertype === 1">
          <v-col>
            <FormStamp
              :data="stampData"
              name="stampData"
              @updateValue="updateValue"
            ></FormStamp>
          </v-col>
          <v-col>
            <InternalNotes
              :hinweiseFreigebenden="internalNotes.hinweiseFreigebenden"
              :hinweisePruefenden="internalNotes.hinweisePruefenden"
              @updateValue="updateValue"
            />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col
            v-if="status === 'erste_freigabe' && this.usertype === 1"
            class="d-flex justify-end"
            style="text-align: end"
          >
            zuletzt bearbeitet von {{ this.lastchange_user }}
          </v-col>
        </v-row>
        <v-row
          v-if="
            (status === 'erste_freigabe' || status === 'freigegeben') &&
            this.usertype === 1
          "
        >
          <v-col class="d-flex flex-column align-end">
            <div v-if="status === 'erste_freigabe' || status === 'freigegeben'">
              Erste Freigabe {{ freigeber_1 ? 'von ' + freigeber_1 : ''
              }}{{ datum_freigabe_1 ? ' am ' + datum_freigabe_1 : ''
              }}{{ zeit_freigabe_1 ? ' um ' + zeit_freigabe_1 : '' }}.
            </div>
            <div v-if="status === 'freigegeben'">
              Endgültige Freigabe
              {{ freigeber_2 ? 'von ' + freigeber_2 : ''
              }}{{ datum_freigabe_2 ? ' am ' + datum_freigabe_2 : ''
              }}{{ zeit_freigabe_2 ? ' um ' + zeit_freigabe_2 : '' }}.
            </div>
          </v-col>
        </v-row>
        <v-row
          dense
          align="center"
          class="mb-5 d-print-none"
          justify="space-between"
          data-section="row"
        >
          <div class="mb-4 mb-sm-0 mt-5">
            <v-btn
              @click="toDashboard(usertype)"
              v-show="
                status === 'freigegeben' ||
                (status === 'pruefung' && usertype !== 1)
                  ? false
                  : true
              "
              data-tabindex="1"
            >
              Zurück zum Dashboard
            </v-btn>
          </div>
          <div>
            <v-btn
              class="mr-4 mr-sm-10 mt-5"
              @click="onSave(true)"
              v-show="
                status === 'freigegeben' ||
                (status === 'pruefung' && usertype !== 1) ||
                readonly
                  ? false
                  : true
              "
              data-tabindex="2"
            >
              Zwischenspeichern
            </v-btn>
            <v-btn
              class="mr-4 mr-sm-10 mt-5"
              type="submit"
              v-show="
                status === 'freigegeben' ||
                (status === 'pruefung' && usertype !== 1) ||
                (readonly && status !== 'erste_freigabe') ||
                (readonly && usertype !== 1)
                  ? false
                  : true
              "
              :color="usertype !== 1 ? 'primary' : 'orange'"
              data-tabindex="3"
            >
              {{
                usertype !== 1 || status === '' || status === 'bearbeitung'
                  ? 'An den Erftverband senden'
                  : status === 'erste_freigabe'
                  ? 'Zurück zur Prüfung'
                  : 'Zurück zum Entnehmer'
              }}
            </v-btn>
            <v-btn
              class="mr-4 mr-sm-10 mt-5"
              v-if="
                usertype === 1 &&
                (status === 'ausstehend' || status === 'korrektur')
              "
              color="primary"
              @click="sendWStatusPruefung()"
              data-tabindex="4"
            >
              Formular übernehmen
            </v-btn>
            <v-btn
              v-if="
                ((status !== 'erste_freigabe' && erste_freigabe) ||
                  (status === 'erste_freigabe' && zweite_freigabe)) &&
                !readonly
              "
              @click="onAccept"
              class="mt-5"
              color="primary"
              data-tabindex="5"
            >
              {{
                status === 'erste_freigabe'
                  ? 'Endgültig freigeben'
                  : 'Erste Freigabe'
              }}
            </v-btn>
            <v-tooltip
              top
              v-else-if="
                usertype === 1 &&
                status !== 'freigegeben' &&
                status !== 'ausstehend' &&
                status !== 'bearbeitung' &&
                status !== 'korrektur'
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  style="opacity: 0.5; background-color: red"
                  class="mt-5"
                  data-tabindex="6"
                >
                  {{
                    status === 'erste_freigabe'
                      ? 'Endgültig freigeben'
                      : 'Erste Freigabe'
                  }}
                </v-btn>
              </template>
              <span>Fehlende Berechtigung</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-container>
      <v-container
        v-show="
          status === 'freigegeben' || (status === 'pruefung' && usertype !== 1)
            ? true
            : false
        "
        class="d-print-none"
      >
        <v-row data-section="row">
          <div class="mb-4 mt-4">
            <v-btn @click="toDashboard(usertype)" data-tabindex="1">
              Zurück zum Dashboard
            </v-btn>
          </div>
        </v-row>
      </v-container>
    </v-form>
    <PrintForm :data="printData" :year="year" v-if="printing && !printLoading"></PrintForm>
  </div>
</template>

<script>
import helpers from '../helpers.js';
const {
  getInternalNotes,
  stopAutosaveAfterX,
  getSingleRowComments,
  determineSingleRowDif,
  getSingleRowSaveData,
  add,
  entryIsNotEmpty,
  buildNewPreviousData,
  assemblePreviousData,
  observeDOMMutations,
  removeTabListener,
  detectTabKey,
  handleTabKey,
  deleteEgRow,
  deleteRow,
  savePreviousId,
  sendIdUpdateRequest,
  getStampDataToSend,
  getStampData,
  sendWStatusPruefung,
  getEigengewinnungComments,
  getEigengewinnungOberflaecheComments,
  multisort,
  getFreigeber,
  getEigengewinnung,
  getEigengewinnungOberflaeche,
  getFremdbezug,
  getSingleRowValues,
  formatDigitsOnly,
  formatDigitsOnlySingleRow,
  handleInput,
  handleInputSingleRow,
  createGroups,
  currentDate,
  currentYear,
  sumValues,
  calculateValue,
  remove,
  toDashboard,
  toggleBemerkung,
  toggleMenu,
  updateValue,
  checkCommentsOpened,
  setDataLoaded,
  handleScroll,
  readonly,
  getRelativeDifference,
  beforeLoadForm,
  getNote,
  setStatusesOnLoad,
  progressState,
  formatDataForPrintForm,
} = helpers;
import { mapState } from 'vuex';
import axios from '../../utils/api';
import { getAuthToken, getUserInfo } from '../../utils/auth.js';
import FormRow from './FormRow.vue';
import ShortUniqueId from 'short-unique-id';
import goTo from '../../node_modules/vuetify/lib/services/goto';
import FormStamp from './FormStamp.vue';
import FormLegend from './FormLegend.vue';
import FormRowMulti from './FormRowMulti.vue';
import FormRowMultiSum from './FormRowMultiSum.vue';
import InternalNotes from './InternalNotes.vue';
import FirstSubBtn from './FirstSubBtn.vue';
import PrintForm from './PrintForm.vue';

export default {
  components: {
    FormRow,
    FormStamp,
    FormLegend,
    FormRowMulti,
    FormRowMultiSum,
    InternalNotes,
    FirstSubBtn,
    PrintForm
  },
  data: () => ({
    internalNotes: {
      hinweiseFreigebenden: '',
      hinweisePruefenden: '',
    },
    fremdbezugMaxLength: 2,
    stampData: {},
    freigeber_1: null,
    datum_freigabe_1: null,
    zeit_freigabe_1: null,
    freigeber_2: null,
    datum_freigabe_2: null,
    zeit_freigabe_2: null,
    lastchange_user: null,
    berechtigung: null,
    erste_freigabe: false,
    zweite_freigabe: false,
    eigenFremdSummeCheck: false,
    eigenFremdSummeHint: '',
    dataLoaded: false,
    timer: null,
    UNTERGR_NR: null,
    OW_NR: null,
    VORFLUT_NR: null,
    user: '',
    formtype: 'LAND',
    year: '',
    mode: '',
    lg: true,
    valid: true,
    terms: false,
    city: '',
    gewinnungsanlagen: [],
    wasserlaeufe: [],
    grundwasserfoerdermenge: {
      GWFOERD_2: 0,
      GWFOERD_1: 0,
      GWFOERD_G: 0,
    },
    oberflaechenwasserentnahme: {
      OWFOERD_2: 0,
      OWFOERD_1: 0,
      OWFOERD_G: 0,
    },
    wasserlieferanten: [],
    wasseraufkommen: {
      value1: 0,
      value2: 0,
      sum: 0,
    },
    hausversorgung: {
      inputs: {
        HAUS_2: '0',
        HAUS_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    viehbewirtschaftungen: {
      inputs: {
        VIEH_2: '0',
        VIEH_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    teichbefuellung: {
      inputs: {
        TEICH_2: '0',
        TEICH_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    feldberegnung: {
      inputs: {
        FELD_2: '0',
        FELD_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    verdunstung: {
      inputs: {
        VERDUNST_2: '0',
        VERDUNST_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    abgabe: {
      inputs: {
        abgabe_2: '0',
        abgabe_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      labelCommentMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
      ABGART: '',
      ABGZIEL: '',
    },
    einleitung: {
      inputs: {
        EINLEIT_2: '0',
        EINLEIT_1: '0',
      },
      EINLEIT_label: '',
      EINLEIT_KZ: '',
      EINLEIT_2Dif: '',
      EINLEIT_1Dif: '',
      EINLEIT_note: '',
      bemerkungMenu: false,
      labelCommentMenu: false,
      labelCommentOpened: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    untergrund: {
      inputs: {
        UNTERGR_2: '0',
        UNTERGR_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    vorfluter: {
      inputs: {
        VORFLUT_2: '0',
        VORFLUT_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    saveData: {},
    allgemeineBemerkungen: '',
    GWFOERD_2_ly: 0,
    GWFOERD_1_ly: 0,
    GWFOERD_G_ly: 0,
    OWFOERD_2_ly: 0,
    OWFOERD_1_ly: 0,
    OWFOERD_G_ly: 0,
    wasseraufkommen_2_ly: 0,
    wasseraufkommen_1_ly: 0,
    wasseraufkommen_G_ly: 0,
    BEDARF_2_ly: 0,
    BEDARF_1_ly: 0,
    BEDARF_G_ly: 0,
    HAUS_2_ly: 0,
    HAUS_1_ly: 0,
    HAUS_G_ly: 0,
    VIEH_2_ly: 0,
    VIEH_1_ly: 0,
    VIEH_G_ly: 0,
    GEWKL_2_ly: 0,
    GEWKL_1_ly: 0,
    GEWKL_G_ly: 0,
    GEWGR_2_ly: 0,
    GEWGR_1_ly: 0,
    GEWGR_G_ly: 0,
    TEICH_2_ly: 0,
    TEICH_1_ly: 0,
    TEICH_G_ly: 0,
    FELD_2_ly: 0,
    FELD_1_ly: 0,
    FELD_G_ly: 0,
    VERDUNST_2_ly: '0',
    VERDUNST_1_ly: '0',
    VERDUNST_G_ly: '0',
    abgabe_2_ly: 0,
    abgabe_1_ly: 0,
    abgabe_G_ly: 0,
    ABGART_ly: '',
    ABGZIEL_ly: '',
    EINLEIT_2_ly: 0,
    EINLEIT_1_ly: 0,
    EINLEIT_G_ly: 0,
    UNTERGR_2_ly: '0',
    UNTERGR_1_ly: '0',
    UNTERGR_G_ly: '0',
    VORFLUT_2_ly: '0',
    VORFLUT_1_ly: '0',
    VORFLUT_G_ly: '0',
    gewinnungsanlagenLy: [],
    wasserlaeufeLy: [],
    wasserlieferantenLy: [],
    newPreviousData: {},
    printData: {},
  }),
  
  computed: {
    freiOrPruef(){
      return this.status === 'erste_freigabe' ||this.status === 'freigegeben' || this.status === 'pruefung';
    },
    readonly,
    ...mapState({
      loggedIn: (state) => state.loggedIn,
      username: (state) => state.username,
      usertype: (state) => state.usertype,
      showNavDrawer: (state) => state.showNavDrawer,
      status: (state) => state.status,
      loading: (state) => state.loading,
      usermail: (state) => state.usermail,
      printing: (state) => state.printing,
      printLoading: (state) => state.printLoading,
    }),
    HAUS_G() {
      let result = this.sumValues(this.hausversorgung.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    VIEH_G() {
      let result = this.sumValues(this.viehbewirtschaftungen.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    TEICH_G() {
      let result = this.sumValues(this.teichbefuellung.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    FELD_G() {
      let result = this.sumValues(this.feldberegnung.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    VERDUNST_G() {
      let result = this.sumValues(this.verdunstung.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    abgabe_G() {
      let result = this.sumValues(this.abgabe.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    EINLEIT_G() {
      let result = this.sumValues(this.einleitung.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    UNTERGR_G() {
      let result = this.sumValues(this.untergrund.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    VORFLUT_G() {
      let result = this.sumValues(this.vorfluter.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
  },
  async created() {
    await this.beforeLoadForm();
    this.year = this.$route.params.year;
    this.user = this.$route.params.username;
    this.mode = this.$route.params.mode ? this.$route.params.mode : '';

    const res = await axios.get('/API-DB/getFormData', {
      params: {
        formtype: this.formtype,
        user: this.user,
        jahr: this.year,
        mode: this.mode,
      },
    });

    console.log('res ', res);
    this.saveData = res.data;
    this.getFreigeber(this.saveData);

    this.lastchange_user = this.saveData.lastchange_user;

    // account for previousData being empty array or empty object
    if (
      this.saveData.previousData === null ||
      this.saveData.previousData === [] ||
      this.saveData.previousData === {} ||
      this.saveData.previousData.length === 0 ||
      Object.keys(this.saveData.previousData).length === 0
    ) {
      delete this.saveData.previousData;
      console.log('deleted empty previousData');
    }

    this.setStatusesOnLoad();

    const fillLastYearsValues = () => {
      if (this.status !== 'bearbeitung' && this.saveData.previousData) {
        this.GWFOERD_2_ly = parseFloat(
          this.saveData.previousData.GWFOERD_2
        ).toLocaleString('de-DE');
        this.GWFOERD_1_ly = parseFloat(
          this.saveData.previousData.GWFOERD_1
        ).toLocaleString('de-DE');
        this.GWFOERD_G_ly = parseFloat(
          this.saveData.previousData.GWFOERD_G
        ).toLocaleString('de-DE');
        this.OWFOERD_2_ly = parseFloat(
          this.saveData.previousData.OWFOERD_2
        ).toLocaleString('de-DE');
        this.OWFOERD_1_ly = parseFloat(
          this.saveData.previousData.OWFOERD_1
        ).toLocaleString('de-DE');
        this.OWFOERD_G_ly = parseFloat(
          this.saveData.previousData.OWFOERD_G
        ).toLocaleString('de-DE');
      }

      this.getEigengewinnung(true);
      this.getEigengewinnungOberflaeche('wasserlaeufe');

      this.getFremdbezug('BEZMENG', 'BEZHERK', 'BEZART');

      this.getSingleRowValues('HAUS');
      if (this.saveData) {
        this.hausversorgung.inputs.HAUS_2 = parseFloat(
          this.saveData['HAUS_2']
        ).toLocaleString('de-DE');
        this.hausversorgung.inputs.HAUS_1 = parseFloat(
          this.saveData['HAUS_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('hausversorgung', 'HAUS');
      this.getSingleRowComments('hausversorgung', 'HAUS');
      this.determineSingleRowDif('hausversorgung', 'HAUS');
      this.getSingleRowValues('VIEH');
      if (this.saveData) {
        this.viehbewirtschaftungen.inputs.VIEH_2 = parseFloat(
          this.saveData['VIEH_2']
        ).toLocaleString('de-DE');
        this.viehbewirtschaftungen.inputs.VIEH_1 = parseFloat(
          this.saveData['VIEH_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('viehbewirtschaftungen', 'VIEH');
      this.getSingleRowComments('viehbewirtschaftungen', 'VIEH');
      this.determineSingleRowDif('viehbewirtschaftungen', 'VIEH');
      this.getSingleRowValues('TEICH');
      if (this.saveData) {
        this.teichbefuellung.inputs.TEICH_2 = parseFloat(
          this.saveData['TEICH_2']
        ).toLocaleString('de-DE');
        this.teichbefuellung.inputs.TEICH_1 = parseFloat(
          this.saveData['TEICH_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('teichbefuellung', 'TEICH');
      this.getSingleRowComments('teichbefuellung', 'TEICH');
      this.determineSingleRowDif('teichbefuellung', 'TEICH');
      this.getSingleRowValues('FELD');
      if (this.saveData) {
        this.feldberegnung.inputs.FELD_2 = parseFloat(
          this.saveData['FELD_2']
        ).toLocaleString('de-DE');
        this.feldberegnung.inputs.FELD_1 = parseFloat(
          this.saveData['FELD_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('feldberegnung', 'FELD');
      this.getSingleRowComments('feldberegnung', 'FELD');
      this.determineSingleRowDif('feldberegnung', 'FELD');
      this.getSingleRowValues('VERDUNST');
      if (this.saveData) {
        this.verdunstung.inputs.VERDUNST_2 = parseFloat(
          this.saveData['VERDUNST_2']
        ).toLocaleString('de-DE');
        this.verdunstung.inputs.VERDUNST_1 = parseFloat(
          this.saveData['VERDUNST_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('verdunstung', 'VERDUNST');
      this.getSingleRowComments('verdunstung', 'VERDUNST');
      this.determineSingleRowDif('verdunstung', 'VERDUNST');
      if (this.saveData) {
        this.abgabe.inputs.abgabe_2 = parseFloat(
          this.saveData['ABGMENG_2']
        ).toLocaleString('de-DE');
        this.abgabe.inputs.abgabe_1 = parseFloat(
          this.saveData['ABGMENG_1']
        ).toLocaleString('de-DE');
        this.abgabe.ABGART = this.saveData.ABGART;
        this.abgabe.ABGZIEL = this.saveData.ABGZIEL;
      }
      this.getNote('abgabe', 'abgabe');
      if (
        this.status !== 'bearbeitung' &&
        !(this.status === 'pruefung' && !this.saveData.previousData) &&
        this.saveData.comments
      ) {
        const abgabeComment1 = this.saveData.comments.filter((e) =>
          e.key.includes('ABGMENG_2')
        );
        const abgabeComment2 = this.saveData.comments.filter((e) =>
          e.key.includes('ABGMENG_1')
        );
        const abgabeCommentLabel = this.saveData.comments.filter((e) =>
          e.key.includes('ABGMENG_label')
        );
        if (abgabeComment1[0]) {
          this.abgabe.value1Comment = abgabeComment1[0].value;
        } else {
          this.abgabe.value1Comment = '';
        }
        if (abgabeComment2[0]) {
          this.abgabe.value2Comment = abgabeComment2[0].value;
        } else {
          this.abgabe.value2Comment = '';
        }
        if (abgabeCommentLabel[0]) {
          this.abgabe.labelComment = abgabeCommentLabel[0].value;
        } else {
          this.abgabe.labelComment = '';
        }
        if (this.saveData.previousData) {
          this.abgabe_2_ly = parseInt(
            this.saveData.previousData.ABGMENG_2
          ).toLocaleString('de-DE');
          this.abgabe_1_ly = parseInt(
            this.saveData.previousData.ABGMENG_1
          ).toLocaleString('de-DE');
          this.abgabe_G_ly = parseInt(
            this.saveData.previousData.ABGMENG_G
          ).toLocaleString('de-DE');
          this.ABGART_ly = this.saveData.previousData.ABGART;
          this.ABGZIEL_ly = this.saveData.previousData.ABGZIEL;
          if (this.abgabe.inputs.abgabe_2 !== this.abgabe_2_ly) {
            this.abgabe.abgabe_2Dif = 'dif';
          }
          if (this.abgabe.inputs.abgabe_1 !== this.abgabe_1_ly) {
            this.abgabe.abgabe_1Dif = 'dif';
          }
        }
      }

      this.getSingleRowValues('EINLEIT');
      if (this.saveData) {
        this.einleitung.inputs.EINLEIT_2 = parseFloat(
          this.saveData['EINLEIT_2']
        ).toLocaleString('de-DE');
        this.einleitung.inputs.EINLEIT_1 = parseFloat(
          this.saveData['EINLEIT_1']
        ).toLocaleString('de-DE');
        if (this.saveData['EINLEIT_note']) {
          this.einleitung.EINLEIT_note = this.saveData['EINLEIT_note'];
        }
        if (this.saveData['EINLEIT_label']) {
          this.einleitung.EINLEIT_label = this.saveData['EINLEIT_label'];
        }
        if (this.saveData['EINLEIT_KZ']) {
          this.einleitung.EINLEIT_KZ = this.saveData['EINLEIT_KZ'];
        }
      }
      this.getSingleRowComments('einleitung', 'EINLEIT', true);
      this.determineSingleRowDif('einleitung', 'EINLEIT', true);
      this.getSingleRowValues('UNTERGR');
      if (this.saveData) {
        this.untergrund.inputs.UNTERGR_2 = parseFloat(
          this.saveData['UNTERGR_2']
        ).toLocaleString('de-DE');
        this.untergrund.inputs.UNTERGR_1 = parseFloat(
          this.saveData['UNTERGR_1']
        ).toLocaleString('de-DE');
        if (this.saveData.UNTERGR_NR) {
          this.UNTERGR_NR = this.saveData.UNTERGR_NR;
        }
      }
      this.getNote('untergrund', 'UNTERGR');
      this.getSingleRowComments('untergrund', 'UNTERGR');
      this.determineSingleRowDif('untergrund', 'UNTERGR');
      this.getSingleRowValues('VORFLUT');
      if (this.saveData) {
        this.vorfluter.inputs.VORFLUT_2 = parseFloat(
          this.saveData['VORFLUT_2']
        ).toLocaleString('de-DE');
        this.vorfluter.inputs.VORFLUT_1 = parseFloat(
          this.saveData['VORFLUT_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('vorfluter', 'VORFLUT');
      this.getSingleRowComments('vorfluter', 'VORFLUT');
      this.determineSingleRowDif('vorfluter', 'VORFLUT');
      this.VORFLUT_NR = this.saveData.VORFLUT_NR;

      this.getSingleRowValues('BEDARF');

      this.wasseraufkommen_2_ly = this.calculateWaterOccurrenceLyLand('2');
      this.wasseraufkommen_1_ly = this.calculateWaterOccurrenceLyLand('1');
      this.wasseraufkommen_G_ly = this.calculateWaterOccurrenceLyLand('total');

      this.allgemeineBemerkungen = this.saveData.allgemeineBemerkungen;
    };

    await fillLastYearsValues();
    this.multisort(this.gewinnungsanlagen, 'label', [this.gewinnungsanlagenLy]);
    this.multisort(this.wasserlaeufe, 'label', [this.wasserlaeufeLy]);
    this.getEigengewinnungComments();
    this.getEigengewinnungOberflaecheComments('wasserlaeufe');

    this.$watch('HAUS_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.hausversorgung.sumChanged = true;
      }
    });
    this.$watch('VIEH_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.viehbewirtschaftungen.sumChanged = true;
      }
    });
    this.$watch('TEICH_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.teichbefuellung.sumChanged = true;
      }
    });
    this.$watch('FELD_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.feldberegnung.sumChanged = true;
      }
    });
    this.$watch('VERDUNST_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.verdunstung.sumChanged = true;
      }
    });
    this.$watch('abgabe_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.abgabe.sumChanged = true;
      }
    });
    this.$watch('EINLEIT_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.einleitung.sumChanged = true;
      }
    });
    this.$watch('UNTERGR_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.untergrund.sumChanged = true;
      }
    });
    this.$watch('VORFLUT_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.vorfluter.sumChanged = true;
      }
    });

    this.buildNewPreviousData(
      'wasserlieferanten',
      'BEZMENG',
      'BEZHERK',
      'BEZART'
    );

    // remove empty wasserlieferanten | 2 loops because index of el changes with removal
    let emptyWasserlieferanten = [];
    this.wasserlieferanten.forEach((el, index) => {
      if (!this.entryIsNotEmpty(index, 'wasserlieferanten')) {
        emptyWasserlieferanten.push(el);
      }
    });
    emptyWasserlieferanten.forEach((el) => {
      this.remove(
        this.wasserlieferanten,
        el,
        this.wasserlieferantenLy,
        'wasserlieferanten'
      );
    });

    this.stampData = this.getStampData();
    this.getInternalNotes();
    await this.setDataLoaded(true);
    this.$forceUpdate();
    window.addEventListener('scroll', this.handleScroll);
    const tabSections = document.querySelectorAll('[data-section="row"]');
    await this.handleTabKey(tabSections);
    this.observeDOMMutations();
  },
  mounted() {
    this.$nextTick(() => {
      if (
        !(
          this.status === 'freigegeben' ||
          (this.status === 'pruefung' && this.usertype !== 1) ||
          this.readonly
        )
      ) {
        console.log('will save automatically');
        this.timer = setInterval(() => {
          this.onSave();
        }, 4 * 60 * 1000);
      }
      this.stopAutosaveAfterX(12 * 60 * 60 * 1000);
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    window.removeEventListener('scroll', this.handleScroll);
    this.removeTabListener();
    this.DOMObserver.disconnect();
  },
  methods: {
    getInternalNotes,
    stopAutosaveAfterX,
    getSingleRowComments,
    determineSingleRowDif,
    buildNewPreviousData,
    assemblePreviousData,
    entryIsNotEmpty,
    observeDOMMutations,
    removeTabListener,
    detectTabKey,
    handleTabKey,
    deleteEgRow,
    deleteRow,
    savePreviousId,
    sendIdUpdateRequest,
    getStampDataToSend,
    getStampData,
    sendWStatusPruefung,
    getEigengewinnungComments,
    getEigengewinnungOberflaecheComments,
    multisort,
    getFreigeber,
    progressState,
    setStatusesOnLoad,
    getEigengewinnung,
    getEigengewinnungOberflaeche,
    getFremdbezug,
    getNote,
    getUserInfo,
    beforeLoadForm,
    getRelativeDifference,
    setDataLoaded,
    handleScroll,
    updateValue,
    getAuthToken,
    toggleBemerkung,
    toggleMenu,
    toDashboard,
    formatDigitsOnly,
    formatDigitsOnlySingleRow,
    handleInput,
    handleInputSingleRow,
    getSingleRowValues,
    createGroups,
    currentDate,
    currentYear,
    formatDataForPrintForm,
    async onSave(alerts) {
      // when only saving, not sending, set status to previous status
      if (this.saveData.status === 'ausstehend') {
        this.$store.dispatch('setStatusPending');
      } else if (this.saveData.status === 'bearbeitung') {
        this.$store.dispatch('setStatusBearbeitung');
      } else if (this.saveData.status === 'pruefung') {
        this.$store.dispatch('setStatusPruefung');
      } else if (this.saveData.status === 'korrektur') {
        this.$store.dispatch('setStatusKorrektur');
      }

      const params = new URLSearchParams({
        formtype: this.formtype,
        user: this.user,
        jahr: this.year,
      }).toString();
      const url = '/API-DB/formData?' + params;
      let { saveData } = this.getSaveData();
      console.log('saveData: ', saveData);
      const stringifiedSaveData = JSON.stringify(saveData);
      this.$store.dispatch('setLoadingTrue');

      try {
        let response = await axios.post(url, stringifiedSaveData, {
          headers: { 'Content-Type': 'application/json' },
        });
        console.log('response: ', response);
        if (alerts) {
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch('setAlertMsg', 'Formular gespeichert');
          this.$store.dispatch('setAlertType', 'info');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 3 * 1000);
        }
      } catch (error) {
        console.log('error: ', error);
        if (alerts) {
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch(
            'setAlertMsg',
            'Formular konnte nicht gespeichert werden'
          );
          this.$store.dispatch('setAlertType', 'error');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 5 * 1000);
        }
      }

      this.$store.dispatch('setLoadingFalse');
    },
    checkCommentsOpened,
    async onSubmit() {
      if (this.usertype !== 1) {
        if (
          !this.checkCommentsOpened(
            [this.gewinnungsanlagen, this.wasserlaeufe, this.wasserlieferanten],
            [
              this.hausversorgung,
              this.viehbewirtschaftungen,
              this.teichbefuellung,
              this.feldberegnung,
              this.verdunstung,
              this.abgabe,
              this.einleitung,
              this.untergrund,
              this.vorfluter,
            ]
          )
        ) {
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch(
            'setAlertMsg',
            'Bitte öffnen Sie alle Kommentare bevor Sie das Formular absenden.'
          );
          this.$store.dispatch('setAlertType', 'info');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 3 * 1000);
          return;
        }
        if (
          this.getRelativeDifference(
            this.wasseraufkommen.sum,
            this.wasseraufkommen_G_ly
          ) > 40 &&
          !(
            this.wasseraufkommen_G_ly === 0 || this.wasseraufkommen_G_ly === '0'
          ) &&
          !this.eigenFremdSummeCheck
        ) {
          this.eigenFremdSummeHint =
            'Bitte überprüfen Sie ihre Eingaben für die Eigengewinnung erneut. Die Abweichung zu den vorherigen Werten beträgt mehr als 40 Prozent.';
          goTo('#eigen-fremd-summe');
          return;
        }
        await this.$refs.form.validate();
      }
      if (this.valid === true || this.usertype === 1) {
        this.progressState();
        const params = new URLSearchParams({
          formtype: this.formtype,
          user: this.user,
          jahr: this.year,
        }).toString();
        const url = '/API-DB/formData?' + params;
        let { saveData } = this.getSaveData();
        const stringifiedSaveData = JSON.stringify(saveData);
        console.log('stringifiedSaveData: ', stringifiedSaveData);
        this.$store.dispatch('setLoadingTrue');
        try {
          let response = await axios.post(url, stringifiedSaveData, {
            headers: { 'Content-Type': 'application/json' },
          });
          console.log('response: ', response);
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch('setAlertMsg', 'Formular versandt');
          this.$store.dispatch('setAlertType', 'info');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 3 * 1000);
          this.$store.dispatch('setLoadingFalse');
          this.toDashboard(this.usertype);
        } catch (error) {
          console.log('error: ', error);
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch(
            'setAlertMsg',
            'Formular konnte nicht versandt werden'
          );
          this.$store.dispatch('setAlertType', 'error');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 5 * 1000);
          this.$store.dispatch('setLoadingFalse');
        }
      }
    },
    async onAccept() {
      if (!(this.erste_freigabe || this.zweite_freigabe)) {
        this.$store.dispatch('setAlertShow', true);
        this.$store.dispatch(
          'setAlertMsg',
          'Sie sind nicht berechtigt, Formulare freizugeben'
        );
        this.$store.dispatch('setAlertType', 'info');
        setTimeout(() => {
          this.$store.commit('setAlertShow', false);
        }, 3 * 1000);
        return;
      }
      if (this.status === 'erste_freigabe') {
        await this.$store.dispatch('setStatusFreigegeben');
      } else {
        await this.$store.dispatch('setStatusErsteFreigabe');
      }
      const params = new URLSearchParams({
        formtype: this.formtype,
        user: this.user,
        jahr: this.year,
      }).toString();
      const url = '/API-DB/formData?' + params;
      let { saveData } = this.getSaveData();
      console.log('current status: ', this.status);
      if (this.status === 'erste_freigabe') {
        saveData.freigeber_1 = this.usermail;
      } else {
        saveData.freigeber_2 = this.usermail;
      }
      console.log('saveData: ', saveData);
      const stringifiedSaveData = JSON.stringify(saveData);
      console.log('stringifiedSaveData: ', stringifiedSaveData);
      this.$store.dispatch('setLoadingTrue');
      try {
        let response = await axios.post(url, stringifiedSaveData, {
          headers: { 'Content-Type': 'application/json' },
        });
        console.log('response: ', response);
        this.$store.dispatch('setAlertShow', true);
        this.$store.dispatch('setAlertMsg', 'Formular versandt');
        this.$store.dispatch('setAlertType', 'info');
        setTimeout(() => {
          this.$store.commit('setAlertShow', false);
        }, 3 * 1000);
        this.$store.dispatch('setLoadingFalse');
        this.toDashboard(this.usertype);
      } catch (error) {
        console.log('error: ', error);
        this.$store.dispatch('setAlertShow', true);
        this.$store.dispatch(
          'setAlertMsg',
          'Formular konnte nicht versandt werden'
        );
        this.$store.dispatch('setAlertType', 'error');
        setTimeout(() => {
          this.$store.commit('setAlertShow', false);
        }, 5 * 1000);
        this.$store.dispatch('setLoadingFalse');
      }
    },
    getSaveData() {
      let saveData = {};
      let comments = [];

      let gewinnungsanlagen2ndSem = 0;
      let gewinnungsanlagen1stSem = 0;
      let gewinnungsanlagenSum = 0;
      for (let i = 0; i < this.gewinnungsanlagen.length; i++) {
        gewinnungsanlagen2ndSem =
          gewinnungsanlagen2ndSem +
          parseInt(this.gewinnungsanlagen[i].value1.replaceAll('.', ''));
        gewinnungsanlagen1stSem =
          gewinnungsanlagen1stSem +
          parseInt(this.gewinnungsanlagen[i].value2.replaceAll('.', ''));
        gewinnungsanlagenSum =
          gewinnungsanlagenSum +
          parseInt(this.gewinnungsanlagen[i].value1.replaceAll('.', '')) +
          parseInt(this.gewinnungsanlagen[i].value2.replaceAll('.', ''));
      }

      this.grundwasserfoerdermenge.GWFOERD_2 = gewinnungsanlagen2ndSem;
      this.grundwasserfoerdermenge.GWFOERD_1 = gewinnungsanlagen1stSem;
      this.grundwasserfoerdermenge.GWFOERD_G = gewinnungsanlagenSum;

      saveData = { ...saveData, ...this.grundwasserfoerdermenge };

      // wasserlaeufe

      let wasserlaeufe2ndSem = 0;
      let wasserlaeufe1stSem = 0;
      let wasserlaeufeSum = 0;

      for (let i = 0; i < this.wasserlaeufe.length; i++) {
        wasserlaeufe2ndSem =
          wasserlaeufe2ndSem +
          parseInt(this.wasserlaeufe[i].value1.replaceAll('.', ''));
        wasserlaeufe1stSem =
          wasserlaeufe1stSem +
          parseInt(this.wasserlaeufe[i].value2.replaceAll('.', ''));
        wasserlaeufeSum =
          wasserlaeufeSum +
          parseInt(this.wasserlaeufe[i].value1.replaceAll('.', '')) +
          parseInt(this.wasserlaeufe[i].value2.replaceAll('.', ''));
      }

      this.oberflaechenwasserentnahme.OWFOERD_2 = wasserlaeufe2ndSem;
      this.oberflaechenwasserentnahme.OWFOERD_1 = wasserlaeufe1stSem;
      this.oberflaechenwasserentnahme.OWFOERD_G = wasserlaeufeSum;

      saveData = {
        ...saveData,
        ...this.oberflaechenwasserentnahme,
        OW_NR: this.OW_NR,
      };

      // wasserlieferanten
      let wasserlieferantenSaveData = [];
      while (this.wasserlieferanten.length < this.fremdbezugMaxLength) {
        this.add(this.wasserlieferanten, true);
      }
      for (let i = 0; i < this.wasserlieferanten.length; i++) {
        let dbnum = i + 1;

        const label = 'BEZHERK' + dbnum;
        const val1 = 'BEZMENG' + dbnum + '_2';
        const val2 = 'BEZMENG' + dbnum + '_1';
        const total = 'BEZMENG' + dbnum + '_G';
        const note = 'BEZMENG' + dbnum + '_note';
        const bezArt = 'BEZART' + dbnum;

        wasserlieferantenSaveData[i] = {};
        wasserlieferantenSaveData[i][label] = this.wasserlieferanten[i].label;
        wasserlieferantenSaveData[i][val1] = parseFloat(
          this.wasserlieferanten[i].value1.replaceAll('.', '')
        );
        wasserlieferantenSaveData[i][val2] = parseFloat(
          this.wasserlieferanten[i].value2.replaceAll('.', '')
        );
        wasserlieferantenSaveData[i][total] = parseFloat(
          this.wasserlieferanten[i].sum.replaceAll('.', '')
        );
        wasserlieferantenSaveData[i][note] = this.wasserlieferanten[i].note;
        wasserlieferantenSaveData[i][bezArt] =
          this.wasserlieferanten[i].bezugsart;

        wasserlieferantenSaveData.forEach((el) => {
          saveData = { ...saveData, ...el };
        });

        comments.push({
          key: label,
          value: this.wasserlieferanten[i].labelComment,
        });
        comments.push({
          key: val1,
          value: this.wasserlieferanten[i].value1Comment,
        });
        comments.push({
          key: val2,
          value: this.wasserlieferanten[i].value2Comment,
        });
      }

      const wasseraufkommenSaveData = {
        wasseraufkommen_2: this.wasseraufkommen.value1,
        wasseraufkommen_1: this.wasseraufkommen.value2,
        wasseraufkommen_g: this.wasseraufkommen.sum,
      };
      saveData = { ...saveData, ...wasseraufkommenSaveData };

      const hausversorgungSaveData = this.getSingleRowSaveData(
        'hausversorgung',
        'HAUS'
      );
      comments.push({
        key: 'HAUS_2',
        value: this.hausversorgung.value1Comment,
      });
      comments.push({
        key: 'HAUS_1',
        value: this.hausversorgung.value2Comment,
      });
      const viehbewirtschaftungenSaveData = this.getSingleRowSaveData(
        'viehbewirtschaftungen',
        'VIEH'
      );
      comments.push({
        key: 'VIEH_2',
        value: this.viehbewirtschaftungen.value1Comment,
      });
      comments.push({
        key: 'VIEH_1',
        value: this.viehbewirtschaftungen.value2Comment,
      });
      const teichbefuellungSaveData = this.getSingleRowSaveData(
        'teichbefuellung',
        'TEICH'
      );
      comments.push({
        key: 'TEICH_2',
        value: this.teichbefuellung.value1Comment,
      });
      comments.push({
        key: 'TEICH_1',
        value: this.teichbefuellung.value2Comment,
      });
      const feldberegnungSaveData = this.getSingleRowSaveData(
        'feldberegnung',
        'FELD'
      );
      comments.push({
        key: 'FELD_2',
        value: this.feldberegnung.value1Comment,
      });
      comments.push({
        key: 'FELD_1',
        value: this.feldberegnung.value2Comment,
      });
      const verdunstungSaveData = this.getSingleRowSaveData(
        'verdunstung',
        'VERDUNST'
      );
      comments.push({
        key: 'VERDUNST_2',
        value: this.verdunstung.value1Comment,
      });
      comments.push({
        key: 'VERDUNST_1',
        value: this.verdunstung.value2Comment,
      });
      const einleitungSaveData = this.getSingleRowSaveData(
        'einleitung',
        'EINLEIT'
      );
      comments.push({
        key: 'EINLEIT_2',
        value: this.einleitung.value1Comment,
      });
      comments.push({
        key: 'EINLEIT_1',
        value: this.einleitung.value2Comment,
      });
      comments.push({
        key: 'EINLEIT_label',
        value: this.einleitung.labelComment,
      });
      const untergrundSaveData = this.getSingleRowSaveData(
        'untergrund',
        'UNTERGR'
      );
      comments.push({
        key: 'UNTERGR_2',
        value: this.untergrund.value1Comment,
      });
      comments.push({
        key: 'UNTERGR_1',
        value: this.untergrund.value2Comment,
      });
      const vorfluterSaveData = this.getSingleRowSaveData(
        'vorfluter',
        'VORFLUT'
      );
      comments.push({
        key: 'VORFLUT_2',
        value: this.vorfluter.value1Comment,
      });
      comments.push({
        key: 'VORFLUT_1',
        value: this.vorfluter.value2Comment,
      });

      comments.push({
        key: 'ABGMENG_2',
        value: this.abgabe.value1Comment,
      });
      comments.push({
        key: 'ABGMENG_1',
        value: this.abgabe.value2Comment,
      });
      comments.push({
        key: 'ABGMENG_label',
        value: this.abgabe.labelComment,
      });

      let { eigengewinnung, eigengewinnungComments } =
        this.getEntnehmerAndFoerderData();
      comments.push(...eigengewinnungComments);

      const eigengewinnungOberflaeche = [];
      for (let i = 0; i < this.wasserlaeufe.length; i++) {
        // eigengewinnung oberflächenwasser
        eigengewinnungOberflaeche[i] = {};
        eigengewinnungOberflaeche[i]['FOEMENG_2'] = parseInt(
          this.wasserlaeufe[i].value1.replace(/[^\d]/g, '')
        );
        eigengewinnungOberflaeche[i]['FOEMENG_1'] = parseInt(
          this.wasserlaeufe[i].value2.replace(/[^\d]/g, '')
        );
        eigengewinnungOberflaeche[i]['FOEMENG_G'] = parseInt(
          this.wasserlaeufe[i].sum.replace(/[^\d]/g, '')
        );
        eigengewinnungOberflaeche[i]['note'] = this.wasserlaeufe[i].note;

        if (this.wasserlaeufe[i].label) {
          eigengewinnungOberflaeche[i]['OSNAME'] = this.wasserlaeufe[i].label;
        } else {
          eigengewinnungOberflaeche[i]['OSNAME'] = '';
        }
        eigengewinnungOberflaeche[i]['addedByUser'] =
          this.wasserlaeufe[i].addedByUser + '';
        eigengewinnungOberflaeche[i]['FOENR_GES'] =
          this.wasserlaeufe[i].FOENR_GES;
        if (!eigengewinnungOberflaeche[i]['FOENR_GES']) {
          eigengewinnungOberflaeche[i]['FOENR_GES'] = '';
        }
        // comments
        let eigengewinnungOberflaecheComments = [];
        const key1 = 'EIGEN_OBERFLAECHE' + i + '_OSNAME';
        const key2 = 'EIGEN_OBERFLAECHE' + i + '_FOEMENG_2';
        const key3 = 'EIGEN_OBERFLAECHE' + i + '_FOEMENG_1';

        if (this.wasserlaeufe[i].labelComment) {
          eigengewinnungOberflaecheComments.push({
            key: key1,
            value: this.wasserlaeufe[i].labelComment,
            label: this.wasserlaeufe[i].label,
          });
        }
        if (this.wasserlaeufe[i].value1Comment) {
          eigengewinnungOberflaecheComments.push({
            key: key2,
            value: this.wasserlaeufe[i].value1Comment,
            label: this.wasserlaeufe[i].label,
          });
        }
        if (this.wasserlaeufe[i].value2Comment) {
          eigengewinnungOberflaecheComments.push({
            key: key3,
            value: this.wasserlaeufe[i].value2Comment,
            label: this.wasserlaeufe[i].label,
          });
        }
        comments.push(...eigengewinnungOberflaecheComments);
      }

      const authToken = this.getAuthToken();

      const stampData = this.getStampDataToSend();

      saveData = {
        ...saveData,
        ...hausversorgungSaveData,
        ...viehbewirtschaftungenSaveData,
        ...teichbefuellungSaveData,
        ...feldberegnungSaveData,
        ...verdunstungSaveData,
        HAUS_note: this.hausversorgung.note,
        VIEH_note: this.viehbewirtschaftungen.note,
        FELD_note: this.teichbefuellung.note,
        FELD_note: this.feldberegnung.note,
        ABGMENG_2: parseInt(this.abgabe.inputs.abgabe_2.replaceAll('.', '')),
        ABGMENG_1: parseInt(this.abgabe.inputs.abgabe_1.replaceAll('.', '')),
        ABGMENG_G: parseInt(this.abgabe_G.replaceAll('.', '')),
        abgabe_note: this.abgabe.note,
        ABGART: this.abgabe.ABGART,
        ABGZIEL: this.abgabe.ABGZIEL,
        allgemeineBemerkungen: this.allgemeineBemerkungen,
        ...einleitungSaveData,
        EINLEIT_KZ: this.einleitung.EINLEIT_KZ,
        EINLEIT_label: this.einleitung.EINLEIT_label,
        EINLEIT_note: this.einleitung.EINLEIT_note,
        ...untergrundSaveData,
        UNTERGR_NR: this.UNTERGR_NR,
        ...vorfluterSaveData,
        VORFLUT_NR: this.VORFLUT_NR,
        eigengewinnung,
        eigengewinnungOberflaeche,
        comments,
        BEDARF_2: parseInt(this.calcBedarf('2').replaceAll('.', '')),
        BEDARF_1: parseInt(this.calcBedarf('1').replaceAll('.', '')),
        BEDARF_G: parseInt(this.calcBedarf('total').replaceAll('.', '')),
        status: this.status,
        LAERFASSJ: this.year,
        lastchange: true,
        lastchange_user: true,
        JWT: authToken,
        ...stampData,
        hinweis_freigabe: this.internalNotes.hinweiseFreigebenden,
        hinweis_pruefung: this.internalNotes.hinweisePruefenden,
      };

      saveData = this.assemblePreviousData(
        saveData,
        'gewinnungsanlagenOberflaeche',
        'wasserlieferanten',
        this.fremdbezugMaxLength,
        'BEZMENG',
        'BEZHERK',
        'BEZART'
      );

      return { saveData: saveData };
    },
    getEntnehmerAndFoerderData() {
      let eigengewinnung = [];
      let eigengewinnungComments = [];

      for (let i = 0; i < this.gewinnungsanlagen.length; i++) {
        // data for SO_FOERDER
        eigengewinnung[i] = {};
        eigengewinnung[i]['FOEMENG_2'] = parseInt(
          this.gewinnungsanlagen[i].value1.replace(/[^\d]/g, '')
        );
        eigengewinnung[i]['FOEMENG_1'] = parseInt(
          this.gewinnungsanlagen[i].value2.replace(/[^\d]/g, '')
        );
        eigengewinnung[i]['FOEMENG_G'] = parseInt(
          this.gewinnungsanlagen[i].sum.replace(/[^\d]/g, '')
        );
        eigengewinnung[i]['note'] = this.gewinnungsanlagen[i].note;

        // data for SO_ENTNEHMER
        eigengewinnung[i]['OSRECHT_A'] = parseInt(
          this.gewinnungsanlagen[i].rights.replace(/[^\d]/g, '')
        );
        eigengewinnung[i]['OSNAME'] = this.gewinnungsanlagen[i].label;
        eigengewinnung[i]['FOENR_GES'] = this.gewinnungsanlagen[i].FOENR_GES;
        if (!eigengewinnung[i]['FOENR_GES']) {
          eigengewinnung[i]['FOENR_GES'] = '';
        }
        eigengewinnung[i]['addedByUser'] =
          this.gewinnungsanlagen[i].addedByUser;

        // comments
        const key1 = 'EIGEN_' + i + '_OSNAME';
        const key2 = 'EIGEN_' + i + '_FOEMENG_2';
        const key3 = 'EIGEN_' + i + '_FOEMENG_1';

        eigengewinnungComments.push({
          key: key1,
          value: this.gewinnungsanlagen[i].labelComment,
        });
        eigengewinnungComments.push({
          key: key2,
          value: this.gewinnungsanlagen[i].value1Comment,
        });
        eigengewinnungComments.push({
          key: key3,
          value: this.gewinnungsanlagen[i].value2Comment,
        });
      }
      return {
        eigengewinnung: eigengewinnung,
        eigengewinnungComments: eigengewinnungComments,
      };
    },
    getSingleRowSaveData,
    sumValues,
    calculateValue,
    calcBedarf(timespan) {
      const wasseraufkommen = this.calculateWaterOccurrenceLand(timespan);
      let einleitmenge = 0;
      let abgabe = 0;
      if (timespan === '2') {
        einleitmenge = this.einleitung.inputs.EINLEIT_2.replaceAll('.', '');
        abgabe = this.abgabe.inputs.abgabe_2.replaceAll('.', '');
      } else if (timespan === '1') {
        einleitmenge = this.einleitung.inputs.EINLEIT_1.replaceAll('.', '');
        abgabe = this.abgabe.inputs.abgabe_1.replaceAll('.', '');
      } else if (timespan === 'total') {
        einleitmenge = this.EINLEIT_G.replaceAll('.', '');
        abgabe = this.abgabe_G.replaceAll('.', '');
      }
      const sum = (wasseraufkommen - einleitmenge - abgabe).toLocaleString(
        'de-DE'
      );
      return sum;
    },
    calculateSum(data, timespan) {
      let sum = 0;
      if (timespan === '2') {
        data.forEach((el) => {
          let val = el.value1.replaceAll('.', '');
          val = parseFloat(val);
          sum = sum + val;
        });
        if (data === this.gewinnungsanlagen) {
          this.grundwasserfoerdermenge.GWFOERD_2 = sum;
        }
        if (data === this.wasserlaeufe) {
          this.oberflaechenwasserentnahme.OWFOERD_2 = sum;
        }
      } else if (timespan === '1') {
        data.forEach((el) => {
          let val = el.value2.replaceAll('.', '');
          val = parseFloat(val);
          sum = sum + val;
        });
        if (data === this.gewinnungsanlagen) {
          this.grundwasserfoerdermenge.GWFOERD_1 = sum;
        }
        if (data === this.wasserlaeufe) {
          this.oberflaechenwasserentnahme.OWFOERD_1 = sum;
        }
      } else if (timespan === 'total') {
        data.forEach((el) => {
          let val1 = el.value1.replaceAll('.', '');
          let val2 = el.value2.replaceAll('.', '');
          let val = parseFloat(val1) + parseFloat(val2);
          sum = sum + val;
        });
        if (data === this.gewinnungsanlagen) {
          this.grundwasserfoerdermenge.GWFOERD_G = sum;
        }
        if (data === this.wasserlaeufe) {
          this.oberflaechenwasserentnahme.OWFOERD_G = sum;
        }
      }
      return sum;
    },
    calculateWaterOccurrenceLand(timespan) {
      let sum = 0;
      if (timespan === '2') {
        sum =
          this.calculateSum(this.gewinnungsanlagen, '2') +
          this.calculateSum(this.wasserlaeufe, '2');
        let waterDelivered = 0;
        this.wasserlieferanten.forEach((el) => {
          let val = el.value1.replaceAll('.', '');
          val = parseFloat(val);
          waterDelivered = waterDelivered + val;
        });
        sum = sum + waterDelivered;
        this.wasseraufkommen.value1 = sum;
      } else if (timespan === '1') {
        sum =
          this.calculateSum(this.gewinnungsanlagen, '1') +
          this.calculateSum(this.wasserlaeufe, '1');
        let waterDelivered = 0;
        this.wasserlieferanten.forEach((el) => {
          let val = el.value2.replaceAll('.', '');
          val = parseFloat(val);
          waterDelivered = waterDelivered + val;
        });
        sum = sum + waterDelivered;
        this.wasseraufkommen.value2 = sum;
      } else if (timespan === 'total') {
        sum =
          this.calculateSum(this.gewinnungsanlagen, 'total') +
          this.calculateSum(this.wasserlaeufe, 'total');
        let waterDelivered = 0;
        this.wasserlieferanten.forEach((el) => {
          let val1 = el.value1.replaceAll('.', '');
          let val2 = el.value2.replaceAll('.', '');
          let val = parseFloat(val1) + parseFloat(val2);
          waterDelivered = waterDelivered + val;
        });
        sum = sum + waterDelivered;
        this.wasseraufkommen.sum = sum;
      }
      return sum;
    },
    calculateWaterOccurrenceLyLand(timespan) {
      let sum = 0;
      if (timespan === '2') {
        let gewinnungsanlagenLySum = 0;
        this.gewinnungsanlagenLy.forEach((el) => {
          let val = el.FOEMENG_2.replaceAll('.', '');
          val = parseFloat(val);
          gewinnungsanlagenLySum = gewinnungsanlagenLySum + val;
        });
        let wasserlaeufeLySum = 0;
        this.wasserlaeufeLy.forEach((el) => {
          let val = el.FOEMENG_2.replaceAll('.', '');
          val = parseFloat(val);
          wasserlaeufeLySum = wasserlaeufeLySum + val;
        });
        sum = gewinnungsanlagenLySum + wasserlaeufeLySum;
        let waterDelivered = 0;
        for (let index = 0; index < this.wasserlieferantenLy.length; index++) {
          const el = this.wasserlieferantenLy[index];
          let val = el.value1.replaceAll('.', '');
          val = parseFloat(val);
          waterDelivered = waterDelivered + val;
        }
        sum = sum + waterDelivered;
      } else if (timespan === '1') {
        let gewinnungsanlagenLySum = 0;
        this.gewinnungsanlagenLy.forEach((el) => {
          let val = el.FOEMENG_1.replaceAll('.', '');
          val = parseFloat(val);
          gewinnungsanlagenLySum = gewinnungsanlagenLySum + val;
        });
        let wasserlaeufeLySum = 0;
        this.wasserlaeufeLy.forEach((el) => {
          let val = el.FOEMENG_1.replaceAll('.', '');
          val = parseFloat(val);
          wasserlaeufeLySum = wasserlaeufeLySum + val;
        });
        sum = gewinnungsanlagenLySum + wasserlaeufeLySum;
        let waterDelivered = 0;
        for (let index = 0; index < this.wasserlieferantenLy.length; index++) {
          const el = this.wasserlieferantenLy[index];
          let val = el.value2.replaceAll('.', '');
          val = parseFloat(val);
          waterDelivered = waterDelivered + val;
        }
        sum = sum + waterDelivered;
      } else if (timespan === 'total') {
        let gewinnungsanlagenLySum = 0;
        this.gewinnungsanlagenLy.forEach((el) => {
          let val = el.FOEMENG_G.replaceAll('.', '');
          val = parseFloat(val);
          gewinnungsanlagenLySum = gewinnungsanlagenLySum + val;
        });
        let wasserlaeufeLySum = 0;
        this.wasserlaeufeLy.forEach((el) => {
          let val = el.FOEMENG_G.replaceAll('.', '');
          val = parseFloat(val);
          wasserlaeufeLySum = wasserlaeufeLySum + val;
        });
        sum = gewinnungsanlagenLySum + wasserlaeufeLySum;
        let waterDelivered = 0;
        for (let index = 0; index < this.wasserlieferantenLy.length; index++) {
          const el = this.wasserlieferantenLy[index];
          let val = el.sum.replaceAll('.', '');
          val = parseFloat(val);
          waterDelivered = waterDelivered + val;
        }
        sum = sum + waterDelivered;
      }
      return sum.toLocaleString('de-DE');
    },
    add,
    remove,
  },
  watch: {
    async printing(newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        await this.$store.dispatch('setPrintLoadingTrue');
        this.printData = await this.formatDataForPrintForm('FOENR_GES');
        await this.$store.dispatch('setPrintLoadingFalse');
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}

.icon-wrapper {
  position: absolute;
  left: -2.5rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.icon-wrapper > i {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
</style>
