<template>
    <v-container class="d-flex flex-column">
        <v-row>
            <v-col cols="10" offset="1" lg="8" offset-lg="2">

        <h1 class="erft__blue mb-4">A. Datenschutzerklärung des Erftverbandes nach der DSGVO</h1>
        <br>
        <strong class="erft__blue">
I.         Name und Anschrift des Verantwortlichen
Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:</strong>
<br><br>
<span class="erft__blue">Erftverband</span>
<address>
Am Erftverband 6<br>
50126 Bergheim<br>
Deutschland<br>
<a href="tel:02271881158">02271 88-1158</a><br>
Fax 02271 88-1210<br>
E-Mail: <a href="mailto:info@erftverband.de" target="_blank">info[a]erftverband.de</a><br>
<a href="https://www.erftverband.de">www.erftverband.de</a>
</address>
<br>
<strong class="erft__blue">II.       Name und Anschrift des Datenschutzbeauftragten
Der Datenschutzbeauftragte des Verantwortlichen ist:</strong>
<br><br>
<address>
Rainer Schöpfer<br>
Erftverband<br>
Am Erftverband 6<br>
50126 Bergheim<br>
<a href="tel:02271881215">02271 88-1215</a><br>
E-Mail: <a href="rainer.schoepfer@erftverband.de">rainer.Schöpfer[at]erftverband.de</a><br>
</address>
<br>
<strong class="erft__blue">III.    Allgemeines zur Datenverarbeitung</strong>
<ul style="list-style: decimal inside;"><br><li>Umfang der Verarbeitung personenbezogener Daten
Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
</li><br>
<li>Rechtsgrundlage für die Verarbeitung personenbezogener Daten
Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung[1] (DSGVO) als Rechtsgrundlage. Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
<br>
Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
<br>
Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
<br>
Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
<br>
Bestimmungen des Bundes- und Landesrechts, insbesondere solche, des Erft­verbandsgesetzes – ErftVG -, bleiben unberührt. Das ErftVG normiert die dem Erftverband zugewiesenen Aufgaben, deren Erfüllung die Verarbeitung personenbezogener Daten erforderlich machen können.
</li><br>
<li>Datenlöschung und Speicherdauer
Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften des Bundes- oder Landesrechts, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
</li></ul>
<br>
<strong class="erft__blue">IV.    Bereitstellung der Website und Erstellung von Logfiles</strong>
<br><br>
<ul style="list-style: decimal inside;"><li>Beschreibung und Umfang der Datenverarbeitung
Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen vom Computersystem des aufrufenden Rechners.

Folgende Daten werden hierbei erhoben:

Informationen über den Browsertyp und die verwendete Version
Das Betriebssystem des Nutzers
Den Internet-Service-Provider des Nutzers
Die IP-Adresse des Nutzers
Datum und Uhrzeit des Zugriffs
Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt.
</li><br>
<li>Rechtsgrundlage für die Datenverarbeitung
Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
</li><br>
<li>Zweck der Datenverarbeitung
Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben.

Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.

In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
</li><br>
<li>Dauer der Speicherung
Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist.

Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
</li><br>
<li>Widerspruchs- und Beseitigungsmöglichkeit
Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
</li></ul><br>
<strong class="erft__blue">V.       Verwendung von Cookies und Fonts</strong>
<br><br>
<ul  style="list-style: lower-latin inside;"><li>Beschreibung und Umfang der Datenverarbeitung
Unsere Webseite verwendet Cookie. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht.

Wir verwenden  das Analysetool Count-per-day, dieses verwendet laut Herstellerkeine Cookies.

Wir verwenden weiter das Sicherheitsplugin Wordfence. Wordfence hat den Zweck, die website des Erftverbandes vor Hackerangriffen zu schützen und nutzt nach eigenen Angaben Cookies um Besucher der website eindeutig zu identifizieren, die im wordfence gezeigt werden.

Folgende Cookies werden gesetzt: Name des Cookies: wfvt_XXXXXXX, wordfence_verifiedHuman. Die Speicherdauer beträgt je nach cookie 30 Minuten oder  24 Stunden.
</li><br>
<li>Rechtsgrundlage für die Datenverarbeitung
Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
</li><br>
<li>Zweck der Datenverarbeitung
Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird. Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.
</li><br>
<li>Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann je nach den Einstellungen des Intzernet Browsers auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.
</li><br>
<li>Google Fonts
Unsere Website verwendet Google Fonts. Google Fonts ist ein Dienst der Google Inc. („Google“). Die Einbindung dieser Web Fonts erfolgt durch einen Serveraufruf, in der Regel ein Server von Google in den USA. Hierdurch wird an den Server übermittelt, welche unserer Internetseiten Sie besucht haben. Auch wird die IP-Adresse des Browsers des Endgerätes des Besuchers dieser Internetseiten von Google gespeichert. Nähere Hinweise sind im Internet unter dem Suchwort Google Fonts zu erlangen.
</li><br>
<li>Verwendung von eingebetteten YouTube-Videos
Die YouTube-Videos auf www.erftverband.de sind  im erweiterten Datenschutzmodus von Youtube eingebunden. Dadurch wird nach Angabe von Youtube  das Setzen von Youtube-Cookies solange blockiert, bis Sie durch aktiven Klick auf die Wiedergabe den Film starten.

Wenn Sie eine Website von uns aufrufen, die ein Youtube-Plugin enthält, baut Ihr Browser eine direkte Verbindung mit den Servern des Anbieters auf. Der Inhalt des Plugins wird von dem Anbieter direkt an Ihren Browser übermittelt und von diesem in die Website eingebunden.

Durch die Einbindung der Plugins erhält der Anbieter die Information, dass Sie die entsprechende Seite unseres Internetauftritts aufgerufen haben. Sind Sie bei dem Anbieter eingeloggt, hat dieser die Möglichkeit, diese Information Ihrem Konto zuzuordnen. Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. a DSGVO.

Nutzungsbedingungen von Youtube
Datenschutzrichtlinie von Youtube/Google
</li></ul>
<br>
<strong class="erft__blue">VI.         Kontaktformular und E-Mail-Kontakt</strong>
<br><br>
<ul style="list-style: decimal inside;"><li>Beschreibung und Umfang der Datenverarbeitung
Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches für die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer diese Möglichkeit wahr, so werden die in der Eingabemaske eingegeben Daten an uns übermittelt und gespeichert. Diese Daten sind

Name (optional)
E-Mail Adresse und
Nachricht
Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten gespeichert:

Die IP-Adresse des Nutzers
Datum und Uhrzeit der Kontaktaufnahme
Im Falle der Kontaktaufnahme über die bereitgestellte E-Mail-Adresse die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert. Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation verwendet.
</li><br>
<li>
Rechtsgrundlage für die Datenverarbeitung
Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.

Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
</li><br>
<li>
Zweck der Datenverarbeitung
Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten.

Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen.
</li><br>
<li>
Dauer der Speicherung
Die Daten werden auf ihren Wunsch gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist.

Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden spätestens nach einer Frist von sieben Tagen gelöscht.
</li><br>
<li>
Widerspruchs- und Beseitigungsmöglichkeit
Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden.

Für den Widerspruch ist es ausreichend, uns eine E-Mail an die info@erftverband zu senden.

Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem Fall gelöscht.
</li></ul>
<br>
<strong class="erft__blue">VII.         Rechte der betroffenen Person</strong>
<br><br>
Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu. Verantwortlicher bei Erftverband ist
<br><br>
<address>
Rainer Schöpfer<br>
Erftverband<br>
Am Erftverband 6<br>
50126 Bergheim<br>
<a href="02271881215">02271 88-1215</a><br>
E-Mail: <a href="rainer.schoepfer@erftverband.de">rainer.Schöpfer[a]erftverband.de</a><br>
</address>
<br>
<ul style="list-style: decimal inside;"><li>
Auskunftsrecht<br>
Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.
<br>
Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:
<br>
<ul style="list-style: decimal inside;">
    <li>
die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
</li><br>
    <li>
die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
</li><br>
    <li>
die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
</li><br>
    <li>
die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
</li><br>
    <li>
das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
</li><br>
    <li>
das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
</li><br>
    <li>
alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
</li><br>
    <li>
das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
</li><br></ul>
</li><br>
<li>
Recht auf Berichtigung
Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen
</li><br>
<li>
Recht auf Einschränkung der Verarbeitung<br>
Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
<br>
<ul style="list-style: decimal inside;">
    <li>
wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
    </li>
<br>
    <li>
die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
    </li>
<br>
    <li>
der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
    </li>
<br>
    <li>
wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht fest steht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.
<br>
Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
<br>
Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
    </li></ul><br>
</li><br>
<li>
Recht auf Löschung<br>
<ul style="list-style: lower-latin inside;"><li>
Löschungspflicht
Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
<br>
<ul style="list-style: decimal inside;">
    <li>
Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.<br>
</li><br>
    <li>
Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.<br>
</li><br>

    <li>
Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.<br>
</li><br>

    <li>
Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.<br>
</li><br>

    <li>
Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.<br>
</li><br>

    <li>
Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.<br>
</li><br></ul>
</li>
<li>
Information an Dritte
Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
</li><br>
<li>
Ausnahmen
Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist<br>
<ul style="list-style: decimal inside;">
    <li>
zur Ausübung des Rechts auf freie Meinungsäußerung und Information;<br>
</li><br>
    <li>
zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
</li><br>
    <li>
aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
</li><br>
    <li>
für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
</li><br>
    <li>
zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.<br>
</li><br></ul>
</li><br></ul>
<li>
Recht auf Unterrichtung
Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.

Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
</li><br>
<li>
Recht auf Datenübertragbarkeit<br>
Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
<br><br>
<ul style="list-style: decimal inside;">
    <li>
die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
</li><br>
    <li>
die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
<br>
In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
<br>
Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
</li><br></ul>
</li><br>
<li>
Widerspruchsrecht<br>
Sie haben das   jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
<br>
Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
</li><br>
<li>
Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung<br>
Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
</li><br>
<li>
Automatisierte Entscheidung im Einzelfall einschließlich Profiling<br>
Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
<br>
<ul style="list-style: decimal inside;">
    <li>
für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,
</li><br>
    <li>
aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
</li><br>
    <li>
mit Ihrer ausdrücklichen Einwilligung erfolgt.
</li><br>
Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
<br>
Hinsichtlich der in 1. und 3. genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
<br>
<ul style="list-style: lower-latin inside;">
    <li>
Sofern Sie über Facebook mit uns Kontakt aufnehmen weisen wir auf Folgendes hin:<br>
Wir kommunizieren im Rahmen unserer Präsenz auf Facebook mit den dort

aktiven Nutzenden, um sie über Positionen und Leistungen informieren sowie

Anregungen aufnehmen zu können. Dazu verarbeiten wir persönliche Daten aus

Kommentaren und persönlichen Nachrichten. Sofern Daten gespeichert werden,

geschieht dies ausschließlich zum Zweck der Kommunikation mit den Nutzenden.

Die bei uns vorhandenen Daten werden gelöscht, sobald sie zur Erledigung des Vorgangs nicht mehr

benötigt werden.

Facebook stellt Seitenbetreibern, über den Bereich „Insights“ aggregierte Daten

über die Nutzung der Seite zur Verfügung. Wir haben als Seitenbetreiber keine

Möglichkeit und auch nicht die Absicht, Einblick in einzelne personenbezogen

Daten zu erhalten. Fragen zur Verwendung Ihrer personenbezogenen Daten

richten Sie bitte direkt an Facebook (Facebook Ireland Ltd., 4 Grand Canal

Square, Grand Canal Harbour, Dublin 2, Irland) über diverse Online-Formulare

unter <a href="https://de-de.facebook.com/about/privacy/" rel="noopener noreferrer" target="_blank">https://de-de.facebook.com/about/privacy/</a>
</li></ul></ul>
</li><br>
<li>
Recht auf Beschwerde bei einer Aufsichtsbehörde<br>
Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde[2], insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
<br>
Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
<br>
Bergheim, den 25.05.2018


[1]Amtsblatt der Europäischen Union 2016, Nr. 119 Seite 1 ff. Sie ist unter <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=OJ:L:2016:119:FULL&from=DE" rel="noopener noreferrer" target="_blank">https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=OJ:L:2016:119:FULL&from=DE</a>  im Internet abrufbar


[2] Aufsichtsbehörde ist die oder der Landesbeauftragte für Datenschutz und Informationsfreiheit NRW. Die Kotaktdaten stehen im Internet unter https://www.ldi.nrw.de.
</li></ul><br>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>