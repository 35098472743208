<template>
    <div>
        <div class="position-relative">
        <v-img
            src="../assets/portalbild23.jpg"
            alt="Banner"
            width="100%"
            class=""
        ></v-img>
        </div>
        <div class="container">
            <div class="row">
                <div class="col d-flex justify-center mt-4">
                    <v-sheet class="pa-8 pa-sm-12 mb-12">
                    <p class="contact__font">Ihr Ansprechpartner beim Erftverband ist Rainer Schöpfer<br>
                        Telefon: <a href="tel:02271881215"> 02271/881215</a><br>
                        <a :href="hrefAttr" rel="noreferrer" target="_blank">E-Mail an den Erftverband</a><br>
                    </p>
                    </v-sheet>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
    export default {
        computed: {
            ...mapState({
                mitglied: (state) => state.mitglied,
                }),
            hrefAttr() {
            const href = this.mitglied ? 
                'mailto:statistik@erftverband.de?subject=Mitgliedsnummer: ' +
                this.mitglied : 'mailto:statistik@erftverband.de';
            return href;
            },
        }
    }
</script>
<style scoped>
.contact__font {
    font-size: max(1.5rem, 16px);
}
</style>