<template>
  <v-row dense>
    <v-col
      cols="12"
      lg="1"
      order="first"
      class="d-flex align-center mb-6 mb-lg-0"
      v-if="addBtn"
    >
      <v-tooltip bottom v-if="!printing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="status !== 'freigegeben' ? handleAddClick() : ''"
            fab
            dark
            small
            color="primary"
            absolute
            v-on="on"
            v-bind="attrs"
            :disabled="status === 'freigegeben' || readonly ? true : false"
            class="d-print-none"
          >
            <v-icon v-show="!printing">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Zeile hinzufügen</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="12"
      order="2"
      order-lg="2"
      :lg="addBtn ? 2 : 3"
      :xl="addBtn ? 3 : 4"
      class="d-flex align-center"
      ><span>{{ sumText }}</span></v-col
    >
    <v-col cols="12" order="3" sm="4" class="d-lg-none">
      <v-text-field
        :value="'Juli - Dez. ' + (year - 1)"
        readonly
      ></v-text-field>
    </v-col>
    <v-col cols="6" sm="4" :lg="lg" order-lg="2" order="4">
      <v-text-field
        :value="value1"
        readonly
        class="txt-align-end"
        suffix="m³"
      ></v-text-field>
    </v-col>
    <!-- <v-col cols="6" sm="4" :lg="lg" order-lg="3" order="5" class="pr-lg-4">
      <v-text-field
        :value="value1Ly"
        disabled
        class="txt-align-end"
        suffix="m³"
      ></v-text-field>
    </v-col> -->
    <v-col cols="12" order="6" sm="4" class="d-lg-none">
      <v-text-field :value="'Jan. - Juni ' + year" readonly></v-text-field>
    </v-col>
    <v-col cols="6" sm="4" :lg="lg" order-lg="4" order="7">
      <v-text-field
        :value="value2"
        readonly
        class="txt-align-end"
        suffix="m³"
      ></v-text-field>
    </v-col>
    <!-- <v-col cols="6" sm="4" :lg="lg" order-lg="5" order="8" class="pr-lg-4">
      <v-text-field
        :value="value2Ly"
        disabled
        class="txt-align-end"
        suffix="m³"
      ></v-text-field>
    </v-col> -->
    <v-col cols="12" sm="4" class="d-lg-none" order="9">
      <v-text-field value="Gesamt" readonly></v-text-field>
    </v-col>
    <v-col cols="6" sm="4" :lg="lg" order-lg="6" order="10">
      <v-text-field
        :value="sum"
        readonly
        class="txt-align-end"
        suffix="m³"
      ></v-text-field>
    </v-col>
    <!-- <v-col cols="6" sm="4" :lg="lg" order-lg="7" order="11">
      <v-text-field
        disabled
        class="txt-align-end"
        :value="sumLy"
        suffix="m³"
      ></v-text-field>
    </v-col> -->
    <v-col class="d-none d-lg-block note__del__col" order="last" :lg="lg">
      <div style="width: 52px"></div>
      <!-- <div style="width: 28px"></div> -->
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: [
    'group',
    'lg',
    'readonly',
    'sumText',
    'value1',
    'value2',
    'sum',
    'value1Ly',
    'value2Ly',
    'sumLy',
    'year',
    'addBtn',
  ],
  emits: ['add'],
  computed: {
    ...mapState({
      status: (state) => state.status,
      printing: (state) => state.printing,
    }),
  },
  methods: {
    handleAddClick() {
      this.$emit('add', this.group, true);
    },
  },
};
</script>
