<template>
  <div>
    <v-form
      @submit.prevent="onSubmit"
      ref="form"
      lazy-validation
      v-model="valid"
      :readonly="readonly"
      v-if="dataLoaded && !printing"
    >
      <v-container>
        <v-container>
          <v-row>
            <v-col>
              <h1>Erfassung von Wassernutzungen im Bereich des Erftverbandes</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>Sport-/Freizeitanlagen</h2>
            </v-col>
            <v-col
              v-if="mode !== 'first' && status !== 'ausstehend'"
              v-show="!printing"
            >
              <FirstSubBtn
                formName="form_spofr"
                :username="user"
                :year="year"
              ></FirstSubBtn>
            </v-col>
          </v-row>
        </v-container>
        <FormLegend
          :saveData="saveData"
          :lg="lg"
          :year="year"
          :readonly="readonly"
        />
  
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <v-row>
            <v-col cols="12">
              <h3>Eigengewinnung</h3>
              <span>Grundwasser</span>
            </v-col>
          </v-row>
          <FormRowMulti
            v-for="(gewinnungsanlage, index) in gewinnungsanlagen"
            :key="gewinnungsanlage.id"
            :index="index"
            :data="gewinnungsanlagen"
            :dataSingle="gewinnungsanlage"
            :dataLy="gewinnungsanlagenLy"
            labelInputLabel="Bezeichnung der Wassergewinnungsanlage"
            fieldGroup="gewinnungsanlagen"
            idFieldName="FOENR_GES"
            :readonly="readonly"
            deletable="true"
            type="EG"
            :year="year"
            :lg="lg"
            :val1="gewinnungsanlage.value1"
            :val2="gewinnungsanlage.value2"
            val1LyName="FOEMENG_2"
            val2LyName="FOEMENG_1"
            sumLyName="FOEMENG_G"
            @toggleMenu="toggleMenu"
            @updateValue="updateValue"
            @savePreviousId="savePreviousId"
            @sendIdUpdateRequest="sendIdUpdateRequest"
            @deleteEgRow="deleteEgRow"
            @handleInput="handleInput"
            @formatDigitsOnly="formatDigitsOnly"
          />
          <FormRowMultiSum
            :group="gewinnungsanlagen"
            :lg="lg"
            :readonly="readonly"
            sumText="Summe Eigengewinnung"
            :value1="calculateSum(gewinnungsanlagen, '2').toLocaleString('de-DE')"
            :value2="calculateSum(gewinnungsanlagen, '1').toLocaleString('de-DE')"
            :sum="
              calculateSum(gewinnungsanlagen, 'total').toLocaleString('de-DE')
            "
            :value1Ly="MENGEGW_2_ly"
            :value2Ly="MENGEGW_1_ly"
            :sumLy="MENGEGW_G_ly"
            :year="year"
            :addBtn="true"
            @add="add"
          />
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-expansion-panels class="oberfl">
          <v-expansion-panel>
            <v-expansion-panel-header
              ><div>
                <h3 class="expansion__hl">Eigengewinnung</h3>
                <span>Oberflächenwasser</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="ps-3 pe-3 pt-3 pb-3" elevation="0">
                <FormRowMulti
                  v-for="(
                    gewinnungsanlageOberflaeche, index
                  ) in gewinnungsanlagenOberflaeche"
                  :key="gewinnungsanlageOberflaeche.id"
                  :index="index"
                  :data="gewinnungsanlagenOberflaeche"
                  :dataSingle="gewinnungsanlageOberflaeche"
                  :dataLy="gewinnungsanlagenOberflaecheLy"
                  labelInputLabel="Bezeichnung der Wassergewinnungsanlage"
                  fieldGroup="gewinnungsanlagenOberflaeche"
                  idFieldName="FOENR_GES"
                  :readonly="readonly"
                  deletable="true"
                  type="EGO"
                  :year="year"
                  :lg="lg"
                  :val1="gewinnungsanlageOberflaeche.value1"
                  :val2="gewinnungsanlageOberflaeche.value2"
                  val1LyName="FOEMENG_2"
                  val2LyName="FOEMENG_1"
                  sumLyName="FOEMENG_G"
                  @toggleMenu="toggleMenu"
                  @updateValue="updateValue"
                  @savePreviousId="savePreviousId"
                  @sendIdUpdateRequest="sendIdUpdateRequest"
                  @deleteEgRow="deleteEgRow"
                  @handleInput="handleInput"
                  @formatDigitsOnly="formatDigitsOnly"
                />
                <FormRowMultiSum
                  :group="gewinnungsanlagenOberflaeche"
                  :lg="lg"
                  :readonly="readonly"
                  sumText="Summe Eigengewinnung"
                  :value1="
                    calculateSum(
                      gewinnungsanlagenOberflaeche,
                      '2'
                    ).toLocaleString('de-DE')
                  "
                  :value2="
                    calculateSum(
                      gewinnungsanlagenOberflaeche,
                      '1'
                    ).toLocaleString('de-DE')
                  "
                  :sum="
                    calculateSum(
                      gewinnungsanlagenOberflaeche,
                      'total'
                    ).toLocaleString('de-DE')
                  "
                  :value1Ly="MENGEOW_2_ly"
                  :value2Ly="MENGEOW_1_ly"
                  :sumLy="MENGEOW_G_ly"
                  :year="year"
                  :addBtn="true"
                  @add="add"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <div class="icon-wrapper">
            <v-icon>mdi-plus</v-icon>
          </div>
          <v-row dense>
            <v-col cols="12">
              <h3>Fremdbezug</h3>
            </v-col>
          </v-row>
          <FormRowMulti
            v-for="(wasserlieferant, index) in wasserlieferanten"
            :key="wasserlieferant.id"
            :index="index"
            :data="wasserlieferanten"
            :dataSingle="wasserlieferant"
            :dataLy="wasserlieferantenLy"
            labelInputLabel="Bezeichnung des Wasserlieferanten"
            fieldGroup="wasserlieferanten"
            :readonly="readonly"
            deletable="true"
            :year="year"
            :lg="lg"
            :val1="wasserlieferant.value1"
            :val2="wasserlieferant.value2"
            :val1Ly="
              wasserlieferantenLy[index]
                ? wasserlieferantenLy[index].value1
                : null
            "
            :val2Ly="
              wasserlieferantenLy[index]
                ? wasserlieferantenLy[index].value2
                : null
            "
            :sumLy="
              wasserlieferantenLy[index] ? wasserlieferantenLy[index].sum : null
            "
            :variant="wasserlieferant.bezugsart"
            variantName="bezugsart"
            variantLabel="Bezugsart"
            variantHint="Bezugsart (e = Ersatzwasser, a = außerhalb EV, s = sonstiges Wasser)"
            @toggleMenu="toggleMenu"
            @updateValue="updateValue"
            @savePreviousId="savePreviousId"
            @sendIdUpdateRequest="sendIdUpdateRequest"
            @deleteEgRow="deleteEgRow"
            @handleInput="handleInput"
            @formatDigitsOnly="formatDigitsOnly"
            @remove="remove"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  status !== 'freigegeben' ? add(wasserlieferanten, true) : ''
                "
                fab
                dark
                small
                color="primary"
                v-on="on"
                v-bind="attrs"
                :disabled="status === 'freigegeben' || readonly ? true : false"
              >
                <v-icon v-show="!printing">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Zeile hinzufügen</span>
          </v-tooltip>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card
          class="ps-3 pe-3 pt-3 pb-3 blue__border"
          elevation="1"
          id="eigen-fremd-summe"
        >
          <div class="icon-wrapper">
            <v-icon>mdi-equal</v-icon>
          </div>
          <FormRowMultiSum
            :group="wasserlieferanten"
            :lg="lg"
            :readonly="readonly"
            sumText="∑ Eigengewinnung & Fremdbezug"
            :value1="calculateWaterOccurrence('2').toLocaleString('de-DE')"
            :value2="calculateWaterOccurrence('1').toLocaleString('de-DE')"
            :sum="calculateWaterOccurrence('total').toLocaleString('de-DE')"
            :value1Ly="wasseraufkommen_2_ly"
            :value2Ly="wasseraufkommen_1_ly"
            :sumLy="wasseraufkommen_G_ly"
            :year="year"
            :addBtn="false"
            @add="add"
          />
          <v-row v-if="eigenFremdSummeHint !== ''">
            <v-col>
              <v-alert type="error">{{ eigenFremdSummeHint }}</v-alert>
              <v-checkbox
                label="Abweichung bestätigen"
                v-model="eigenFremdSummeCheck"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <div class="icon-wrapper">
            <v-icon>⁒</v-icon>
          </div>
          <v-row dense>
            <v-col cols="12">
              <h3>Fremdabgabe ohne Nutzung</h3>
            </v-col>
          </v-row>
          <FormRowMulti
            v-for="(wasserempfaenger, index) in wasserempfaengerPl"
            :key="wasserempfaenger.id"
            :index="index"
            :data="wasserempfaengerPl"
            :dataSingle="wasserempfaenger"
            :dataLy="wasserempfaengerLy"
            labelInputLabel="Bezeichnung des Wasserempfängers"
            fieldGroup="wasserempfaengerPl"
            :readonly="readonly"
            deletable="true"
            :year="year"
            :lg="lg"
            :val1="wasserempfaenger.value1"
            :val2="wasserempfaenger.value2"
            :val1Ly="
              wasserempfaengerLy[index] ? wasserempfaengerLy[index].value1 : null
            "
            :val2Ly="
              wasserempfaengerLy[index] ? wasserempfaengerLy[index].value2 : null
            "
            :sumLy="
              wasserempfaengerLy[index] ? wasserempfaengerLy[index].sum : null
            "
            :variant="wasserempfaenger.abgabeart"
            variantName="abgabeart"
            variantLabel="Abgabeart"
            variantHint="Abgabeart (e = Ersatzwasser, a = außerhalb EV)"
            @toggleMenu="toggleMenu"
            @updateValue="updateValue"
            @savePreviousId="savePreviousId"
            @sendIdUpdateRequest="sendIdUpdateRequest"
            @deleteEgRow="deleteEgRow"
            @handleInput="handleInput"
            @formatDigitsOnly="formatDigitsOnly"
            @remove="remove"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  status !== 'freigegeben' ? add(wasserempfaengerPl, true) : ''
                "
                fab
                dark
                small
                color="primary"
                v-on="on"
                v-bind="attrs"
                :disabled="status === 'freigegeben' || readonly ? true : false"
              >
                <v-icon v-show="!printing">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Zeile hinzufügen</span>
          </v-tooltip>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <div class="icon-wrapper">
            <v-icon>⁒</v-icon>
          </div>
          <FormRow
            headline="Einleitung ohne Nutzung"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="einleitungOhneNutzung"
            :labelComment="einleitungOhneNutzung.labelComment"
            labelHint=""
            val1Name="EINLEIT_2"
            :value1Comment="einleitungOhneNutzung.value1Comment"
            :val1_ly="EINLEIT_2_ly"
            val2Name="EINLEIT_1"
            :value2Comment="einleitungOhneNutzung.value2Comment"
            :val2_ly="EINLEIT_1_ly"
            noteFieldName="EINLEIT_note"
            :val1Dif="einleitungOhneNutzung.EINLEIT_2Dif"
            :val2Dif="einleitungOhneNutzung.EINLEIT_1Dif"
            :sum="EINLEIT_G"
            :sum_ly="EINLEIT_G_ly"
            fieldGroup="einleitungOhneNutzung"
            labelFieldName="EINLEIT_label"
            labelFieldMaxLength="40"
            :variantFieldName="usertype === 1 ? 'EINLEIT_KZ' : null"
            variantFieldMaxLength="1"
            variantFieldLabel="Kennung Einleitung"
            variantHint="a = Anreicherung, e = Einleitung ohne Nutzung"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <div class="icon-wrapper">
            <v-icon>mdi-equal</v-icon>
          </div>
          <FormRowMultiSum
            :lg="lg"
            :readonly="readonly"
            sumText="Wasser zur Deckung des Eigenbedarfs"
            :value1="calculateBedarf('value1', true).toLocaleString('de-DE')"
            :value2="calculateBedarf('value2', true).toLocaleString('de-DE')"
            :sum="calculateBedarf('sum', true).toLocaleString('de-DE')"
            :value1Ly="BEDARF_2_ly"
            :value2Ly="BEDARF_1_ly"
            :sumLy="BEDARF_G_ly"
            :year="year"
            :addBtn="false"
          />
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <h3>Aufgliederung der Nutzung</h3>
          <FormRow
            headline="Beregnung der Sportanlage"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="beregnung"
            val1Name="GRUEN_2"
            :value1Comment="beregnung.value1Comment"
            :val1_ly="GRUEN_2_ly"
            val2Name="GRUEN_1"
            :value2Comment="beregnung.value2Comment"
            :val2_ly="GRUEN_1_ly"
            noteFieldName="note"
            :val1Dif="beregnung.GRUEN_2Dif"
            :val2Dif="beregnung.GRUEN_1Dif"
            :sum="GRUEN_G"
            :sum_ly="GRUEN_G_ly"
            fieldGroup="beregnung"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <FormRow
            headline="Schwimmbecken"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="schwimmbecken"
            val1Name="schwimm_2"
            :value1Comment="schwimmbecken.value1Comment"
            :val1_ly="schwimm_2_ly"
            val2Name="schwimm_1"
            :value2Comment="schwimmbecken.value2Comment"
            :val2_ly="schwimm_1_ly"
            noteFieldName="note"
            :val1Dif="schwimmbecken.schwimm_2Dif"
            :val2Dif="schwimmbecken.schwimm_1Dif"
            :sum="schwimm_G"
            :sum_ly="schwimm_G_ly"
            fieldGroup="schwimmbecken"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <FormRow
            headline="Soziale & hygienische Zwecke"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="sozialwasser"
            val1Name="SOZIALWA_2"
            :value1Comment="sozialwasser.value1Comment"
            :val1_ly="SOZIALWA_2_ly"
            val2Name="SOZIALWA_1"
            :value2Comment="sozialwasser.value2Comment"
            :val2_ly="SOZIALWA_1_ly"
            noteFieldName="note"
            :val1Dif="sozialwasser.SOZIALWA_2Dif"
            :val2Dif="sozialwasser.SOZIALWA_1Dif"
            :sum="SOZIALWA_G"
            :sum_ly="SOZIALWA_G_ly"
            fieldGroup="sozialwasser"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <FormRow
            headline="sonstige Zwecke, insbesondere: "
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="sonstigeNutzung"
            :labelComment="sonstigeNutzung.labelComment"
            labelHint=""
            val1Name="sonstigeNutzung_2"
            :value1Comment="sonstigeNutzung.value1Comment"
            :val1_ly="sonstigeNutzung_2_ly"
            val2Name="sonstigeNutzung_1"
            :value2Comment="sonstigeNutzung.value2Comment"
            :val2_ly="sonstigeNutzung_1_ly"
            noteFieldName="note"
            :val1Dif="sonstigeNutzung.sonstigeNutzung_2Dif"
            :val2Dif="sonstigeNutzung.sonstigeNutzung_1Dif"
            :sum="sonstigeNutzung_G"
            :sum_ly="sonstigeNutzung_G_ly"
            fieldGroup="sonstigeNutzung"
            labelFieldName="sonstigeNutzung_label"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <div class="icon-wrapper">
            <v-icon>mdi-equal</v-icon>
          </div>
          <v-row dense>
            <v-col
              cols="12"
              lg="3"
              xl="4"
              order="first"
              class="d-flex align-center"
            >
              <span>Gesamte Eigenbedarfsmenge</span>
            </v-col>
            <v-col cols="12" order="2" sm="4" class="d-lg-none">
              <v-text-field
                :value="'Juli - Dez. ' + (year - 1)"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" :lg="lg" order-lg="2" order="3">
              <v-text-field
                :value="gesBedarf_2"
                readonly
                class="txt-align-end"
                suffix="m³"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="6" sm="4" :lg="lg" order-lg="3" order="4">
              <v-text-field
                disabled
                class="txt-align-end"
                suffix="m³"
                :value="gesBedarf_2_ly"
              >
              </v-text-field>
            </v-col> -->
            <v-col cols="12" order="5" sm="4" class="d-lg-none">
              <v-text-field
                :value="'Jan. - Juni ' + year"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" :lg="lg" order-lg="4" order="6">
              <v-text-field
                :value="gesBedarf_1"
                readonly
                class="txt-align-end"
                suffix="m³"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="6" sm="4" :lg="lg" order-lg="5" order="7">
              <v-text-field
                disabled
                class="txt-align-end"
                suffix="m³"
                :value="gesBedarf_1_ly"
              >
              </v-text-field>
            </v-col> -->
            <v-col cols="12" sm="4" class="d-lg-none" order="8">
              <v-text-field value="Gesamt" readonly></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" :lg="lg" order-lg="6" order="9">
              <v-text-field
                :value="
                  (
                    parseInt(GRUEN_G.replace(/[^\d]/g, '')) +
                    parseInt(schwimm_G.replace(/[^\d]/g, '')) +
                    parseInt(SOZIALWA_G.replace(/[^\d]/g, '')) +
                    parseInt(sonstigeNutzung_G.replace(/[^\d]/g, ''))
                  ).toLocaleString('de-DE')
                "
                readonly
                class="txt-align-end"
                suffix="m³"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="6" sm="4" :lg="lg" order-lg="7" order="10">
              <v-text-field
                disabled
                class="txt-align-end"
                suffix="m³"
                :value="gesBedarf_G_ly"
              >
              </v-text-field>
            </v-col> -->
            <v-col class="d-none d-lg-block note__del__col" order="last" :lg="lg">
              <div style="width: 52px"></div>
            </v-col>
          </v-row>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <FormRow
            headline="Verbrauch und Verluste"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="verluste"
            val1Name="VERLUSTE_2"
            :value1Comment="verluste.value1Comment"
            :val1_ly="VERLUSTE_2_ly"
            val2Name="VERLUSTE_1"
            :value2Comment="verluste.value2Comment"
            :val2_ly="VERLUSTE_1_ly"
            noteFieldName="note"
            :val1Dif="verluste.VERLUSTE_2Dif"
            :val2Dif="verluste.VERLUSTE_1Dif"
            :sum="VERLUSTE_G"
            :sum_ly="VERLUSTE_G_ly"
            fieldGroup="verluste"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <FormRow
            headline="Anfallendes Abwasser"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="abwasser"
            val1Name="ABWASSER_2"
            :value1Comment="abwasser.value1Comment"
            :val1_ly="ABWASSER_2_ly"
            val2Name="ABWASSER_1"
            :value2Comment="abwasser.value2Comment"
            :val2_ly="ABWASSER_1_ly"
            noteFieldName="note"
            :val1Dif="abwasser.ABWASSER_2Dif"
            :val2Dif="abwasser.ABWASSER_1Dif"
            :sum="ABWASSER_G"
            :sum_ly="ABWASSER_G_ly"
            fieldGroup="abwasser"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
          <FormRow
            headline="Einleitung in das örtliche Kanalnetz"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="kanal"
            val1Name="kanal_2"
            :value1Comment="kanal.value1Comment"
            :val1_ly="kanal_2_ly"
            val2Name="kanal_1"
            :value2Comment="kanal.value2Comment"
            :val2_ly="kanal_1_ly"
            noteFieldName="note"
            :val1Dif="kanal.kanal_2Dif"
            :val2Dif="kanal.kanal_1Dif"
            :sum="kanal_G"
            :sum_ly="kanal_G_ly"
            fieldGroup="kanal"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <FormRow
            headline="Einleitung in einen Vorfluter"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="vorfluter"
            val1Name="vorfluter_2"
            :value1Comment="vorfluter.value1Comment"
            :val1_ly="vorfluter_2_ly"
            val2Name="vorfluter_1"
            :value2Comment="vorfluter.value2Comment"
            :val2_ly="vorfluter_1_ly"
            noteFieldName="note"
            :val1Dif="vorfluter.vorfluter_2Dif"
            :val2Dif="vorfluter.vorfluter_1Dif"
            :sum="vorfluter_G"
            :sum_ly="vorfluter_G_ly"
            fieldGroup="vorfluter"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <v-row v-if="usertype === 1">
            <v-col class="pt-lg-0">
              <span
                >Vorfluternummer zur Abwasserableitung in den Vorfluter:
                {{ VORFLUT_NR }}</span
              >
            </v-col>
          </v-row>
          <FormRow
            headline="Einleitung in den Untergrund"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="untergrund"
            val1Name="UNTERGRUND_2"
            :value1Comment="untergrund.value1Comment"
            :val1_ly="UNTERGRUND_2_ly"
            val2Name="UNTERGRUND_1"
            :value2Comment="untergrund.value2Comment"
            :val2_ly="UNTERGRUND_1_ly"
            noteFieldName="note"
            :val1Dif="untergrund.UNTERGRUND_2Dif"
            :val2Dif="untergrund.UNTERGRUND_1Dif"
            :sum="UNTERGRUND_G"
            :sum_ly="UNTERGRUND_G_ly"
            fieldGroup="untergrund"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
          <v-row v-if="usertype === 1">
            <v-col class="pt-lg-0">
              <span
                >Schollennummer zur Abwassereinleitung in den Untergrund:
                {{ UNTERGR_NR }}</span
              >
            </v-col>
          </v-row>
          <FormRow
            headline="sonstige Beseitigung"
            headlineStyles="font-size: 1rem; font-weight: 400;"
            :data="sonstigeBeseitigung"
            val1Name="sonstigeBeseitigung_2"
            :value1Comment="sonstigeBeseitigung.value1Comment"
            :val1_ly="sonstigeBeseitigung_2_ly"
            val2Name="sonstigeBeseitigung_1"
            :value2Comment="sonstigeBeseitigung.value2Comment"
            :val2_ly="sonstigeBeseitigung_1_ly"
            noteFieldName="note"
            :val1Dif="sonstigeBeseitigung.sonstigeBeseitigung_2Dif"
            :val2Dif="sonstigeBeseitigung.sonstigeBeseitigung_1Dif"
            :sum="sonstigeBeseitigung_G"
            :sum_ly="sonstigeBeseitigung_G_ly"
            fieldGroup="sonstigeBeseitigung"
            :year="year"
            :lg="lg"
            :readonly="readonly"
            :status="status"
            @updateValue="updateValue"
            @handleInputSingleRow="handleInputSingleRow"
            @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
            @toggleMenu="toggleMenu"
            @toggleBemerkung="toggleBemerkung"
          >
          </FormRow>
        </v-card>
        <v-divider class="mt-5 mb-5"></v-divider>
        <v-row dense data-section="row">
          <v-col cols="12" class="relative">
            <v-textarea
              outlined
              name="allgemeineBemerkungen"
              label="Allgemeine Bemerkungen / Wasserrechte"
              placeholder="Tragen Sie hier gegebenenfalls allgemeine Bemerkungen ein"
              v-model="allgemeineBemerkungen"
              data-tabindex="1"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-card class="ps-3 pe-3 pt-3 pb-3 mb-7" elevation="1">
          <v-row dense class="d-flex align-center">
            <v-col cols="1" class="d-flex justify-center">
              <v-icon color="primary">mdi-information-outline</v-icon>
            </v-col>
            <v-col>
              <p class="mb-0">
                Die Beantwortung der vorgenannten Fragen kann zu einer
                kostenpflichtigen Mitgliedschaft im Erftverband führen, sofern die
                Bedingungen gemäß Erftverbandsgesetz erfüllt sind. Das
                Erftverbandsgesetz ist im Internet unter
                <a href="https://www.erftverband.de">www.erftverband.de</a>
                abrufbar. Gerne senden wir es Ihnen auch in Papierform zu und
                stehen für etwaige Fragen zur Verfügung.
              </p>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="1"></v-col>
            <v-col sm="9" md="6" lg="4">
              <v-checkbox
                v-if="freiOrPruef"
                label="Ich habe den Hinweis gelesen und akzeptiere ihn"
                v-model="freiOrPruef"
                disabled
              ></v-checkbox>
              <v-checkbox
                v-else
                v-model="terms"
                label="Ich habe den Hinweis gelesen und akzeptiere ihn"
                :rules="[
                  (v) =>
                    !!v || 'Bitte akzeptieren Sie den Hinweis um fortzufahren',
                ]"
                required
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
        <v-row dense align="center" v-if="usertype === 1">
          <v-col>
            <FormStamp
              :data="stampData"
              name="stampData"
              @updateValue="updateValue"
            ></FormStamp>
          </v-col>
          <v-col>
            <InternalNotes
              :hinweiseFreigebenden="internalNotes.hinweiseFreigebenden"
              :hinweisePruefenden="internalNotes.hinweisePruefenden"
              @updateValue="updateValue"
            />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col
            v-if="status === 'erste_freigabe' && this.usertype === 1"
            class="d-flex justify-end"
            style="text-align: end"
          >
            zuletzt bearbeitet von {{ this.lastchange_user }}
          </v-col>
        </v-row>
        <v-row
          v-if="
            (status === 'erste_freigabe' || status === 'freigegeben') &&
            this.usertype === 1
          "
        >
          <v-col class="d-flex flex-column align-end">
            <div v-if="status === 'erste_freigabe' || status === 'freigegeben'">
              Erste Freigabe {{ freigeber_1 ? 'von ' + freigeber_1 : ''
              }}{{ datum_freigabe_1 ? ' am ' + datum_freigabe_1 : ''
              }}{{ zeit_freigabe_1 ? ' um ' + zeit_freigabe_1 : '' }}.
            </div>
            <div v-if="status === 'freigegeben'">
              Endgültige Freigabe
              {{ freigeber_2 ? 'von ' + freigeber_2 : ''
              }}{{ datum_freigabe_2 ? ' am ' + datum_freigabe_2 : ''
              }}{{ zeit_freigabe_2 ? ' um ' + zeit_freigabe_2 : '' }}.
            </div>
          </v-col>
        </v-row>
        <v-row
          dense
          align="center"
          class="mb-5 d-print-none"
          justify="space-between"
          data-section="row"
        >
          <div class="mb-4 mb-sm-0 mt-5">
            <v-btn
              @click="toDashboard(usertype)"
              v-show="
                status === 'freigegeben' ||
                (status === 'pruefung' && usertype !== 1)
                  ? false
                  : true
              "
              data-tabindex="1"
            >
              Zurück zum Dashboard
            </v-btn>
          </div>
          <div>
            <v-btn
              class="mr-4 mr-sm-10 mt-5"
              @click="onSave(true)"
              v-show="
                status === 'freigegeben' ||
                (status === 'pruefung' && usertype !== 1) ||
                readonly
                  ? false
                  : true
              "
              data-tabindex="2"
            >
              Zwischenspeichern
            </v-btn>
            <v-btn
              class="mr-4 mr-sm-10 mt-5"
              type="submit"
              v-show="
                status === 'freigegeben' ||
                (status === 'pruefung' && usertype !== 1) ||
                (readonly && status !== 'erste_freigabe') ||
                (readonly && usertype !== 1)
                  ? false
                  : true
              "
              :color="usertype !== 1 ? 'primary' : 'orange'"
              data-tabindex="3"
            >
              {{
                usertype !== 1 || status === '' || status === 'bearbeitung'
                  ? 'An den Erftverband senden'
                  : status === 'erste_freigabe'
                  ? 'Zurück zur Prüfung'
                  : 'Zurück zum Entnehmer'
              }}
            </v-btn>
            <v-btn
              class="mr-4 mr-sm-10 mt-5"
              v-if="
                usertype === 1 &&
                (status === 'ausstehend' || status === 'korrektur')
              "
              color="primary"
              @click="sendWStatusPruefung()"
              data-tabindex="4"
            >
              Formular übernehmen
            </v-btn>
            <v-btn
              v-if="
                ((status !== 'erste_freigabe' && erste_freigabe) ||
                  (status === 'erste_freigabe' && zweite_freigabe)) &&
                !readonly
              "
              @click="onAccept"
              class="mt-5"
              color="primary"
              data-tabindex="5"
            >
              {{
                status === 'erste_freigabe'
                  ? 'Endgültig freigeben'
                  : 'Erste Freigabe'
              }}
            </v-btn>
            <v-tooltip
              top
              v-else-if="
                usertype === 1 &&
                status !== 'freigegeben' &&
                status !== 'ausstehend' &&
                status !== 'bearbeitung' &&
                status !== 'korrektur'
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  style="opacity: 0.5; background-color: red"
                  class="mt-5"
                  data-tabindex="6"
                >
                  {{
                    status === 'erste_freigabe'
                      ? 'Endgültig freigeben'
                      : 'Erste Freigabe'
                  }}
                </v-btn>
              </template>
              <span>Fehlende Berechtigung</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-container>
      <v-container
        v-show="
          status === 'freigegeben' || (status === 'pruefung' && usertype !== 1)
            ? true
            : false
        "
        class="d-print-none"
      >
        <v-row data-section="row">
          <div class="mb-4 mt-4">
            <v-btn @click="toDashboard(usertype)" data-tabindex="1">
              Zurück zum Dashboard
            </v-btn>
          </div>
        </v-row>
      </v-container>
    </v-form>
    <PrintForm :data="printData" :year="year" v-if="printing && !printLoading"></PrintForm>
  </div>
</template>

<script>
import helpers from '../helpers.js';
const {
  getInternalNotes,
  stopAutosaveAfterX,
  getSingleRowComments,
  determineSingleRowDif,
  getSingleRowSaveData,
  add,
  entryIsNotEmpty,
  buildNewPreviousData,
  assemblePreviousData,
  observeDOMMutations,
  removeTabListener,
  detectTabKey,
  handleTabKey,
  deleteEgRow,
  deleteRow,
  savePreviousId,
  sendIdUpdateRequest,
  getStampDataToSend,
  getStampData,
  sendWStatusPruefung,
  getEigengewinnungComments,
  getEigengewinnungOberflaecheComments,
  multisort,
  getFreigeber,
  progressState,
  getEigengewinnung,
  getEigengewinnungOberflaeche,
  getFremdbezug,
  getFremdabgabe,
  getSingleRowValues,
  formatDigitsOnly,
  formatDigitsOnlySingleRow,
  handleInputSingleRow,
  handleInput,
  createGroups,
  currentDate,
  currentYear,
  sumValues,
  calculateValue,
  calculateBedarf,
  remove,
  toDashboard,
  toggleBemerkung,
  toggleMenu,
  updateValue,
  checkCommentsOpened,
  setDataLoaded,
  handleScroll,
  calculateWaterOccurrence,
  calculateWaterOccurrenceLy,
  calculateSum,
  readonly,
  getRelativeDifference,
  beforeLoadForm,
  getNote,
  setStatusesOnLoad,
  formatDataForPrintForm,
} = helpers;
import { mapState } from 'vuex';
import axios from '../../utils/api';
import { getAuthToken, getUserInfo } from '../../utils/auth.js';
import FormRow from './FormRow.vue';
import goTo from '../../node_modules/vuetify/lib/services/goto';
import FormStamp from './FormStamp.vue';
import FormLegend from './FormLegend.vue';
import FormRowMulti from './FormRowMulti.vue';
import FormRowMultiSum from './FormRowMultiSum.vue';
import InternalNotes from './InternalNotes.vue';
import FirstSubBtn from './FirstSubBtn.vue';
import PrintForm from './PrintForm.vue';

export default {
  components: {
    FormRow,
    FormStamp,
    FormLegend,
    FormRowMulti,
    FormRowMultiSum,
    InternalNotes,
    FirstSubBtn,
    PrintForm
  },
  data: () => ({
    internalNotes: {
      hinweiseFreigebenden: '',
      hinweisePruefenden: '',
    },
    fremdbezugMaxLength: 3,
    fremdabgabeMaxLength: 3,
    stampData: {},
    freigeber_1: null,
    datum_freigabe_1: null,
    zeit_freigabe_1: null,
    freigeber_2: null,
    datum_freigabe_2: null,
    zeit_freigabe_2: null,
    lastchange_user: null,
    berechtigung: null,
    erste_freigabe: false,
    zweite_freigabe: false,
    eigenFremdSummeCheck: false,
    eigenFremdSummeHint: '',
    dataLoaded: false,
    timer: null,
    UNTERGR_NR: null,
    VORFLUT_NR: null,
    user: '',
    formtype: 'IND',
    year: '',
    mode: '',
    lg: true,
    valid: true,
    terms: false,
    city: '',
    gewinnungsanlagen: [],
    gewinnungsanlagenOberflaeche: [],
    grundwasserfoerdermenge: {
      MENGEGW_2: 0,
      MENGEGW_1: 0,
      MENGEGW_G: 0,
    },
    oberflaechenwasserfoerdermenge: {
      MENGEOW_2: 0,
      MENGEOW_1: 0,
      MENGEOW_G: 0,
    },
    wasserlieferanten: [],
    wasseraufkommen: {
      wasseraufkommen_2: 0,
      wasseraufkommen_1: 0,
      wasseraufkommen_G: 0,
    },
    wasserempfaengerPl: [],
    einleitungOhneNutzung: {
      inputs: {
        EINLEIT_2: '0',
        EINLEIT_1: '0',
      },
      EINLEIT_label: '',
      EINLEIT_KZ: '',
      EINLEIT_2Dif: '',
      EINLEIT_1Dif: '',
      EINLEIT_note: '',
      bemerkungMenu: false,
      labelCommentMenu: false,
      labelCommentOpened: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    wasserbedarf: {
      BEDARF_1: 0,
      BEDARF_2: 0,
      BEDARF_G: 0,
    },
    allgemeineBemerkungen: '',
    beregnung: {
      inputs: {
        GRUEN_2: '0',
        GRUEN_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    schwimmbecken: {
      inputs: {
        schwimm_2: '0',
        schwimm_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    sozialwasser: {
      inputs: {
        SOZIALWA_2: '0',
        SOZIALWA_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    sonstigeNutzung: {
      sonstigeNutzung_label: '',
      inputs: {
        sonstigeNutzung_2: '0',
        sonstigeNutzung_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      labelCommentMenu: false,
      labelCommentOpened: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    verluste: {
      inputs: {
        VERLUSTE_2: '0',
        VERLUSTE_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    abwasser: {
      inputs: {
        ABWASSER_2: '0',
        ABWASSER_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    kanal: {
      inputs: {
        kanal_2: '0',
        kanal_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    vorfluter: {
      inputs: {
        vorfluter_2: '0',
        vorfluter_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    untergrund: {
      inputs: {
        UNTERGRUND_2: '0',
        UNTERGRUND_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    sonstigeBeseitigung: {
      inputs: {
        sonstigeBeseitigung_2: '0',
        sonstigeBeseitigung_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    MENGEGW_2_ly: 0,
    MENGEGW_1_ly: 0,
    MENGEGW_G_ly: 0,
    MENGEOW_2_ly: 0,
    MENGEOW_1_ly: 0,
    MENGEOW_G_ly: 0,
    wasseraufkommen_2_ly: 0,
    wasseraufkommen_1_ly: 0,
    wasseraufkommen_G_ly: 0,
    EINLEIT_2_ly: 0,
    EINLEIT_1_ly: 0,
    EINLEIT_G_ly: 0,
    BEDARF_2_ly: 0,
    BEDARF_1_ly: 0,
    BEDARF_G_ly: 0,
    GRUEN_2_ly: 0,
    GRUEN_1_ly: 0,
    GRUEN_G_ly: 0,
    schwimm_2_ly: 0,
    schwimm_1_ly: 0,
    schwimm_G_ly: 0,
    SOZIALWA_2_ly: 0,
    SOZIALWA_1_ly: 0,
    SOZIALWA_G_ly: 0,
    sonstigeNutzung_2_ly: '0',
    sonstigeNutzung_1_ly: '0',
    sonstigeNutzung_G_ly: '0',
    gewinnungsanlagenLy: [],
    gewinnungsanlagenOberflaecheLy: [],
    wasserlieferantenLy: [],
    wasserempfaengerLy: [],
    VERLUSTE_2_ly: '0',
    VERLUSTE_1_ly: '0',
    VERLUSTE_G_ly: '0',
    ABWASSER_2_ly: '0',
    ABWASSER_1_ly: '0',
    ABWASSER_G_ly: '0',
    kanal_2_ly: '0',
    kanal_1_ly: '0',
    kanal_G_ly: '0',
    vorfluter_2_ly: '0',
    vorfluter_1_ly: '0',
    vorfluter_G_ly: '0',
    UNTERGRUND_2_ly: '0',
    UNTERGRUND_1_ly: '0',
    UNTERGRUND_G_ly: '0',
    sonstigeBeseitigung_2_ly: '0',
    sonstigeBeseitigung_1_ly: '0',
    sonstigeBeseitigung_G_ly: '0',
    SO_FOERDER: [],
    SO_ENTNEHMER: [],
    foerderDataWithComments: [],
    newPreviousData: {},
    printData: {},
  }),

  computed: {
    freiOrPruef(){
      return this.status === 'erste_freigabe' ||this.status === 'freigegeben' || this.status === 'pruefung';
    },
    readonly,
    ...mapState({
      loggedIn: (state) => state.loggedIn,
      username: (state) => state.username,
      usertype: (state) => state.usertype,
      showNavDrawer: (state) => state.showNavDrawer,
      status: (state) => state.status,
      loading: (state) => state.loading,
      usermail: (state) => state.usermail,
      printing: (state) => state.printing,
      printLoading: (state) => state.printLoading,
    }),
    EINLEIT_G() {
      let result = this.sumValues(this.einleitungOhneNutzung.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    GRUEN_G() {
      let result = this.sumValues(this.beregnung.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    schwimm_G() {
      let result = this.sumValues(this.schwimmbecken.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    SOZIALWA_G() {
      let result = this.sumValues(this.sozialwasser.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    sonstigeNutzung_G() {
      let result = this.sumValues(this.sonstigeNutzung.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    VERLUSTE_G() {
      let result = this.sumValues(this.verluste.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    ABWASSER_G() {
      let result = this.sumValues(this.abwasser.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    kanal_G() {
      let result = this.sumValues(this.kanal.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    vorfluter_G() {
      let result = this.sumValues(this.vorfluter.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    UNTERGRUND_G() {
      let result = this.sumValues(this.untergrund.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    sonstigeBeseitigung_G() {
      let result = this.sumValues(this.sonstigeBeseitigung.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    gesBedarf_2() {
      const sum = (
        parseInt(this.beregnung.inputs.GRUEN_2.replace(/[^\d]/g, '')) +
        parseInt(this.schwimmbecken.inputs.schwimm_2.replace(/[^\d]/g, '')) +
        parseInt(this.sozialwasser.inputs.SOZIALWA_2.replace(/[^\d]/g, '')) +
        parseInt(
          this.sonstigeNutzung.inputs.sonstigeNutzung_2.replace(/[^\d]/g, '')
        )
      ).toLocaleString('de-DE');
      return sum;
    },
    gesBedarf_1() {
      const sum = (
        parseInt(this.beregnung.inputs.GRUEN_1.replace(/[^\d]/g, '')) +
        parseInt(this.schwimmbecken.inputs.schwimm_1.replace(/[^\d]/g, '')) +
        parseInt(this.sozialwasser.inputs.SOZIALWA_1.replace(/[^\d]/g, '')) +
        parseInt(
          this.sonstigeNutzung.inputs.sonstigeNutzung_1.replace(/[^\d]/g, '')
        )
      ).toLocaleString('de-DE');
      return sum;
    },
    gesBedarf_2_ly() {
      const sum = (
        parseInt(this.GRUEN_2_ly) +
        parseInt(this.schwimm_2_ly) +
        parseInt(this.SOZIALWA_2_ly) +
        parseInt(this.sonstigeNutzung_2_ly)
      ).toLocaleString('de-DE');
      return sum;
    },
    gesBedarf_1_ly() {
      const sum = (
        parseInt(this.GRUEN_1_ly) +
        parseInt(this.schwimm_1_ly) +
        parseInt(this.SOZIALWA_1_ly) +
        parseInt(this.sonstigeNutzung_1_ly)
      ).toLocaleString('de-DE');
      return sum;
    },
    gesBedarf_G_ly() {
      const sum = (
        parseInt(this.GRUEN_G_ly) +
        parseInt(this.schwimm_G_ly) +
        parseInt(this.SOZIALWA_G_ly) +
        parseInt(this.sonstigeNutzung_G_ly)
      ).toLocaleString('de-DE');
      return sum;
    },
  },
  async created() {
    await this.beforeLoadForm();
    this.year = this.$route.params.year;
    this.user = this.$route.params.username;
    this.mode = this.$route.params.mode ? this.$route.params.mode : '';

    const res = await axios.get('/API-DB/getFormData', {
      params: {
        formtype: this.formtype,
        user: this.user,
        jahr: this.year,
        mode: this.mode,
      },
    });

    console.log('res ', res);
    this.saveData = res.data;
    this.getFreigeber(this.saveData);

    this.lastchange_user = this.saveData.lastchange_user;

    // account for previousData being empty array or empty object
    if (
      this.saveData.previousData === null ||
      this.saveData.previousData === [] ||
      this.saveData.previousData === {} ||
      this.saveData.previousData.length === 0 ||
      Object.keys(this.saveData.previousData).length === 0
    ) {
      delete this.saveData.previousData;
      console.log('deleted empty previousData');
    }

    this.setStatusesOnLoad();

    const fillLastYearsValues = () => {
      if (this.status !== 'bearbeitung' && this.saveData.previousData) {
        if (typeof this.saveData.previousData.MENGEGW_2 !== 'number') {
          this.MENGEGW_2_ly = parseFloat(
            this.saveData.previousData.MENGEGW_2.replaceAll('.', '')
          ).toLocaleString('de-DE');
        } else {
          this.MENGEGW_2_ly =
            this.saveData.previousData.MENGEGW_2.toLocaleString('de-DE');
        }
        if (typeof this.saveData.previousData.MENGEGW_1 !== 'number') {
          this.MENGEGW_1_ly = parseFloat(
            this.saveData.previousData.MENGEGW_1.replaceAll('.', '')
          ).toLocaleString('de-DE');
        } else {
          this.MENGEGW_1_ly =
            this.saveData.previousData.MENGEGW_1.toLocaleString('de-DE');
        }
        if (typeof this.saveData.previousData.MENGEGW_G_ly !== 'number') {
          this.MENGEGW_G_ly = parseFloat(
            this.saveData.previousData.MENGEGW_G.replaceAll('.', '')
          ).toLocaleString('de-DE');
        } else {
          this.MENGEGW_G_ly =
            this.saveData.previousData.MENGEGW_G.toLocaleString('de-DE');
        }
        this.MENGEOW_2_ly = parseFloat(
          this.saveData.previousData.MENGEOW_2.replaceAll('.', '')
        ).toLocaleString('de-DE');
        this.MENGEOW_1_ly = parseFloat(
          this.saveData.previousData.MENGEOW_1.replaceAll('.', '')
        ).toLocaleString('de-DE');
        this.MENGEOW_G_ly = parseFloat(
          this.saveData.previousData.MENGEOW_G.replaceAll('.', '')
        ).toLocaleString('de-DE');
      }

      this.getEigengewinnung();
      this.getEigengewinnungOberflaeche('gewinnungsanlagenOberflaeche');

      this.getFremdbezug('BEZMENG', 'HERKUNFT', 'BEZUGSART');
      this.getFremdabgabe('wasserempfaengerPl', 'ABMENGE', 'ZIEL', 'ABGABEART');

      this.getSingleRowValues('EINLEIT');
      if (this.saveData) {
        this.einleitungOhneNutzung.inputs.EINLEIT_2 = parseFloat(
          this.saveData['EINLEIT_2']
        ).toLocaleString('de-DE');
        this.einleitungOhneNutzung.inputs.EINLEIT_1 = parseFloat(
          this.saveData['EINLEIT_1']
        ).toLocaleString('de-DE');
        if (this.saveData['EINLEIT_note']) {
          this.einleitungOhneNutzung.EINLEIT_note =
            this.saveData['EINLEIT_note'];
        }
        if (this.saveData['EINLEIT_label']) {
          this.einleitungOhneNutzung.EINLEIT_label =
            this.saveData['EINLEIT_label'];
        }
        this.einleitungOhneNutzung.EINLEIT_KZ = this.saveData['EINLEIT_KZ'];
      }
      this.getSingleRowComments('einleitungOhneNutzung', 'EINLEIT', true);
      this.determineSingleRowDif('einleitungOhneNutzung', 'EINLEIT', true);
      this.getSingleRowValues('BEDARF');
      this.getSingleRowValues('GRUEN');
      if (this.saveData) {
        this.beregnung.inputs.GRUEN_2 = parseFloat(
          this.saveData['GRUEN_2']
        ).toLocaleString('de-DE');
        this.beregnung.inputs.GRUEN_1 = parseFloat(
          this.saveData['GRUEN_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('beregnung', 'GRUEN');
      this.getSingleRowComments('beregnung', 'GRUEN');
      this.determineSingleRowDif('beregnung', 'GRUEN');
      this.getSingleRowValues('schwimm');
      if (this.saveData) {
        this.schwimmbecken.inputs.schwimm_2 = parseFloat(
          this.saveData['schwimm_2']
        ).toLocaleString('de-DE');
        this.schwimmbecken.inputs.schwimm_1 = parseFloat(
          this.saveData['schwimm_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('schwimmbecken', 'schwimm');
      this.getSingleRowComments('schwimmbecken', 'schwimm');
      this.determineSingleRowDif('schwimmbecken', 'schwimm');
      this.getSingleRowValues('SOZIALWA');
      if (this.saveData) {
        this.sozialwasser.inputs.SOZIALWA_2 = parseFloat(
          this.saveData['SOZIALWA_2']
        ).toLocaleString('de-DE');
        this.sozialwasser.inputs.SOZIALWA_1 = parseFloat(
          this.saveData['SOZIALWA_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('sozialwasser', 'SOZIALWA');
      this.getSingleRowComments('sozialwasser', 'SOZIALWA');
      this.determineSingleRowDif('sozialwasser', 'SOZIALWA');
      this.getSingleRowValues('sonstigeNutzung');
      if (this.saveData) {
        this.sonstigeNutzung.inputs.sonstigeNutzung_2 = parseFloat(
          this.saveData['sonstigeNutzung_2']
        ).toLocaleString('de-DE');
        this.sonstigeNutzung.inputs.sonstigeNutzung_1 = parseFloat(
          this.saveData['sonstigeNutzung_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('sonstigeNutzung', 'sonstigeNutzung');
      this.getSingleRowComments('sonstigeNutzung', 'sonstigeNutzung', true);
      this.determineSingleRowDif('sonstigeNutzung', 'sonstigeNutzung', true);
      this.getSingleRowValues('verluste');
      if (this.saveData) {
        this.verluste.inputs.VERLUSTE_2 = parseFloat(
          this.saveData['VERLUSTE_2']
        ).toLocaleString('de-DE');
        this.verluste.inputs.VERLUSTE_1 = parseFloat(
          this.saveData['VERLUSTE_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('verluste', 'VERLUSTE');
      this.getSingleRowComments('verluste', 'VERLUSTE');
      this.determineSingleRowDif('verluste', 'VERLUSTE');
      this.getSingleRowValues('abwasser');
      if (this.saveData) {
        this.abwasser.inputs.ABWASSER_2 = parseFloat(
          this.saveData['ABWASSER_2']
        ).toLocaleString('de-DE');
        this.abwasser.inputs.ABWASSER_1 = parseFloat(
          this.saveData['ABWASSER_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('abwasser', 'ABWASSER');
      this.getSingleRowComments('abwasser', 'ABWASSER');
      this.determineSingleRowDif('abwasser', 'ABWASSER');
      this.getSingleRowValues('kanal');
      if (this.saveData) {
        this.kanal.inputs.kanal_2 = parseFloat(
          this.saveData['kanal_2']
        ).toLocaleString('de-DE');
        this.kanal.inputs.kanal_1 = parseFloat(
          this.saveData['kanal_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('kanal', 'kanal');
      this.getSingleRowComments('kanal', 'kanal');
      this.determineSingleRowDif('kanal', 'kanal');
      this.getSingleRowValues('vorfluter');
      if (this.saveData) {
        this.vorfluter.inputs.vorfluter_2 = parseFloat(
          this.saveData['vorfluter_2']
        ).toLocaleString('de-DE');
        this.vorfluter.inputs.vorfluter_1 = parseFloat(
          this.saveData['vorfluter_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('vorfluter', 'vorfluter');
      this.getSingleRowComments('vorfluter', 'vorfluter');
      this.determineSingleRowDif('vorfluter', 'vorfluter');
      this.VORFLUT_NR = this.saveData.VORFLUT_NR;
      this.getSingleRowValues('untergrund');
      if (this.saveData) {
        this.untergrund.inputs.UNTERGRUND_2 = parseFloat(
          this.saveData['UNTERGRUND_2']
        ).toLocaleString('de-DE');
        this.untergrund.inputs.UNTERGRUND_1 = parseFloat(
          this.saveData['UNTERGRUND_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('untergrund', 'UNTERGRUND');
      this.getSingleRowComments('untergrund', 'UNTERGRUND');
      this.determineSingleRowDif('untergrund', 'UNTERGRUND');
      this.UNTERGR_NR = this.saveData.UNTERGR_NR;
      this.getSingleRowValues('sonstigeBeseitigung');
      if (this.saveData) {
        this.sonstigeBeseitigung.inputs.sonstigeBeseitigung_2 = parseFloat(
          this.saveData['sonstigeBeseitigung_2']
        ).toLocaleString('de-DE');
        this.sonstigeBeseitigung.inputs.sonstigeBeseitigung_1 = parseFloat(
          this.saveData['sonstigeBeseitigung_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('sonstigeBeseitigung', 'sonstigeBeseitigung');
      this.getSingleRowComments('sonstigeBeseitigung', 'sonstigeBeseitigung');
      this.determineSingleRowDif('sonstigeBeseitigung', 'sonstigeBeseitigung');

      this.wasseraufkommen_2_ly = this.calculateWaterOccurrenceLy('2');
      this.wasseraufkommen_1_ly = this.calculateWaterOccurrenceLy('1');
      this.wasseraufkommen_G_ly = this.calculateWaterOccurrenceLy('total');

      this.allgemeineBemerkungen = this.saveData.allgemeineBemerkungen;
    };
    await fillLastYearsValues();
    this.multisort(this.gewinnungsanlagen, 'label', [this.gewinnungsanlagenLy]);
    this.multisort(this.gewinnungsanlagenOberflaeche, 'label', [
      this.gewinnungsanlagenOberflaecheLy,
    ]);
    this.getEigengewinnungComments();
    this.getEigengewinnungOberflaecheComments('gewinnungsanlagenOberflaeche');

    this.$watch('EINLEIT_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.einleitungOhneNutzung.sumChanged = true;
      }
    });
    this.$watch('GRUEN_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.beregnung.sumChanged = true;
      }
    });
    this.$watch('schwimm_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.schwimmbecken.sumChanged = true;
      }
    });
    this.$watch('SOZIALWA_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.sozialwasser.sumChanged = true;
      }
    });
    this.$watch('sonstigeNutzung_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.sonstigeNutzung.sumChanged = true;
      }
    });
    this.$watch('VERLUSTE_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.verluste.sumChanged = true;
      }
    });
    this.$watch('ABWASSER_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.abwasser.sumChanged = true;
      }
    });
    this.$watch('kanal_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.kanal.sumChanged = true;
      }
    });
    this.$watch('vorfluter_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.vorfluter.sumChanged = true;
      }
    });
    this.$watch('UNTERGRUND_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.untergrund.sumChanged = true;
      }
    });
    this.$watch('sonstigeBeseitigung_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.sonstigeBeseitigung.sumChanged = true;
      }
    });

    this.buildNewPreviousData(
      'wasserlieferanten',
      'BEZMENG',
      'HERKUNFT',
      'BEZUGSART',
      'wasserempfaengerPl',
      'ABMENGE',
      'ZIEL',
      'ABGABEART'
    );

    // remove empty wasserlieferanten | 2 loops because index of el changes with removal
    let emptyWasserlieferanten = [];
    this.wasserlieferanten.forEach((el, index) => {
      if (!this.entryIsNotEmpty(index, 'wasserlieferanten')) {
        emptyWasserlieferanten.push(el);
      }
    });
    emptyWasserlieferanten.forEach((el) => {
      this.remove(
        this.wasserlieferanten,
        el,
        this.wasserlieferantenLy,
        'wasserlieferanten'
      );
    });

    // remove empty wasserermpfaenger | 2 loops because index of el changes with removal
    let emptyWasserempfaenger = [];
    this.wasserempfaengerPl.forEach((el, index) => {
      if (!this.entryIsNotEmpty(index, 'wasserempfaengerPl')) {
        emptyWasserempfaenger.push(el);
      }
    });
    emptyWasserempfaenger.forEach((el) => {
      this.remove(
        this.wasserempfaengerPl,
        el,
        this.wasserempfaengerLy,
        'wasserempfaengerPl'
      );
    });

    this.stampData = this.getStampData();
    this.getInternalNotes();
    await this.setDataLoaded(true);
    this.$forceUpdate();
    window.addEventListener('scroll', this.handleScroll);
    const tabSections = document.querySelectorAll('[data-section="row"]');
    await this.handleTabKey(tabSections);
    this.observeDOMMutations();
  },
  mounted() {
    this.$nextTick(() => {
      if (
        !(
          this.status === 'freigegeben' ||
          (this.status === 'pruefung' && this.usertype !== 1) ||
          this.readonly
        )
      ) {
        console.log('will save automatically');
        this.timer = setInterval(() => {
          this.onSave();
        }, 4 * 60 * 1000);
      }
      this.stopAutosaveAfterX(12 * 60 * 60 * 1000);
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    window.removeEventListener('scroll', this.handleScroll);
    this.removeTabListener();
    this.DOMObserver.disconnect();
  },
  methods: {
    getInternalNotes,
    stopAutosaveAfterX,
    getSingleRowComments,
    determineSingleRowDif,
    buildNewPreviousData,
    assemblePreviousData,
    entryIsNotEmpty,
    observeDOMMutations,
    removeTabListener,
    detectTabKey,
    handleTabKey,
    deleteEgRow,
    deleteRow,
    savePreviousId,
    sendIdUpdateRequest,
    getStampDataToSend,
    getStampData,
    sendWStatusPruefung,
    getEigengewinnungComments,
    getEigengewinnungOberflaecheComments,
    multisort,
    getFreigeber,
    setStatusesOnLoad,
    getEigengewinnung,
    getEigengewinnungOberflaeche,
    getFremdbezug,
    getFremdabgabe,
    getNote,
    getUserInfo,
    beforeLoadForm,
    getRelativeDifference,
    setDataLoaded,
    handleScroll,
    updateValue,
    getAuthToken,
    toggleBemerkung,
    toggleMenu,
    toDashboard,
    formatDigitsOnly,
    formatDigitsOnlySingleRow,
    handleInput,
    handleInputSingleRow,
    getSingleRowValues,
    createGroups,
    currentDate,
    currentYear,
    formatDataForPrintForm,
    async onSave(alerts) {
      // when only saving, not sending, set status to previous status
      if (this.saveData.status === 'ausstehend') {
        this.$store.dispatch('setStatusPending');
      } else if (this.saveData.status === 'bearbeitung') {
        this.$store.dispatch('setStatusBearbeitung');
      } else if (this.saveData.status === 'pruefung') {
        this.$store.dispatch('setStatusPruefung');
      } else if (this.saveData.status === 'korrektur') {
        this.$store.dispatch('setStatusKorrektur');
      }

      const params = new URLSearchParams({
        formtype: this.formtype,
        user: this.user,
        jahr: this.year,
      }).toString();
      const url = '/API-DB/formData?' + params;
      let { saveData } = this.getSaveData();
      console.log('saveData: ', saveData);
      const stringifiedSaveData = JSON.stringify(saveData);
      this.$store.dispatch('setLoadingTrue');

      try {
        let response = await axios.post(url, stringifiedSaveData, {
          headers: { 'Content-Type': 'application/json' },
        });
        console.log('response: ', response);
        if (alerts) {
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch('setAlertMsg', 'Formular gespeichert');
          this.$store.dispatch('setAlertType', 'info');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 3 * 1000);
        }
      } catch (error) {
        console.log('error: ', error);
        if (alerts) {
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch(
            'setAlertMsg',
            'Formular konnte nicht gespeichert werden'
          );
          this.$store.dispatch('setAlertType', 'error');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 5 * 1000);
        }
      }

      this.$store.dispatch('setLoadingFalse');
    },
    checkCommentsOpened,
    async onSubmit() {
      if (this.usertype !== 1) {
        if (
          !this.checkCommentsOpened(
            [
              this.gewinnungsanlagen,
              this.wasserlieferanten,
              this.wasserempfaengerPl,
            ],
            [
              this.einleitungOhneNutzung,
              this.beregnung,
              this.schwimmbecken,
              this.sozialwasser,
              this.sonstigeNutzung,
              this.verluste,
              this.abwasser,
              this.kanal,
              this.vorfluter,
              this.untergrund,
              this.sonstigeBeseitigung,
            ]
          )
        ) {
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch(
            'setAlertMsg',
            'Bitte öffnen Sie alle Kommentare bevor Sie das Formular absenden.'
          );
          this.$store.dispatch('setAlertType', 'info');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 3 * 1000);
          return;
        }
        if (
          this.getRelativeDifference(
            this.wasseraufkommen.wasseraufkommen_G,
            this.wasseraufkommen_G_ly
          ) > 40 &&
          !(
            this.wasseraufkommen_G_ly === 0 || this.wasseraufkommen_G_ly === '0'
          ) &&
          !this.eigenFremdSummeCheck
        ) {
          this.eigenFremdSummeHint =
            'Bitte überprüfen Sie ihre Eingaben für die Eigengewinnung erneut. Die Abweichung zu den vorherigen Werten beträgt mehr als 40 Prozent.';
          goTo('#eigen-fremd-summe');
          return;
        }
        await this.$refs.form.validate();
      }
      if (this.valid === true || this.usertype === 1) {
        this.progressState();
        const params = new URLSearchParams({
          formtype: this.formtype,
          user: this.user,
          jahr: this.year,
        }).toString();
        const url = '/API-DB/formData?' + params;
        let { saveData } = this.getSaveData();
        console.log('saveData: ', saveData);
        const stringifiedSaveData = JSON.stringify(saveData);
        console.log('stringifiedSaveData: ', stringifiedSaveData);
        this.$store.dispatch('setLoadingTrue');
        try {
          let response = await axios.post(url, stringifiedSaveData, {
            headers: { 'Content-Type': 'application/json' },
          });
          console.log('response: ', response);
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch('setAlertMsg', 'Formular versandt');
          this.$store.dispatch('setAlertType', 'info');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 3 * 1000);
          this.$store.dispatch('setLoadingFalse');
          this.toDashboard(this.usertype);
        } catch (error) {
          console.log('error: ', error);
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch(
            'setAlertMsg',
            'Formular konnte nicht versandt werden'
          );
          this.$store.dispatch('setAlertType', 'error');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 5 * 1000);
          this.$store.dispatch('setLoadingFalse');
        }
      }
    },
    async onAccept() {
      if (!(this.erste_freigabe || this.zweite_freigabe)) {
        this.$store.dispatch('setAlertShow', true);
        this.$store.dispatch(
          'setAlertMsg',
          'Sie sind nicht berechtigt, Formulare freizugeben'
        );
        this.$store.dispatch('setAlertType', 'info');
        setTimeout(() => {
          this.$store.commit('setAlertShow', false);
        }, 3 * 1000);
        return;
      }
      if (
        (this.sonstigeNutzung.inputs.sonstigeNutzung_2 &&
          (this.sonstigeNutzung.inputs.sonstigeNutzung_2 != 0 ||
            this.sonstigeNutzung.inputs.sonstigeNutzung_2 !== '0')) ||
        (this.sonstigeNutzung.inputs.sonstigeNutzung_1 &&
          (this.sonstigeNutzung.inputs.sonstigeNutzung_1 != 0 ||
            this.sonstigeNutzung.inputs.sonstigeNutzung_1 !== '0'))
      ) {
        this.$store.dispatch('setAlertShow', true);
        this.$store.dispatch(
          'setAlertMsg',
          'Sonstige Nutzung muss in eine andere Zeile umgetragen werden, bevor das Formular freigegeben werden kann.'
        );
        this.$store.dispatch('setAlertType', 'info');
        setTimeout(() => {
          this.$store.commit('setAlertShow', false);
        }, 3 * 1000);
        return;
      }
      if (
        (this.sonstigeBeseitigung.inputs.sonstigeBeseitigung_2 &&
          (this.sonstigeBeseitigung.inputs.sonstigeBeseitigung_2 != 0 ||
            this.sonstigeBeseitigung.inputs.sonstigeBeseitigung_2 !== '0')) ||
        (this.sonstigeBeseitigung.inputs.sonstigeBeseitigung_1 &&
          (this.sonstigeBeseitigung.inputs.sonstigeBeseitigung_1 != 0 ||
            this.sonstigeBeseitigung.inputs.sonstigeBeseitigung_1 !== '0'))
      ) {
        this.$store.dispatch('setAlertShow', true);
        this.$store.dispatch(
          'setAlertMsg',
          'Sonstige Beseitigung muss in eine andere Zeile umgetragen werden, bevor das Formular freigegeben werden kann.'
        );
        this.$store.dispatch('setAlertType', 'info');
        setTimeout(() => {
          this.$store.commit('setAlertShow', false);
        }, 3 * 1000);
        return;
      }
      if (this.status === 'erste_freigabe') {
        await this.$store.dispatch('setStatusFreigegeben');
      } else {
        await this.$store.dispatch('setStatusErsteFreigabe');
      }
      const params = new URLSearchParams({
        formtype: this.formtype,
        user: this.user,
        jahr: this.year,
      }).toString();
      const url = '/API-DB/formData?' + params;
      let { saveData } = this.getSaveData();
      if (this.status === 'erste_freigabe') {
        saveData.freigeber_1 = this.usermail;
      } else {
        saveData.freigeber_2 = this.usermail;
      }
      console.log('saveData: ', saveData);
      const stringifiedSaveData = JSON.stringify(saveData);
      console.log('stringifiedSaveData: ', stringifiedSaveData);
      this.$store.dispatch('setLoadingTrue');
      await axios
        .post(url, stringifiedSaveData, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(function (response) {
          console.log('response: ', response);
          alert('Formular versandt');
        })
        .catch(function (error) {
          console.log('error: ', error);
        });
      this.$store.dispatch('setLoadingFalse');
      this.toDashboard(this.usertype);
    },
    progressState,
    getSaveData() {
      let saveData = {};
      let comments = [];

      //  eigengewinnung
      let gewinnungsanlagen2ndSem = 0;
      let gewinnungsanlagen1stSem = 0;
      let gewinnungsanlagenSum = 0;
      for (let i = 0; i < this.gewinnungsanlagen.length; i++) {
        gewinnungsanlagen2ndSem =
          gewinnungsanlagen2ndSem +
          parseInt(this.gewinnungsanlagen[i].value1.replaceAll('.', ''));
        gewinnungsanlagen1stSem =
          gewinnungsanlagen1stSem +
          parseInt(this.gewinnungsanlagen[i].value2.replaceAll('.', ''));
        gewinnungsanlagenSum =
          gewinnungsanlagenSum +
          parseInt(this.gewinnungsanlagen[i].value1.replaceAll('.', '')) +
          parseInt(this.gewinnungsanlagen[i].value2.replaceAll('.', ''));
      }

      this.grundwasserfoerdermenge.MENGEGW_2 = gewinnungsanlagen2ndSem;
      this.grundwasserfoerdermenge.MENGEGW_1 = gewinnungsanlagen1stSem;
      this.grundwasserfoerdermenge.MENGEGW_G = gewinnungsanlagenSum;

      saveData = { ...saveData, ...this.grundwasserfoerdermenge };

      //  eigengewinnung Oberflaechenwasser
      let gewinnungsanlagenOberflaeche2ndSem = 0;
      let gewinnungsanlagenOberflaeche1stSem = 0;
      let gewinnungsanlagenOberflaecheSum = 0;
      for (let i = 0; i < this.gewinnungsanlagenOberflaeche.length; i++) {
        gewinnungsanlagenOberflaeche2ndSem =
          gewinnungsanlagenOberflaeche2ndSem +
          parseInt(
            this.gewinnungsanlagenOberflaeche[i].value1.replaceAll('.', '')
          );
        gewinnungsanlagenOberflaeche1stSem =
          gewinnungsanlagenOberflaeche1stSem +
          parseInt(
            this.gewinnungsanlagenOberflaeche[i].value2.replaceAll('.', '')
          );
        gewinnungsanlagenOberflaecheSum =
          gewinnungsanlagenOberflaecheSum +
          parseInt(
            this.gewinnungsanlagenOberflaeche[i].value1.replaceAll('.', '')
          ) +
          parseInt(
            this.gewinnungsanlagenOberflaeche[i].value2.replaceAll('.', '')
          );
      }

      this.oberflaechenwasserfoerdermenge.MENGEOW_2 =
        gewinnungsanlagenOberflaeche2ndSem;
      this.oberflaechenwasserfoerdermenge.MENGEOW_1 =
        gewinnungsanlagenOberflaeche1stSem;
      this.oberflaechenwasserfoerdermenge.MENGEOW_G =
        gewinnungsanlagenOberflaecheSum;

      saveData = { ...saveData, ...this.oberflaechenwasserfoerdermenge };

      // wasserlieferanten
      let wasserlieferantenSaveData = [];
      while (this.wasserlieferanten.length < this.fremdbezugMaxLength) {
        this.add(this.wasserlieferanten, true);
      }
      for (let i = 0; i < this.wasserlieferanten.length; i++) {
        let dbnum = i + 1;

        const label = 'HERKUNFT' + dbnum;
        const val1 = 'BEZMENG' + dbnum + '_2';
        const val2 = 'BEZMENG' + dbnum + '_1';
        const total = 'BEZMENG' + dbnum + '_G';
        const note = 'BEZMENG' + dbnum + '_note';
        const bezugsart = 'BEZUGSART' + dbnum;

        wasserlieferantenSaveData[i] = {};
        wasserlieferantenSaveData[i][label] = this.wasserlieferanten[i].label;
        wasserlieferantenSaveData[i][val1] = parseFloat(
          this.wasserlieferanten[i].value1.replaceAll('.', '')
        );
        wasserlieferantenSaveData[i][val2] = parseFloat(
          this.wasserlieferanten[i].value2.replaceAll('.', '')
        );
        wasserlieferantenSaveData[i][total] = parseFloat(
          this.wasserlieferanten[i].sum.replaceAll('.', '')
        );
        wasserlieferantenSaveData[i][bezugsart] =
          this.wasserlieferanten[i].bezugsart;
        wasserlieferantenSaveData[i][note] = this.wasserlieferanten[i].note;

        wasserlieferantenSaveData.forEach((el) => {
          saveData = { ...saveData, ...el };
        });
        if (this.wasserlieferanten[i].labelComment) {
          comments.push({
            key: label,
            value: this.wasserlieferanten[i].labelComment,
          });
        }
        if (this.wasserlieferanten[i].value1Comment) {
          comments.push({
            key: val1,
            value: this.wasserlieferanten[i].value1Comment,
          });
        }
        if (this.wasserlieferanten[i].value2Comment) {
          comments.push({
            key: val2,
            value: this.wasserlieferanten[i].value2Comment,
          });
        }
      }

      // wasserempfaenger
      let wasserempfaengerSaveData = [];
      while (this.wasserempfaengerPl.length < this.fremdabgabeMaxLength) {
        this.add(this.wasserempfaengerPl, true);
      }
      for (let i = 0; i < this.wasserempfaengerPl.length; i++) {
        let dbnum = i + 1;
        const label = 'ZIEL' + dbnum;
        const val1 = 'ABMENGE' + dbnum + '_2';
        const val2 = 'ABMENGE' + dbnum + '_1';
        const total = 'ABMENGE' + dbnum + '_G';
        const note = 'ABMENGE' + dbnum + '_note';
        const abgabeart = 'ABGABEART' + dbnum;

        comments.push({
          key: label,
          value: this.wasserempfaengerPl[i].labelComment,
        });
        comments.push({
          key: val1,
          value: this.wasserempfaengerPl[i].value1Comment,
        });
        comments.push({
          key: val2,
          value: this.wasserempfaengerPl[i].value2Comment,
        });

        wasserempfaengerSaveData[i] = {};
        wasserempfaengerSaveData[i][label] = this.wasserempfaengerPl[i].label;
        wasserempfaengerSaveData[i][val1] = parseInt(
          this.wasserempfaengerPl[i].value1.replaceAll('.', '')
        );
        wasserempfaengerSaveData[i][val2] = parseInt(
          this.wasserempfaengerPl[i].value2.replaceAll('.', '')
        );
        wasserempfaengerSaveData[i][total] = parseInt(
          this.wasserempfaengerPl[i].sum.replaceAll('.', '')
        );
        wasserempfaengerSaveData[i][abgabeart] =
          this.wasserempfaengerPl[i].abgabeart;
        wasserempfaengerSaveData[i][note] = this.wasserempfaengerPl[i].note;

        wasserempfaengerSaveData.forEach((el) => {
          saveData = { ...saveData, ...el };
        });
      }

      saveData = {
        ...saveData,
        BEDARF_2: this.wasserbedarf.BEDARF_2,
        BEDARF_1: this.wasserbedarf.BEDARF_1,
        BEDARF_G: this.wasserbedarf.BEDARF_G,
      };

      const einleitungOhneNutzungSaveData = this.getSingleRowSaveData(
        'einleitungOhneNutzung',
        'EINLEIT'
      );
      comments.push({
        key: 'EINLEIT_label',
        value: this.einleitungOhneNutzung.labelComment,
      });
      comments.push({
        key: 'EINLEIT_2',
        value: this.einleitungOhneNutzung.value1Comment,
      });
      comments.push({
        key: 'EINLEIT_1',
        value: this.einleitungOhneNutzung.value2Comment,
      });

      const beregnungSaveData = this.getSingleRowSaveData('beregnung', 'GRUEN');
      if (this.beregnung.value1Comment) {
        comments.push({
          key: 'GRUEN_2',
          value: this.beregnung.value1Comment,
        });
      }
      if (this.beregnung.value2Comment) {
        comments.push({
          key: 'GRUEN_1',
          value: this.beregnung.value2Comment,
        });
      }
      const schwimmbeckenSaveData = this.getSingleRowSaveData(
        'schwimmbecken',
        'schwimm'
      );
      if (this.schwimmbecken.value1Comment) {
        comments.push({
          key: 'schwimm_2',
          value: this.schwimmbecken.value1Comment,
        });
      }
      if (this.schwimmbecken.value2Comment) {
        comments.push({
          key: 'schwimm_1',
          value: this.schwimmbecken.value2Comment,
        });
      }
      const sozialwasserSaveData = this.getSingleRowSaveData(
        'sozialwasser',
        'SOZIALWA'
      );
      if (this.sozialwasser.value1Comment) {
        comments.push({
          key: 'SOZIALWA_2',
          value: this.sozialwasser.value1Comment,
        });
      }
      if (this.sozialwasser.value2Comment) {
        comments.push({
          key: 'SOZIALWA_1',
          value: this.sozialwasser.value2Comment,
        });
      }
      if (this.status === 'freigegeben') {
        sozialwasserSaveData['SOZIALWA_2'] =
          schwimmbeckenSaveData['schwimm_2'] +
          sozialwasserSaveData['SOZIALWA_2'];
        sozialwasserSaveData['SOZIALWA_1'] =
          schwimmbeckenSaveData['schwimm_1'] +
          sozialwasserSaveData['SOZIALWA_1'];
        sozialwasserSaveData['SOZIALWA_G'] =
          schwimmbeckenSaveData['schwimm_G'] +
          sozialwasserSaveData['SOZIALWA_G'];
      }
      const sonstigeNutzungSaveData = this.getSingleRowSaveData(
        'sonstigeNutzung',
        'sonstigeNutzung'
      );
      if (this.sonstigeNutzung.label) {
        sonstigeNutzungSaveData['sonstigeNutzung_label'] =
          this.sonstigeNutzung.label;
      } else {
        sonstigeNutzungSaveData['sonstigeNutzung_label'] = '';
      }
      if (this.sonstigeNutzung.value1Comment) {
        comments.push({
          key: 'sonstigeNutzung_2',
          value: this.sonstigeNutzung.value1Comment,
        });
      }
      if (this.sonstigeNutzung.value2Comment) {
        comments.push({
          key: 'sonstigeNutzung_1',
          value: this.sonstigeNutzung.value2Comment,
        });
      }
      const verlusteSaveData = this.getSingleRowSaveData(
        'verluste',
        'VERLUSTE'
      );
      if (this.verluste.value1Comment) {
        comments.push({
          key: 'VERLUSTE_2',
          value: this.verluste.value1Comment,
        });
      }
      if (this.verluste.value2Comment) {
        comments.push({
          key: 'VERLUSTE_1',
          value: this.verluste.value2Comment,
        });
      }
      const abwasserSaveData = this.getSingleRowSaveData(
        'abwasser',
        'ABWASSER'
      );
      if (this.abwasser.value1Comment) {
        comments.push({
          key: 'ABWASSER_2',
          value: this.abwasser.value1Comment,
        });
      }
      if (this.abwasser.value2Comment) {
        comments.push({
          key: 'ABWASSER_1',
          value: this.abwasser.value2Comment,
        });
      }
      const kanalSaveData = this.getSingleRowSaveData('kanal', 'kanal');
      if (this.kanal.value1Comment) {
        comments.push({
          key: 'kanal_2',
          value: this.kanal.value1Comment,
        });
      }
      if (this.kanal.value2Comment) {
        comments.push({
          key: 'kanal_1',
          value: this.kanal.value2Comment,
        });
      }
      const vorfluterSaveData = this.getSingleRowSaveData(
        'vorfluter',
        'vorfluter'
      );
      if (this.vorfluter.value1Comment) {
        comments.push({
          key: 'vorfluter_2',
          value: this.vorfluter.value1Comment,
        });
      }
      if (this.vorfluter.value2Comment) {
        comments.push({
          key: 'vorfluter_1',
          value: this.vorfluter.value2Comment,
        });
      }
      const WASSERLAEUFE_2 =
        kanalSaveData['kanal_2'] + vorfluterSaveData['vorfluter_2'];
      const WASSERLAEUFE_1 =
        kanalSaveData['kanal_1'] + vorfluterSaveData['vorfluter_1'];
      const WASSERLAEUFE_G =
        kanalSaveData['kanal_G'] + vorfluterSaveData['vorfluter_G'];
      const untergrundSaveData = this.getSingleRowSaveData(
        'untergrund',
        'UNTERGRUND'
      );
      if (this.untergrund.value1Comment) {
        comments.push({
          key: 'UNTERGRUND_2',
          value: this.untergrund.value1Comment,
        });
      }
      if (this.untergrund.value2Comment) {
        comments.push({
          key: 'UNTERGRUND_1',
          value: this.untergrund.value2Comment,
        });
      }
      const sonstigeBeseitigungSaveData = this.getSingleRowSaveData(
        'sonstigeBeseitigung',
        'sonstigeBeseitigung'
      );
      if (this.sonstigeBeseitigung.value1Comment) {
        comments.push({
          key: 'sonstigeBeseitigung_2',
          value: this.sonstigeBeseitigung.value1Comment,
        });
      }
      if (this.sonstigeBeseitigung.value2Comment) {
        comments.push({
          key: 'sonstigeBeseitigung_1',
          value: this.sonstigeBeseitigung.value2Comment,
        });
      }

      let { eigengewinnung, eigengewinnungComments } =
        this.getEntnehmerAndFoerderData();
      comments.push(...eigengewinnungComments);

      const eigengewinnungOberflaeche = [];
      for (let i = 0; i < this.gewinnungsanlagenOberflaeche.length; i++) {
        // eigengewinnung oberflächenwasser
        eigengewinnungOberflaeche[i] = {};
        eigengewinnungOberflaeche[i]['FOEMENG_2'] = parseInt(
          this.gewinnungsanlagenOberflaeche[i].value1.replace(/[^\d]/g, '')
        );
        eigengewinnungOberflaeche[i]['FOEMENG_1'] = parseInt(
          this.gewinnungsanlagenOberflaeche[i].value2.replace(/[^\d]/g, '')
        );
        eigengewinnungOberflaeche[i]['FOEMENG_G'] = parseInt(
          this.gewinnungsanlagenOberflaeche[i].sum.replace(/[^\d]/g, '')
        );
        eigengewinnungOberflaeche[i]['note'] =
          this.gewinnungsanlagenOberflaeche[i].note;

        if (this.gewinnungsanlagenOberflaeche[i].label) {
          eigengewinnungOberflaeche[i]['OSNAME'] =
            this.gewinnungsanlagenOberflaeche[i].label;
        } else {
          eigengewinnungOberflaeche[i]['OSNAME'] = '';
        }
        eigengewinnungOberflaeche[i]['addedByUser'] =
          this.gewinnungsanlagenOberflaeche[i].addedByUser + '';
        eigengewinnungOberflaeche[i]['FOENR_GES'] =
          this.gewinnungsanlagenOberflaeche[i].FOENR_GES;
        if (!eigengewinnungOberflaeche[i]['FOENR_GES']) {
          eigengewinnungOberflaeche[i]['FOENR_GES'] = '';
        }
        // comments
        let eigengewinnungOberflaecheComments = [];
        const key1 = 'EIGEN_OBERFLAECHE' + i + '_OSNAME';
        const key2 = 'EIGEN_OBERFLAECHE' + i + '_FOEMENG_2';
        const key3 = 'EIGEN_OBERFLAECHE' + i + '_FOEMENG_1';

        if (this.gewinnungsanlagenOberflaeche[i].labelComment) {
          eigengewinnungOberflaecheComments.push({
            key: key1,
            value: this.gewinnungsanlagenOberflaeche[i].labelComment,
            label: this.gewinnungsanlagenOberflaeche[i].label,
          });
        }
        if (this.gewinnungsanlagenOberflaeche[i].value1Comment) {
          eigengewinnungOberflaecheComments.push({
            key: key2,
            value: this.gewinnungsanlagenOberflaeche[i].value1Comment,
            label: this.gewinnungsanlagenOberflaeche[i].label,
          });
        }
        if (this.gewinnungsanlagenOberflaeche[i].value2Comment) {
          eigengewinnungOberflaecheComments.push({
            key: key3,
            value: this.gewinnungsanlagenOberflaeche[i].value2Comment,
            label: this.gewinnungsanlagenOberflaeche[i].label,
          });
        }
        comments.push(...eigengewinnungOberflaecheComments);
      }

      const authToken = this.getAuthToken();

      const stampData = this.getStampDataToSend();

      saveData = {
        ...saveData,
        ...einleitungOhneNutzungSaveData,
        EINLEIT_note: this.einleitungOhneNutzung.EINLEIT_note,
        EINLEIT_label: this.einleitungOhneNutzung.EINLEIT_label,
        EINLEIT_KZ: this.einleitungOhneNutzung.EINLEIT_KZ,
        ...beregnungSaveData,
        ...schwimmbeckenSaveData,
        ...sozialwasserSaveData,
        ...sonstigeNutzungSaveData,
        ...verlusteSaveData,
        ...abwasserSaveData,
        ...kanalSaveData,
        ...vorfluterSaveData,
        VORFLUT_NR: this.VORFLUT_NR,
        ...untergrundSaveData,
        UNTERGR_NR: this.UNTERGR_NR,
        ...sonstigeBeseitigungSaveData,
        allgemeineBemerkungen: this.allgemeineBemerkungen,
        eigengewinnung,
        eigengewinnungOberflaeche,
        comments,
        WASSERLAEUFE_2,
        WASSERLAEUFE_1,
        WASSERLAEUFE_G,
        status: this.status,
        INERFASSJ: this.year,
        lastchange: true,
        lastchange_user: true,
        JWT: authToken,
        ...stampData,
        hinweis_freigabe: this.internalNotes.hinweiseFreigebenden,
        hinweis_pruefung: this.internalNotes.hinweisePruefenden,
      };

      saveData = this.assemblePreviousData(
        saveData,
        'gewinnungsanlagenOberflaeche',
        'wasserlieferanten',
        this.fremdbezugMaxLength,
        'BEZMENG',
        'HERKUNFT',
        'BEZUGSART',
        'wasserempfaengerPl',
        this.fremdabgabeMaxLength,
        'ABMENGE',
        'ZIEL',
        'ABGABEART'
      );

      return { saveData: saveData };
    },
    getEntnehmerAndFoerderData() {
      let eigengewinnung = [];
      let eigengewinnungComments = [];

      for (let i = 0; i < this.gewinnungsanlagen.length; i++) {
        // data for SO_FOERDER
        eigengewinnung[i] = {};
        eigengewinnung[i]['FOEMENG_2'] = parseInt(
          this.gewinnungsanlagen[i].value1.replace(/[^\d]/g, '')
        );
        eigengewinnung[i]['FOEMENG_1'] = parseInt(
          this.gewinnungsanlagen[i].value2.replace(/[^\d]/g, '')
        );
        eigengewinnung[i]['FOEMENG_G'] = parseInt(
          this.gewinnungsanlagen[i].sum.replace(/[^\d]/g, '')
        );
        if (
          this.gewinnungsanlagen[i].note == null ||
          !this.gewinnungsanlagen[i].note
        ) {
          eigengewinnung[i]['note'] = '';
        } else {
          eigengewinnung[i]['note'] = this.gewinnungsanlagen[i].note;
        }

        // data for SO_ENTNEHMER
        eigengewinnung[i]['OSNAME'] = this.gewinnungsanlagen[i].label;
        eigengewinnung[i]['FOENR_GES'] = this.gewinnungsanlagen[i].FOENR_GES;
        if (!eigengewinnung[i]['FOENR_GES']) {
          eigengewinnung[i]['FOENR_GES'] = '';
        }
        eigengewinnung[i]['addedByUser'] =
          this.gewinnungsanlagen[i].addedByUser + '';

        // comments
        const key1 = 'EIGEN_' + i + '_OSNAME';
        const key2 = 'EIGEN_' + i + '_FOEMENG_2';
        const key3 = 'EIGEN_' + i + '_FOEMENG_1';

        if (this.gewinnungsanlagen[i].labelComment) {
          eigengewinnungComments.push({
            key: key1,
            value: this.gewinnungsanlagen[i].labelComment,
          });
        }
        if (this.gewinnungsanlagen[i].value1Comment) {
          eigengewinnungComments.push({
            key: key2,
            value: this.gewinnungsanlagen[i].value1Comment,
          });
        }
        if (this.gewinnungsanlagen[i].value2Comment) {
          eigengewinnungComments.push({
            key: key3,
            value: this.gewinnungsanlagen[i].value2Comment,
          });
        }
      }
      return {
        eigengewinnung: eigengewinnung,
        eigengewinnungComments: eigengewinnungComments,
      };
    },
    getSingleRowSaveData,
    sumValues,
    calculateValue,
    calculateBedarf,
    calculateSum,
    calculateWaterOccurrence,
    calculateWaterOccurrenceLy,
    add,
    remove,
  },
  watch: {
    async printing(newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        await this.$store.dispatch('setPrintLoadingTrue');
        this.printData = await this.formatDataForPrintForm('FOENR_GES');
        await this.$store.dispatch('setPrintLoadingFalse');
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}

.icon-wrapper {
  position: absolute;
  left: -2.5rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.icon-wrapper > i {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
</style>
