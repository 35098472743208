<template>
  <v-sheet width="500" elevation="4" class="pa-4 mb-4" data-section="row">
    <v-textarea
      outlined
      name="hinweisePruefenden"
      label="Hinweise vom Prüfenden"
      placeholder="Hinweise vom Prüfenden"
      :value="hinweisePruefenden"
      data-tabindex="2"
      @change="
        (e) => $emit('updateValue', 'internalNotes', 'hinweisePruefenden', e)
      "
      :color="hinweisePruefenden != '' ? 'error' : ''"
      class="red__txt"
    >
    </v-textarea>
    <v-textarea
      outlined
      name="hinweiseFreigebenden"
      label="Hinweise vom Freigebenden"
      placeholder="Hinweise vom Freigebenden"
      :value="hinweiseFreigebenden"
      data-tabindex="1"
      @change="
        (e) => $emit('updateValue', 'internalNotes', 'hinweiseFreigebenden', e)
      "
      :color="hinweiseFreigebenden != '' ? 'error' : ''"
      class="red__txt"
    >
    </v-textarea>
  </v-sheet>
</template>

<script>
export default {
  props: ['hinweiseFreigebenden', 'hinweisePruefenden'],
  emits: ['updateValue'],
};
</script>

<style lang="css">
.red__txt textarea {
  color: #cf4607 !important;
}
</style>
