<template>
  <v-alert
    dense
    dismissible
    :type="type"
    :value="show"
    class="temp__alert"
    width="20rem"
    >{{ msg }}</v-alert
  >
</template>

<script>
export default {
  props: ['show', 'time', 'msg', 'type'],
};
</script>

<style lang="css" scoped>
.temp__alert {
  position: fixed;
  top: 2rem;
  left: calc(50% - (20rem / 2));
}
</style>
