import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const getDefaultState = () => ({
  username: null,
  showNavDrawer: false,
  status: '',
  loading: false,
  usertype: null,
  usermail: null,
  printing: false,
  printLoading: false,
  printWindowWidth: null,
  firmaCommaBetrieb: null,
  mitglied: null,
  pruefungAlert: false,
  lastDbCat: null,
  approvedAlert: false,
  dateOfFormSubmission: null,
  alertShow: false,
  alertMsg: '',
  alertType: 'info',
  addressDialog: {
    show: false,
    persistent: false,
    required: false,
  },
  addressData: {
    title: '',
    firstName: '',
    lastName: '',
    companyPart1: '',
    companyPart2: '',
    companyPart3: '',
    email: '',
    mimitgl: '',
    tel: '',
    street: '',
    zip: '',
    city: '',
  },
  firstLogin: false,
});

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    setUser(state, username) {
      state.username = username;
    },
    setUsertype(state, usertype) {
      state.usertype = usertype;
    },
    setUsermail(state, usermail) {
      state.usermail = usermail;
    },
    closeNavDrawer(state) {
      state.showNavDrawer = false;
    },
    openNavDrawer(state) {
      state.showNavDrawer = true;
    },
    setStatusBearbeitung(state) {
      state.status = 'bearbeitung';
    },
    setStatusPruefung(state) {
      state.status = 'pruefung';
    },
    setStatusKorrektur(state) {
      state.status = 'korrektur';
    },
    setStatusErsteFreigabe(state) {
      state.status = 'erste_freigabe';
    },
    setStatusFreigegeben(state) {
      state.status = 'freigegeben';
    },
    setStatusPending(state) {
      state.status = 'ausstehend';
    },
    setLoadingTrue(state) {
      state.loading = true;
    },
    setLoadingFalse(state) {
      state.loading = false;
    },
    setResetState(state) {
      Object.assign(state, getDefaultState());
    },
    setPrintingFalse(state) {
      state.printing = false;
      console.log('printing set to false');
    },
    setPrintingTrue(state) {
      state.printing = true;
      console.log('printing set to true');
    },
    setPrintLoadingTrue(state) {
      state.printLoading = true;
    },
    setPrintLoadingFalse(state) {
      state.printLoading = false;
    },
    setPrintWindowWidth(state, value) {
      state.printWindowWidth = value;
    },
    setFirmaCommaBetrieb(state, value) {
      state.firmaCommaBetrieb = value;
    },
    setMitglied(state, value) {
      state.mitglied = value;
    },
    setPruefungAlert(state, value) {
      state.pruefungAlert = value;
    },
    setlastDbCat(state, value) {
      state.lastDbCat = value;
    },
    setApprovedAlert(state, value) {
      state.approvedAlert = value;
    },
    setDateOfFormSubmission(state, value) {
      state.dateOfFormSubmission = value;
    },
    setAlertShow(state, value) {
      state.alertShow = value;
    },
    setAlertMsg(state, value) {
      state.alertMsg = value;
    },
    setAlertType(state, value) {
      state.alertType = value;
    },
    setAddressDialog(state, value) {
      state.addressDialog = value;
    },
    setAddressData(state, value) {
      state.addressData = value;
    },
    setFirstLogin(state, value) {
      state.firstLogin = value;
    }
  },
  actions: {
    setUser({ commit }, username) {
      commit('setUser', username);
    },
    setUsertype({ commit }, usertype) {
      commit('setUsertype', usertype);
    },
    setUsermail({ commit }, usermail) {
      commit('setUsermail', usermail);
    },
    closeNavDrawer({ commit }) {
      commit('closeNavDrawer');
    },
    openNavDrawer({ commit }) {
      commit('openNavDrawer');
    },
    setStatusBearbeitung({ commit }) {
      commit('setStatusBearbeitung');
    },
    setStatusPruefung({ commit }) {
      commit('setStatusPruefung');
    },
    setStatusKorrektur({ commit }) {
      commit('setStatusKorrektur');
    },
    setStatusErsteFreigabe({ commit }) {
      commit('setStatusErsteFreigabe');
    },
    setStatusFreigegeben({ commit }) {
      commit('setStatusFreigegeben');
    },
    setStatusPending({ commit }) {
      commit('setStatusPending');
    },
    setLoadingTrue({ commit }) {
      commit('setLoadingTrue');
    },
    setLoadingFalse({ commit }) {
      commit('setLoadingFalse');
    },
    setResetState({ commit }) {
      commit('setResetState');
    },
    setPrintingFalse({ commit }) {
      commit('setPrintingFalse');
    },
    setPrintingTrue({ commit }) {
      commit('setPrintingTrue');
    },
    setPrintLoadingTrue({ commit }) {
      commit('setPrintLoadingTrue');
    },
    setPrintLoadingFalse({ commit }) {
      commit('setPrintLoadingFalse');
    },
    setPrintWindowWidth({ commit }, value) {
      commit('setPrintWindowWidth', value);
    },
    setFirmaCommaBetrieb({ commit }, value) {
      commit('setFirmaCommaBetrieb', value);
    },
    setMitglied({ commit }, value) {
      commit('setMitglied', value);
    },
    setPruefungAlert({ commit }, value) {
      commit('setPruefungAlert', value);
    },
    setlastDbCat({ commit }, value) {
      commit('setlastDbCat', value);
    },
    setApprovedAlert({ commit }, value) {
      commit('setApprovedAlert', value);
    },
    setDateOfFormSubmission({ commit }, value) {
      commit('setDateOfFormSubmission', value);
    },
    setAlertShow({ commit }, value) {
      commit('setAlertShow', value);
    },
    setAlertMsg({ commit }, value) {
      commit('setAlertMsg', value);
    },
    setAlertType({ commit }, value) {
      commit('setAlertType', value);
    },
    setAddressDialog({ commit }, value) {
      commit('setAddressDialog', value);
    },
    setAddressData({ commit }, value) {
      commit('setAddressData', value);
    },
    setFirstLogin({ commit }, value) {
      commit('setFirstLogin', value);
    }
  },
  modules: {},
});
