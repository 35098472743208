<template>
  <v-container class="fixed-container" id="fixed-container">
    <v-row
      dense
      :class="
        this.$store.state.showNavDrawer ? 'd-none fixed-row' : 'fixed-row'
      "
      id="fixed-row"
    >
      <v-col
        cols="6"
        sm="8"
        lg="3"
        xl="4"
        order="first"
        class="align-self-center pe-0 mt-3 mt-lg-0"
      >
        <h3 id="timespan" class="opacity-xs-0">Zeitraum</h3>
      </v-col>
      <v-col cols="6" sm="4" order-lg="2" :lg="lg" class="d-none d-lg-block">
        <v-text-field
          :value="'Juli - Dez. ' + (year - 1)"
          readonly
          class="input__text__right"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="4" :lg="lg" order-lg="4" class="d-none d-lg-block">
        <v-text-field
          :value="'Jan. - Juni ' + year"
          readonly
          class="input__text__right"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="4" :lg="lg" order-lg="6" class="d-none d-lg-block">
        <v-text-field
          value="Gesamtzeitraum"
          readonly
          class="d-none d-lg-block input__text__right"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex note__del__col" order-lg="8"
        ><div class="div__spacer"></div
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: ['saveData', 'lg', 'year', 'readonly'],
  computed: {
    ...mapState({
      status: (state) => state.status,
    }),
  },
};
</script>

<style lang="css" scoped>
.fixed-container {
  padding-left: 12px;
  padding-right: 12px;
}

.sticky.fixed-row {
  position: fixed;
  top: 0;
  z-index: 10;
  margin-left: 1.4rem;
  width: calc((var(--container-width) - 48px - 32px - 24px - 14px));
  pointer-events: none;
}

.sticky.fixed-row .div__spacer {
  display: none;
}

.div__spacer {
  width: 52px;
}

@media (max-width: 599.98px) {
  .sticky.fixed-row {
    margin-left: 2.2rem;
  }
}

@media (min-width: 600px) {
  .sticky.fixed-row {
    margin-left: 2.95rem;
  }
}

@media (min-width: 1264px) {
  .sticky.fixed-row {
    margin-left: 1.1rem;
  }
}
</style>
