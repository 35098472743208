<template>
  <v-row dense data-section="row">
    <v-col
      :cols="usertype === 1 && (variant || variant == '') ? 5 : 6"
      :sm="usertype === 1 && (variant || variant == '') ? 7 : 8"
      :lg="usertype === 1 && (variant || variant == '') ? 2 : 3"
      :xl="usertype === 1 && (variant || variant == '') ? 3 : 4"
      order="first"
      class="d-flex"
    >
      <v-tooltip bottom nudge-top="20">
        <template v-slot:activator="{ on: tooltip }">
          <v-text-field
            v-on="tooltip"
            :value="labelInputComponent"
            @input="(e) => $emit('updateValue', fieldGroup, 'label', e, index)"
            :label="addedByUserComponent ? labelInputLabel : ''"
            :class="
              readonly
                ? ''
                : dataSingleComponent.labelComment ||
                  labelDifComponent === 'dif'
                ? 'orange__bg'
                : addedByUserComponent &&
                  (!dataSingleComponent.labelComment ||
                    dataSingleComponent.labelComment === '')
                ? 'blue__bg__input red__txt'
                : ''
            "
            :readonly="addedByUserComponent ? false : true"
            persistent-hint
            :hint="dataSingleComponent.labelComment"
            :data-tabindex="addedByUserComponent ? index + 0.2 : null"
            maxlength="40"
          >
            <template
              v-slot:prepend-inner
              v-if="
                addedByUserComponent &&
                (status === 'pruefung' ||
                  (status !== 'bearbeitung' &&
                    dataSingleComponent.labelComment &&
                    dataSingleComponent.labelComment !== ''))
              "
            >
              <v-menu
                offset-y
                :close-on-content-click="false"
                :value="dataSingle.labelCommentMenu"
                @input="
                  (e) =>
                    $emit(
                      'updateValue',
                      fieldGroup,
                      'labelCommentMenu',
                      e,
                      index
                    )
                "
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-icon
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        v-if="
                          dataSingleComponent.labelComment === '' ||
                          !dataSingleComponent.labelComment
                        "
                        @click="
                          toggleMenuComponent(
                            'toggleMenu',
                            dataSingle,
                            'labelCommentMenu'
                          )
                        "
                        v-show="!printing"
                        :data-tabindex="
                          addedByUserComponent ? index + 0.1 : null
                        "
                      >
                        mdi-note-edit-outline</v-icon
                      >
                      <v-icon
                        v-else
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        :color="
                          labelCommentOpenedComponent || readonly ? '' : 'white'
                        "
                        @click="
                          toggleMenuComponent(
                            'toggleMenu',
                            dataSingle,
                            'labelCommentMenu'
                          )
                        "
                        v-show="!printing"
                        :data-tabindex="
                          addedByUserComponent ? index + 0.1 : null
                        "
                        >mdi-note-text
                      </v-icon>
                    </template>
                    <span>Kommentar</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-textarea
                        type="text"
                        v-model="dataSingleComponent.labelComment"
                        label="Kommentar"
                        class="blue__bg__input"
                        :disabled="status !== 'pruefung'"
                      >
                      </v-textarea>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-btn
                  class="mb-4 bemerkung__btn"
                  @click="
                    toggleMenuComponent(
                      'toggleMenu',
                      dataSingleComponent,
                      'labelCommentMenu'
                    )
                  "
                  >OK</v-btn
                >
              </v-menu>
            </template>
            <template v-slot:append v-if="rights && usertype !== 1">
              <span>W.-Recht: {{ rights }} m³/a</span>
            </template>
            <template v-slot:append v-else>
              <span
                :style="addedByUserComponent ? '' : 'white-space: pre-wrap;'"
                >&#10;&nbsp;</span
              >
            </template>
          </v-text-field>
        </template>
        <span>{{ labelInputComponent }}</span>
      </v-tooltip>
      <v-tooltip bottom nudge-top="20">
        <template v-slot:activator="{ on: tooltip }">
          <v-text-field
            v-on="tooltip"
            maxlength="13"
            :value="dataSingleComponent[idFieldName]"
            @input="
              (e) => $emit('updateValue', fieldGroup, idFieldName, e, index)
            "
            v-if="usertype === 1 && idFieldName"
            :readonly="
              addedByUserComponent === true && !readonly ? false : true
            "
            @focus="(e) => $emit('savePreviousId', e, dataSingleComponent)"
            @blur="(e) => $emit('sendIdUpdateRequest', e, dataSingleComponent)"
            :data-tabindex="
              addedByUserComponent === true && !readonly ? index + 0.9 : null
            "
          >
            <template v-slot:append v-if="OSSTOCKWERK || OSHORIZONT">
              <span class=""
                >{{ OSSTOCKWERK ? 'Stockw.: ' + OSSTOCKWERK : '' }}&nbsp;</span
              ><span>{{ OSHORIZONT ? 'Horizont ' + OSHORIZONT : '' }}</span>
            </template>
            <template v-slot:append v-else-if="rights">
              <span class="">W.-Recht: {{ rights }} m³/a</span>
            </template>
          </v-text-field>
        </template>
        <span>{{ dataSingleComponent[idFieldName] }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="3"
      lg="1"
      v-if="usertype === 1 && (variant || variant == '')"
      order="2"
    >
      <v-text-field
        :value="variant"
        :label="variantLabel"
        :hint="variantHint"
        maxlength="1"
        :data-tabindex="!readonly ? index + 0.3 : null"
        @blur="
          (e) =>
            $emit('updateValue', fieldGroup, variantName, e.target.value, index)
        "
      >
      </v-text-field>
    </v-col>
    <v-col
      :cols="usertype === 1 && (variant || variant == '') ? 4 : 6"
      :sm="usertype === 1 && (variant || variant == '') ? 2 : 4"
      class="justify-end d-flex note__del__col"
      :order="usertype === 1 && (variant || variant == '') ? 3 : 2"
      order-lg="8"
      :lg="lg"
    >
      <div class="d-flex align-center pe-1">
        <v-menu
          offset-y
          :close-on-content-click="false"
          :value="dataSingle['bemerkungMenu']"
          @input="
            (e) => $emit('updateValue', fieldGroup, 'bemerkungMenu', e, index)
          "
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  v-if="dataSingle.note === ''"
                  @click="
                    toggleMenuComponent(
                      'toggleMenu',
                      dataSingle,
                      'bemerkungMenu'
                    )
                  "
                  class="d-print-none"
                  v-show="!printing"
                  :data-tabindex="index + 0.8"
                >
                  mdi-note-edit-outline</v-icon
                >
                <v-icon
                  v-else
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  color="orange"
                  @click="
                    toggleMenuComponent(
                      'toggleMenu',
                      dataSingle,
                      'bemerkungMenu'
                    )
                  "
                  class="d-print-none"
                  v-show="!printing"
                  :data-tabindex="index + 0.8"
                  >mdi-note-text
                </v-icon>
              </template>
              <span>Bemerkung</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-textarea
                  type="text"
                  :value="dataSingleComponent['note']"
                  @input="
                    (e) => $emit('updateValue', fieldGroup, 'note', e, index)
                  "
                  label="Bemerkung"
                  class="white__bg"
                >
                </v-textarea>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-btn
            class="mb-4 bemerkung__btn"
            @click="
              toggleMenuComponent('toggleMenu', dataSingle, 'bemerkungMenu')
            "
            >OK</v-btn
          >
        </v-menu>
      </div>
      <div class="d-flex align-center">
        <v-tooltip
          bottom
          v-if="
            (addedByUserComponent ||
              (!(type == 'EG' || type == 'EGO') && !readonly)) &&
            !printing
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-show="!printing"
              @click="handleDeleteClick()"
              slot="append"
              v-on="on"
              v-bind="attrs"
              :disabled="
                (usertype !== 1 && status === 'pruefung') ||
                status === 'freigegeben'
                  ? true
                  : false
              "
              class="d-print-none"
              :data-tabindex="
                (usertype !== 1 && status === 'pruefung') ||
                status === 'freigegeben'
                  ? null
                  : index + 0.8
              "
              v-if="deletable"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>Zeile löschen</span>
        </v-tooltip>
        <div style="width: 24px" v-else></div>
        <!-- <div
          style="width: 24px"
          v-if="
            (addedByUserComponent ||
              (!(type == 'EG' || type == 'EGO') && !readonly)) &&
            !printing
          "
        ></div> -->
      </div>
    </v-col>
    <v-col
      cols="12"
      :order="usertype === 1 && (variant || variant == '') ? 4 : 3"
      sm="4"
      class="d-lg-none"
    >
      <v-text-field
        :value="'Juli - Dez. ' + (year - 1)"
        readonly
      ></v-text-field>
    </v-col>
    <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="2"
      :order="usertype === 1 && (variant || variant == '') ? 5 : 4"
    >
      <v-text-field
        :value="value1Component"
        @input="(e) => $emit('updateValue', fieldGroup, 'value1', e, index)"
        :class="[
          'txt-align-end',
          readonly
            ? ''
            : dataSingleComponent.value1Comment ||
              dataSingleComponent.value1Dif === 'dif'
            ? 'orange__bg'
            : 'blue__bg__input',
        ]"
        suffix="m³"
        @blur="$emit('handleInput', data[index], 'value1')"
        @keyup="(e) => $emit('formatDigitsOnly', e)"
        @focusin="(e) => $emit('formatDigitsOnly', e)"
        persistent-hint
        :hint="dataSingleComponent.value1Comment"
        :data-tabindex="
          addedByUserComponent && (type == 'EG' || type == 'EGO')
            ? index + 0.4
            : variant || variant == ''
            ? index + 0.5
            : index + 0.2
        "
        maxlength="9"
      >
        <template
          v-slot:prepend-inner
          v-if="
            status === 'pruefung' ||
            (status !== 'bearbeitung' &&
              dataSingleComponent.value1Comment &&
              dataSingleComponent.value1Comment !== '')
          "
        >
          <v-menu
            offset-y
            :close-on-content-click="false"
            :value="dataSingle.value1CommentMenu"
            @input="
              (e) =>
                $emit('updateValue', fieldGroup, 'value1CommentMenu', e, index)
            "
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-icon
                    v-show="!printing"
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    v-if="
                      dataSingleComponent.value1Comment === '' ||
                      !dataSingleComponent.value1Comment
                    "
                    @click="
                      toggleMenuComponent(
                        'toggleMenu',
                        dataSingle,
                        'value1CommentMenu'
                      )
                    "
                    :data-tabindex="
                      addedByUserComponent && (type == 'EG' || type == 'EGO')
                        ? index + 0.3
                        : variant || variant == ''
                        ? index + 0.4
                        : index + 0.1
                    "
                  >
                    mdi-note-edit-outline</v-icon
                  >
                  <v-icon
                    v-show="!printing"
                    v-else
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    :color="
                      dataSingle.value1CommentOpened || readonly ? '' : 'white'
                    "
                    @click="
                      toggleMenuComponent(
                        'toggleMenu',
                        dataSingle,
                        'value1CommentMenu'
                      )
                    "
                    :data-tabindex="
                      addedByUserComponent && (type == 'EG' || type == 'EGO')
                        ? index + 0.3
                        : variant || variant == ''
                        ? index + 0.4
                        : index + 0.1
                    "
                    >mdi-note-text
                  </v-icon>
                </template>
                <span>Kommentar</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-textarea
                    type="text"
                    v-model="dataSingleComponent.value1Comment"
                    label="Kommentar"
                    class="blue__bg__input"
                    :disabled="status !== 'pruefung'"
                  >
                  </v-textarea>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-btn
              class="mb-4 bemerkung__btn"
              @click="
                toggleMenuComponent(
                  'toggleMenu',
                  dataSingle,
                  'value1CommentMenu'
                )
              "
              >OK</v-btn
            >
          </v-menu>
        </template>
      </v-text-field>
    </v-col>
    <!-- <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="3"
      :order="usertype === 1 && (variant || variant == '') ? 6 : 5"
      class="pr-lg-4"
    >
      <v-text-field
        :value="
          dataLy[index] && val1LyName
            ? dataLy[index][val1LyName]
            : dataLy[index] && val1Ly
            ? val1Ly
            : ''
        "
        disabled
        suffix="m³"
        :class="
          dataSingleComponent.value1Dif === 'dif'
            ? 'txt-align-end red__txt'
            : 'txt-align-end'
        "
      >
      </v-text-field>
    </v-col> -->
    <v-col
      cols="12"
      :order="usertype === 1 && (variant || variant == '') ? 7 : 6"
      sm="4"
      class="d-lg-none"
    >
      <v-text-field :value="'Jan. - Juni ' + year" readonly></v-text-field>
    </v-col>
    <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="4"
      :order="usertype === 1 && (variant || variant == '') ? 8 : 7"
    >
      <v-text-field
        :value="value2Component"
        @input="(e) => $emit('updateValue', fieldGroup, 'value2', e, index)"
        :class="[
          'txt-align-end',
          readonly
            ? ''
            : dataSingleComponent.value2Comment ||
              dataSingleComponent.value2Dif === 'dif'
            ? 'orange__bg'
            : 'blue__bg__input',
        ]"
        suffix="m³"
        @blur="$emit('handleInput', data[index], 'value2')"
        @keyup="(e) => $emit('formatDigitsOnly', e)"
        @focusin="(e) => $emit('formatDigitsOnly', e)"
        persistent-hint
        :hint="dataSingleComponent.value2Comment"
        :data-tabindex="
          addedByUserComponent && (type == 'EG' || type == 'EGO')
            ? index + 0.6
            : variant || variant == ''
            ? index + 0.7
            : index + 0.4
        "
        maxlength="9"
      >
        <template
          v-slot:prepend-inner
          v-if="
            status === 'pruefung' ||
            (status !== 'bearbeitung' &&
              dataSingleComponent.value2Comment &&
              dataSingleComponent.value2Comment !== '')
          "
        >
          <v-menu
            offset-y
            :close-on-content-click="false"
            :value="dataSingle.value2CommentMenu"
            @input="
              (e) =>
                $emit('updateValue', fieldGroup, 'value2CommentMenu', e, index)
            "
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-icon
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    v-if="
                      dataSingle.value2Comment === '' ||
                      !dataSingle.value2Comment
                    "
                    @click="
                      toggleMenuComponent(
                        'toggleMenu',
                        dataSingle,
                        'value2CommentMenu'
                      )
                    "
                    v-show="!printing"
                    :data-tabindex="
                      addedByUserComponent && (type == 'EG' || type == 'EGO')
                        ? index + 0.5
                        : variant || variant == ''
                        ? index + 0.6
                        : index + 0.3
                    "
                  >
                    mdi-note-edit-outline</v-icon
                  >
                  <v-icon
                    v-else
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    :color="
                      dataSingle.value2CommentOpened || readonly ? '' : 'white'
                    "
                    @click="
                      toggleMenuComponent(
                        'toggleMenu',
                        dataSingle,
                        'value2CommentMenu'
                      )
                    "
                    v-show="!printing"
                    :data-tabindex="
                      addedByUserComponent && (type == 'EG' || type == 'EGO')
                        ? index + 0.5
                        : variant || variant == ''
                        ? index + 0.6
                        : index + 0.3
                    "
                    >mdi-note-text
                  </v-icon>
                </template>
                <span>Kommentar</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-textarea
                    type="text"
                    v-model="dataSingleComponent.value2Comment"
                    label="Kommentar"
                    class="blue__bg__input"
                    :disabled="status !== 'pruefung'"
                  >
                  </v-textarea>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-btn
              class="mb-4 bemerkung__btn"
              @click="
                toggleMenuComponent(
                  'toggleMenu',
                  dataSingle,
                  'value2CommentMenu'
                )
              "
              >OK</v-btn
            >
          </v-menu>
        </template>
      </v-text-field>
    </v-col>
    <!-- <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="5"
      :order="usertype === 1 && (variant || variant == '') ? 9 : 8"
      class="pr-lg-4"
    >
      <v-text-field
        :value="
          dataLy[index] && val2LyName
            ? dataLy[index][val2LyName]
            : dataLy[index] && val2Ly
            ? val2Ly
            : ''
        "
        disabled
        suffix="m³"
        :class="
          dataSingleComponent.value2Dif === 'dif'
            ? 'txt-align-end red__txt'
            : 'txt-align-end'
        "
      >
      </v-text-field>
    </v-col> -->
    <v-col
      cols="12"
      sm="4"
      class="d-lg-none"
      :order="usertype === 1 && (variant || variant == '') ? 10 : 9"
    >
      <v-text-field value="Gesamt" readonly></v-text-field>
    </v-col>
    <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="6"
      :order="usertype === 1 && (variant || variant == '') ? 11 : 10"
    >
      <v-text-field
        :value="dataSingleComponent.sum"
        readonly
        suffix="m³"
        :class="
          (dataSingleComponent.sumChanged ||
            dataSingleComponent.value1Dif === 'dif' ||
            dataSingleComponent.value2Dif === 'dif') &&
          status === 'pruefung'
            ? 'txt-align-end orange__bg'
            : 'txt-align-end'
        "
      >
      </v-text-field>
    </v-col>
    <!-- <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="7"
      :order="usertype === 1 && (variant || variant == '') ? 12 : 11"
    >
      <v-text-field
        :value="
          dataLy[index] && sumLyName
            ? dataLy[index][sumLyName]
            : dataLy[index] && sumLy
            ? sumLy
            : ''
        "
        disabled
        suffix="m³"
        class="txt-align-end"
      >
      </v-text-field>
    </v-col> -->
    <v-col cols="12" class="d-lg-none" order="last">
      <v-divider class="mt-5 mb-5"></v-divider>
    </v-col>
    <v-col
      v-if="
        printing &&
        ((dataSingleComponent.labelComment &&
          dataSingleComponent.labelComment !== '') ||
          (dataSingleComponent.value1Comment &&
            dataSingleComponent.value1Comment !== '') ||
          (dataSingleComponent.value2Comment &&
            dataSingleComponent.value2Comment !== '') ||
          (dataSingleComponent.note && dataSingleComponent.note !== ''))
      "
      cols="12"
      order="last"
      style="padding-top: 0; margin-top: 0"
    >
      <v-row>
        <v-col
          cols="6"
          sm="8"
          lg="3"
          xl="4"
          :order="usertype === 1 && (variant || variant == '') ? 14 : 13"
          order-lg="8"
        >
          <v-text-field
            :value="dataSingleComponent.labelComment"
            hint="Kommentar Bezeichnung"
            persistent-hint
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          :lg="lg"
          :order="usertype === 1 && (variant || variant == '') ? 15 : 14"
          order-lg="9"
        >
          <v-text-field
            :value="dataSingleComponent.value1Comment"
            hint="Kommentar 1. Halbjahr"
            persistent-hint
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          :lg="lg"
          :order="usertype === 1 && (variant || variant == '') ? 16 : 15"
          order-lg="10"
          v-if="
            printing &&
            ((dataSingleComponent.labelComment &&
              dataSingleComponent.labelComment !== '') ||
              (dataSingleComponent.value1Comment &&
                dataSingleComponent.value1Comment !== '') ||
              (dataSingleComponent.value2Comment &&
                dataSingleComponent.value2Comment !== '') ||
              (dataSingleComponent.note && dataSingleComponent.note !== ''))
          "
        >
          <v-text-field
            :value="dataSingleComponent.value2Comment"
            hint="Kommentar 2. Halbjahr"
            persistent-hint
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          :lg="lg"
          :order="usertype === 1 && (variant || variant == '') ? 17 : 16"
          order-lg="11"
        >
          <v-text-field
            hint="Bemerkung"
            persistent-hint
            :value="dataSingleComponent.note"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          class="justify-end d-flex note__del__col"
          :order="usertype === 1 && (variant || variant == '') ? 18 : 17"
          order-lg="12"
          :lg="lg"
        >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: [
    'index',
    'data',
    'dataSingle',
    'labelInputLabel',
    'fieldGroup',
    'idFieldName',
    'readonly',
    'deletable',
    'type',
    'dataLy',
    'year',
    'lg',
    'val1',
    'val2',
    'val1LyName',
    'val2LyName',
    'sumLyName',
    'val1Ly',
    'val2Ly',
    'sumLy',
    'OSSTOCKWERK',
    'OSHORIZONT',
    'rights',
    'variant',
    'variantName',
    'variantLabel',
    'variantHint',
  ],
  emits: [
    'toggleMenu',
    'updateValue',
    'savePreviousId',
    'sendIdUpdateRequest',
    'deleteEgRow',
    'handleInput',
    'formatDigitsOnly',
    'remove',
  ],
  computed: {
    dataSingleComponent() {
      return this.dataSingle;
    },
    value1Component() {
      return this.val1;
    },
    value2Component() {
      return this.val2;
    },
    labelInputComponent() {
      return this.dataSingle['label'];
    },
    labelCommentOpenedComponent() {
      return this.dataSingle.labelCommentOpened;
    },
    addedByUserComponent() {
      return this.dataSingle['addedByUser'];
    },
    labelDifComponent() {
      return this.dataSingle['labelDif'];
    },
    ...mapState({
      printing: (state) => state.printing,
      usertype: (state) => state.usertype,
      status: (state) => state.status,
    }),
  },
  methods: {
    toggleMenuComponent(fn, data, name) {
      this.$emit(fn, data, name);
      this.$forceUpdate();
    },
    handleDeleteClick() {
      if (this.type == 'EG' || this.type == 'EGO') {
        this.$emit(
          'deleteEgRow',
          this.dataSingleComponent[this.idFieldName],
          this.type,
          this.data,
          this.dataSingle,
          this.dataLy
        );
      } else {
        this.$emit(
          'remove',
          this.data,
          this.dataSingle,
          this.dataLy,
          this.fieldGroup
        );
      }
    },
  },
};
</script>
