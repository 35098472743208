<template>
  <div>
    <v-container>
      <h1 class="hl">Formulare von {{ memberNo }}</h1>
      <v-sheet elevation="1" class="pa-12 mt-8">
        <v-container
          fluid
          v-for="category in categories"
          :key="category.id"
          class="ps-0"
        >
          <h4>{{ category.name }}</h4>
          <v-row dense v-for="betrieb in category.betriebe" :key="betrieb.name">
            <v-col
              cols="12"
              sm="4"
              class="d-flex align-center"
              v-if="Object.keys(betrieb.forms).length > 0"
            >
              <span>{{ betrieb.name }} ({{ betrieb.mitglied }})</span>
            </v-col>
            <v-col v-for="form in betrieb.forms" :key="form.id" class="d-flex">
              <v-btn
                @click="handleFormClick(form)"
                :color="
                  form.status === 'pruefung'
                    ? 'primary'
                    : form.status === 'freigegeben'
                    ? 'green'
                    : form.status === 'korrektur'
                    ? 'error'
                    : 'nicht ausgefüllt'
                    ? ''
                    : ''
                "
                class="form__btn"
              >
                {{ form.year }}
              </v-btn>
              <v-btn depressed icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{ ...tooltip }">mdi-calendar-edit </v-icon>
                  </template>
                  <span>zuletzt bearbeitet: {{ form.lastChanged }}</span
                  ><span v-if="form.lastChangedUser">
                    von: {{ form.lastChangedUser }}</span
                  >
                </v-tooltip>
              </v-btn>
              <v-btn depressed icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{ ...tooltip }">
                      mdi-email-fast-outline
                    </v-icon>
                  </template>
                  <span>Mail versandt: {{ form.mailSent }}</span>
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <v-col class="d-flex align-center">
            <v-icon color="#f5f5f5">mdi-circle</v-icon
            ><span> = nicht ausgefüllt</span>
          </v-col>
          <v-col class="d-flex align-center">
            <v-icon color="primary">mdi-circle</v-icon
            ><span> = in Prüfung</span>
          </v-col>
          <v-col class="d-flex align-center">
            <v-icon color="error">mdi-circle</v-icon
            ><span> = Korrektur erforderlich</span>
          </v-col>
          <v-col class="d-flex align-center">
            <v-icon color="green">mdi-circle</v-icon
            ><span> = abgeschlossen</span>
          </v-col>
          <v-col class="d-flex align-center">
            <v-icon>mdi-calendar-edit</v-icon><span> = letzte Bearbeitung</span>
          </v-col>
        </v-row>
      </v-sheet>
      <v-divider class="mt-5 mb-5"></v-divider>
    </v-container>
  </div>
</template>

<script>
import { getUserInfo } from '../../utils/auth';
import axios from '../../utils/api';
import helpers from '../helpers.js';
const { generateTimestamp } = helpers;
export default {
  data: () => ({
    categories: [],
    // address: '',
    // mail: '',
    // year: '',
    memberNo: '',
  }),
  mounted() {
    this.beforeLoad();
    this.$forceUpdate();
  },
  methods: {
    async beforeLoad() {
      // const userInfo = getUserInfo();
      // const username = userInfo.ANSPRECH;
      // const address = userInfo.ANSPRECH_ANREDE;
      // this.address = address;
      // this.$store.dispatch('setUser', username);
      this.memberNo = this.$route.params.userno;
      // this.mail = userInfo.MAIL;
      // this.year = 2023;

      try {
        let res = await axios({
          url: `/API-USER/userforms?mimitgl=${this.memberNo}`,
          method: 'GET',
        });
        let data = res.data;
        console.log('res.data ', data);
        this.buildDashboard(data);
      } catch (err) {
        console.error('Dashboard Request Error:', err);
      }
    },
    generateTimestamp,
    buildDashboard(data) {
      data.forEach((el) => {
        const formattedDatetime = new Date(el.lastchange).toLocaleString(
          'de-DE'
        );
        const mailTimestamp = this.generateTimestamp();
        const mailSent = new Date(mailTimestamp).toLocaleString('de-DE');
        const newForm = {
          year: el.BEFRAG,
          name: '',
          status: el.status,
          id: '',
          mitglied: decodeURI(el.MIMITGL),
          firma: el.FIRMA1,
          betrieb: el.BETRIEB1,
          lastChanged: formattedDatetime,
          lastChangedUser: el.lastchange_user,
          mailSent: mailSent,
        };
        let firmaCommaBetrieb = '';
        if (el.FIRMA1 && el.BETRIEB1) {
          firmaCommaBetrieb = el.FIRMA1 + ', ' + el.BETRIEB1;
        } else if (el.FIRMA1 && !el.BETRIEB1) {
          firmaCommaBetrieb = el.FIRMA1;
        } else if (!el.FIRMA1 && el.BETRIEB1) {
          firmaCommaBetrieb = el.BETRIEB1;
        }
        let mitgliedsNr = decodeURI(el.MIMITGL);
        if (el.FRAGEB_NR === '1') {
          const category = 'Öffentliche Wasserversorgung';
          newForm.name = 'form_oeff';
          if (!this.categories.some((e) => e.name === category)) {
            this.categories.push({ name: category, betriebe: {} });
          }
          this.categories.map((cat) => {
            if (cat.name === category) {
              if (!cat.betriebe[firmaCommaBetrieb]) {
                cat.betriebe[firmaCommaBetrieb] = {
                  name: firmaCommaBetrieb,
                  mitglied: mitgliedsNr,
                };
              }
              if (!cat.betriebe[firmaCommaBetrieb].forms) {
                cat.betriebe[firmaCommaBetrieb]['forms'] = {};
              }
              newForm.id =
                'form_oeff' +
                (Object.keys(cat.betriebe[firmaCommaBetrieb].forms).length + 1);
              cat.betriebe[firmaCommaBetrieb].forms[newForm.id] = newForm;
            }
          });
        } else if (el.FRAGEB_NR === '2') {
          const category = 'Gewerbliche Betriebe';
          newForm.name = 'form_gew';
          if (!this.categories.some((e) => e.name === category)) {
            this.categories.push({ name: category, betriebe: {} });
          }
          this.categories.map((cat) => {
            if (cat.name === category) {
              if (!cat.betriebe[firmaCommaBetrieb]) {
                cat.betriebe[firmaCommaBetrieb] = {
                  name: firmaCommaBetrieb,
                  mitglied: mitgliedsNr,
                };
              }
              if (!cat.betriebe[firmaCommaBetrieb].forms) {
                cat.betriebe[firmaCommaBetrieb]['forms'] = {};
              }
              newForm.id =
                'form_gew' +
                (Object.keys(cat.betriebe[firmaCommaBetrieb].forms).length + 1);
              cat.betriebe[firmaCommaBetrieb].forms[newForm.id] = newForm;
            }
          });
        } else if (el.FRAGEB_NR === '3') {
          const category = 'Kies- und Betonwerke';
          newForm.name = 'form_kibe';
          if (!this.categories.some((e) => e.name === category)) {
            this.categories.push({ name: category, betriebe: {} });
          }
          this.categories.map((cat) => {
            if (cat.name === category) {
              if (!cat.betriebe[firmaCommaBetrieb]) {
                cat.betriebe[firmaCommaBetrieb] = {
                  name: firmaCommaBetrieb,
                  mitglied: mitgliedsNr,
                };
              }
              if (!cat.betriebe[firmaCommaBetrieb].forms) {
                cat.betriebe[firmaCommaBetrieb]['forms'] = {};
              }
              newForm.id =
                'form_kibe' +
                (Object.keys(cat.betriebe[firmaCommaBetrieb].forms).length + 1);
              cat.betriebe[firmaCommaBetrieb].forms[newForm.id] = newForm;
            }
          });
        } else if (el.FRAGEB_NR === '4') {
          const category = 'Sport-/Freizeitanlagen';
          newForm.name = 'form_spofr';
          if (!this.categories.some((e) => e.name === category)) {
            this.categories.push({ name: category, betriebe: {} });
          }
          this.categories.map((cat) => {
            if (cat.name === category) {
              if (!cat.betriebe[firmaCommaBetrieb]) {
                cat.betriebe[firmaCommaBetrieb] = {
                  name: firmaCommaBetrieb,
                  mitglied: mitgliedsNr,
                };
              }
              if (!cat.betriebe[firmaCommaBetrieb].forms) {
                cat.betriebe[firmaCommaBetrieb]['forms'] = {};
              }
              newForm.id =
                'form_spofr' +
                (Object.keys(cat.betriebe[firmaCommaBetrieb].forms).length + 1);
              cat.betriebe[firmaCommaBetrieb].forms[newForm.id] = newForm;
            }
          });
        } else if (el.FRAGEB_NR === '5') {
          const category = 'Landwirtschaft';
          newForm.name = 'form_land';
          if (!this.categories.some((e) => e.name === category)) {
            this.categories.push({ name: category, betriebe: {} });
          }
          this.categories.map((cat) => {
            if (cat.name === category) {
              if (!cat.betriebe[firmaCommaBetrieb]) {
                cat.betriebe[firmaCommaBetrieb] = {
                  name: firmaCommaBetrieb,
                  mitglied: mitgliedsNr,
                };
              }
              if (!cat.betriebe[firmaCommaBetrieb].forms) {
                cat.betriebe[firmaCommaBetrieb]['forms'] = {};
              }
              newForm.id =
                'form_land' +
                (Object.keys(cat.betriebe[firmaCommaBetrieb].forms).length + 1);
              cat.betriebe[firmaCommaBetrieb].forms[newForm.id] = newForm;
            }
          });
        }
      });
    },
    handleFormClick(form) {
      if (form.name === 'form_spofr' && form.mitglied.startsWith('5')) {
        this.$router.push({
          name: form.name + '_land',
          params: { username: form.mitglied, year: form.year },
        });
      } else {
        this.$router.push({
          name: form.name,
          params: { username: form.mitglied, year: form.year },
        });
      }
    },
  },
};
</script>

<style></style>
