<template>
  <v-sheet class="mx-auto">
    <v-form
      @submit.prevent="submit()"
      ref="persDataForm"
      lazy-validation
      v-model="valid"
    >
      <v-container>
        <v-sheet elevation="4" class="custom__sheet mb-2">
          <v-row>
            <v-col cols="12">
              <v-alert text type="info" v-if="required"
                >Bitte bestätigen oder korrigieren Sie Ihre Adressdaten bevor
                Sie fortfahren</v-alert
              >
              <v-alert text type="info" v-else
                >Ändern Sie hier Ihre Adressdaten</v-alert
              >
            </v-col>
            <v-col cols="12">
              <div>Ihre Erftverbandsnummer: {{ formData.mimitgl }}</div>
            </v-col>
            <v-col cols="12" sm="6">
              <div>Wasserentnehmer</div>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.companyPart1"
                label="Firma1"
                :rules="[rules.max30]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.companyPart2"
                label="Firma2"
                :rules="[rules.max30]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.companyPart3"
                label="Firma3"
                :rules="[rules.max30]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.street"
                label="Straße und Hausnummer"
                :rules="[rules.max30]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="formData.zip"
                label="Postleitzahl"
                :rules="[rules.zip]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" offset-sm="3">
              <v-text-field
                v-model="formData.city"
                label="Ort"
                :rules="[rules.max23]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet elevation="4" class="custom__sheet mb-2">
          <v-row>
            <v-col cols="12">
              <div>Betriebsstelle</div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.location1"
                label="Betrieb1"
                :rules="[rules.max30]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.location2"
                label="Betrieb2"
                :rules="[rules.max30]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet elevation="4" class="custom__sheet mb-2">
          <v-row>
            <v-col cols="12">
              <div>Ansprechpartner/in für diese Datenerfassung</div>
            </v-col>
            <v-col cols="6" sm="4">
              <v-text-field
                v-model="formData.title"
                label="Anrede"
                :rules="[rules.max25]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.firstName"
                label="Vorname"
                :rules="[rules.max25]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.lastName"
                label="Nachname"
                :rules="[rules.required, rules.max25]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.email"
                label="E-Mail"
                type="email"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.tel"
                label="Telefon"
                :rules="[rules.max30]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-sheet>
        <v-row>
          <v-col v-if="errMsg">
            <v-alert text type="error">{{ errMsg }}</v-alert>
          </v-col>
          <v-col class="d-flex justify-center" cols="12">
            <v-btn class="mb-4 mt-4" type="submit" color="primary">{{
              dataChanged ? 'Daten senden' : 'Daten bestätigen'
            }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-sheet>
</template>

<script>
import axios from '../../utils/api';
import { mapState } from 'vuex';

export default {
  data: () => ({
    formData: {
      title: '',
      firstName: '',
      lastName: '',
      location1: '',
      location2: '',
      companyPart1: '',
      companyPart2: '',
      companyPart3: '',
      email: '',
      mimitgl: '',
      tel: '',
      street: '',
      zip: '',
      city: '',
    },
    required: false,
    dataChanged: false,
    rules: {
      required: (value) => !!value || 'Pflichtfeld.',
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Ungültige E-Mail-Adresse';
      },
      max23: (value) => {
        if (value) {
          return value.length <= 23 || 'Maximal 23 Zeichen';
        } else {
          return true;
        }
      },
      max25: (value) => {
        if (value) {
          return value.length <= 25 || 'Maximal 25 Zeichen';
        } else {
          return true;
        }
      },
      max30: (value) => {
        if (value) {
          return value.length <= 30 || 'Maximal 30 Zeichen';
        } else {
          return true;
        }
      },
      zip: (value) => {
        if (value) {
          const pattern = /^[0-9]{5}$/;
          return pattern.test(value) || 'Ungültige Postleitzahl';
        } else {
          return true;
        }
      },
    },
    valid: true,
    errMsg: false,
  }),
  props: ['data'],
  computed: {
    ...mapState({
      firstLogin: (state) => state.firstLogin,
    }),
  },
  methods: {
    async submit() {
      this.errMsg = false;
      if (this.$refs.persDataForm.validate()) {
        console.log('valid form: ', this.$refs.persDataForm);
        const jsonData = JSON.stringify({
          ANSPRECH_ANREDE: this.formData.title,
          ANSPRECH_VN: this.formData.firstName,
          ANSPRECH: this.formData.lastName,
          BETRIEB1: this.formData.location1,
          BETRIEB2: this.formData.location2,
          FIRMA1: this.formData.companyPart1,
          FIRMA2: this.formData.companyPart2,
          FIRMA3: this.formData.companyPart3,
          MAIL: this.formData.email,
          MIMITGL: this.formData.mimitgl,
          TELEFON: this.formData.tel,
          STRASSE1: this.formData.street,
          PLZ1: this.formData.zip,
          ORT1: this.formData.city,
        });
        const change = this.compareData();
        try {
          const res = await axios.post(
            `/API-USER/addressUpdate?mimitgl=${this.formData.mimitgl}&change=${change}`,
            jsonData
          );
          this.$store.commit('setAddressDialog', {
            show: false,
          });
          this.$store.dispatch('setAlertShow', true);
          if (change === 1) {
            this.$store.dispatch(
              'setAlertMsg',
              'Ihre Daten wurden an den Erftverband übermittelt. Es kann einige Zeit dauern, bis sie aktualisiert werden.'
            );
          } else {
            this.$store.dispatch(
              'setAlertMsg',
              'Ihre Daten wurden an den Erftverband übermittelt.'
            );
          }
          this.$store.dispatch('setFirstLogin', false);
        } catch (err) {
          this.errMsg =
            'Ihre Daten konnten nicht an den Erftverband übermittelt werden. Bitte versuchen Sie es erneut.';
          console.log('error: ', err);
        }
      } else {
        console.log('Address form invalid');
      }
    },
    compareData() {
      if (
        this.formData.title !== this.data.title ||
        this.formData.firstName !== this.data.firstName ||
        this.formData.lastName !== this.data.lastName ||
        this.formData.location1 !== this.data.location1 ||
        this.formData.location2 !== this.data.location2 ||
        this.formData.companyPart1 !== this.data.companyPart1 ||
        this.formData.companyPart2 !== this.data.companyPart2 ||
        this.formData.companyPart3 !== this.data.companyPart3 ||
        this.formData.email !== this.data.email ||
        this.formData.mimitgl !== this.data.mimitgl ||
        this.formData.tel !== this.data.tel ||
        this.formData.street !== this.data.street ||
        this.formData.zip !== this.data.zip ||
        this.formData.city !== this.data.city
      ) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  watch: {
    'formData.title': {
      handler(newVal) {
        console.log('new title: ', newVal);
        newVal !== this.data.title ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.firstName': {
      handler(newVal) {
        console.log('new firstName: ', newVal);
        newVal !== this.data.firstName ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.lastName': {
      handler(newVal) {
        console.log('new lastName: ', newVal);
        newVal !== this.data.lastName ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.location1': {
      handler(newVal) {
        console.log('new location1: ', newVal);
        newVal !== this.data.location1 ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.location2': {
      handler(newVal) {
        console.log('new location2: ', newVal);
        newVal !== this.data.location2 ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.companyPart1': {
      handler(newVal) {
        console.log('new companyPart1: ', newVal);
        newVal !== this.data.companyPart1 ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.companyPart2': {
      handler(newVal) {
        console.log('new companyPart2: ', newVal);
        newVal !== this.data.companyPart2 ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.companyPart3': {
      handler(newVal) {
        console.log('new companyPart3: ', newVal);
        newVal !== this.data.companyPart3 ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.email': {
      handler(newVal) {
        console.log('new email: ', newVal);
        newVal !== this.data.email ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.mimitgl': {
      handler(newVal) {
        console.log('new mimitgl: ', newVal);
        newVal !== this.data.mimitgl ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.tel': {
      handler(newVal) {
        console.log('new tel: ', newVal);
        newVal !== this.data.tel ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.street': {
      handler(newVal) {
        console.log('new street: ', newVal);
        newVal !== this.data.street ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.zip': {
      handler(newVal) {
        console.log('new zip: ', newVal);
        newVal !== this.data.zip ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
    'formData.city': {
      handler(newVal) {
        console.log('new city: ', newVal);
        newVal !== this.data.city ? (this.dataChanged = true) : null;
      },
      deep: true,
    },
  },
  created() {
    this.formData.title = this.data.title;
    this.formData.firstName = this.data.firstName;
    this.formData.lastName = this.data.lastName;
    this.formData.location1 = this.data.location1;
    this.formData.location2 = this.data.location2;
    this.formData.companyPart1 = this.data.companyPart1;
    this.formData.companyPart2 = this.data.companyPart2;
    this.formData.companyPart3 = this.data.companyPart3;
    this.formData.email = this.data.email;
    this.formData.mimitgl = this.data.mimitgl;
    this.formData.tel = this.data.tel;
    this.formData.street = this.data.street;
    this.formData.zip = this.data.zip;
    this.formData.city = this.data.city;
    this.required = this.firstLogin;
  },
};
</script>

<style lang="scss" scoped>
.custom__sheet {
  padding: 1rem;
  border: 1px solid #007ba5;
}
</style>
