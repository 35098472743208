<template>
    <v-container>
        <v-row>
            <v-col cols="10" offset="1" lg="8" offset-lg="2">
        <h1 class="mb-4 erft__blue">Impressum</h1>
        <div>Angaben gemäß §5 TMG:
<br><br>
<strong class="erft__blue">Herausgeber</strong><br>
<address class="mt-2 mb-2">
ERFTVERBAND<br>
Körperschaft des öffentlichen Rechts
<br>
Am Erftverband 6
<br>
Postfach 13 20
<br>
50126 Bergheim
<br>
50103 Bergheim
</address>
<a href="tel:02271880">02271 88-0</a><br>
Fax 02271 88-1210<br>
<a href="https://www.erftverband.de">www.erftverband.de</a>
<br><br>
Vorstand<br>
Dr. Bernd Bucher
<br><br>
Ständiger Vertreter des Vorstands<br>
Prof. Dipl.-Ing. Dipl.-Wirt.-Ing. Heinrich Schäfer
<br><br>
Kontakt:<br>
<address class="mt-2 mb-2">
<a href="tel:02271880">02271 88 0</a><br>
Fax 02271 88 1210<br>
E-Mail: <a href="mailto:info@erftverband.de" target="_blank">info[a]erftverband.de</a><br>
DE-Mail: <a href="mailto:info@erftverband.de-mail.de" target="_blank">info[a]erftverband.de-mail.de</a><br>
</address>
<br>
Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
<address class="mt-2 mb-2">
Vorstand Dr. Bernd Bucher<br>
Am Erftverband 6<br>
50126 Bergheim
</address>
</div>
</v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>