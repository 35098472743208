<template>
  <div class="d-flex justify-end align-center">
    <v-btn @click="showForm()"> Erste Eingabe anzeigen </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  props: { formName: String, username: String, year: String },
  methods: {
    showForm() {
      const routeData = this.$router.resolve({
        name: this.formName,
        params: { username: this.username, year: this.year, mode: 'first' },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="css" scoped></style>
