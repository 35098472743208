<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
      :class="route === 'login' ? 'd-print-none header__login' : 'd-print-none'"
    >
      <div class="d-flex align-center">
        <button @click="toDashboard(usertype)" alt="Zum Dashboard">
          <v-img
            alt="Erftverband Logo"
            class="shrink mr-2"
            contain
            src="./assets/Erftverband_logo.png"
            transition="scale-transition"
            width="155"
            height="47"
          />
        </button>
      </div>
      <div
        class="d-flex align-center company"
        v-if="route.includes('form') && isLoggedIn() && !isMobile"
      >
        {{ firmaCommaBetrieb ? firmaCommaBetrieb : '' }}&nbsp;{{
          mitglied ? '(' + mitglied + ')' : ''
        }}
      </div>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            light
            @click="printPage()"
            class="d-none d-md-block d-print-none"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> mdi-printer-outline </v-icon>
          </v-btn>
        </template>
        <span
          >Bitte nutzen Sie die Skalierung der Druckansicht um die gewünschte
          Darstellung zu erreichen (z.B. A3 - Querformat für Formulare)</span
        >
      </v-tooltip>
      <v-btn
        icon
        light
        @click.stop="toggleNavDrawer()"
        :disabled="!isLoggedIn()"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
      <v-navigation-drawer
        v-if="isLoggedIn()"
        v-model="$store.state.showNavDrawer"
        absolute
        temporary
        right
        :width="
          $vuetify.breakpoint.xs
            ? '75%'
            : $vuetify.breakpoint.sm
            ? '50%'
            : '30%'
        "
        app
        light
        id="nav-drawer"
        @input="toggleNavDrawer()"
      >
        <v-list>
          <v-list-item @click="printPage()" class="d-md-none">
            <v-list-item-icon>
              <v-icon>mdi-printer-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Drucken </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="toDashboard(usertype)">
            <v-list-item-icon>
              <v-icon>mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Dashboard </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="toUserVerwaltung()"
            v-if="usertype === 1 && benutzerverwaltung"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Benutzerverwaltung </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="toUserList()" v-if="usertype === 1">
            <v-list-item-icon>
              <v-icon>mdi-table-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Entnehmersuche </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="toPasswordChange()" v-if="usertype != 1">
            <v-list-item-icon>
              <v-icon>mdi-lock-reset</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Passwort ändern </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="primary accent-4"
      ></v-progress-linear>
      <ErftAlert
        v-if="alertShow"
        :show="alertShow"
        :msg="alertMsg"
        :type="alertType"
      />
    </v-app-bar>

    <v-main>
      <v-container v-show="printing">
        <v-row>
          <v-col
            v-if="$route.name.includes('form')"
            style="margin-left: 0.75rem"
          >
            <CompanyName />
          </v-col>
          <v-col
            id="exportDate"
            class="d-flex justify-end"
            :style="
              $route.name.includes('form') ? 'margin-right: 0.75rem;' : ''
            "
          ></v-col>
        </v-row>
      </v-container>
      <v-alert text type="info" v-if="pruefungAlert"
        >Die Eingabe wurde durch Sie bereits abgeschlossen und liegt dem
        Erftverband bereits zur Prüfung vor. Sollten Sie den Wunsch haben, die
        Daten zu korrigieren, so wenden Sie sich bitte an den Erftverband unter
        der Rufnummer <a href="tel:02271881215">02271 / 881215</a> oder über die
        E-Mailadresse
        <a href="mailto:statistik@erftverband.de">statistik@erftverband.de</a>.
        Der Bogen wird für Sie dann wieder freigeschaltet.</v-alert
      >
      <v-alert text type="info" v-if="approvedAlert"
        >Die Eingabe wurde durch Sie
        {{ dateOfFormSubmission ? 'am ' + dateOfFormSubmission : '' }} bereits
        abgeschlossen. Der Bogen liegt derzeit beim Erftverband zur Prüfung vor.
        Eine Korrektur ist dann nur noch nach der Freigabe durch den Erftverband
        möglich. Falls Sie eine nachträgliche Freigabe des Bogens benötigen, so
        setzen Sie sich bitte mit uns in Verbindung: E-Mail
        <a href="mailto:statistik@erftverband.de">statistik@erftverband.de</a>
        oder Telefon: <a href="tel:02271881215">02271 / 881215</a>. Wir helfen
        Ihnen gerne weiter.
      </v-alert>
      <v-dialog
        max-width="600"
        v-model="addressDialog.show"
        :persistent="addressDialog.persistent"
      >
        <template>
          <AddressForm :data="addressData" :required="addressDialog.required" />
        </template>
      </v-dialog>
      <router-view></router-view>
    </v-main>
    <v-footer
      padless
      :class="
        route === 'login'
          ? 'pt-4 pb-4 ps-4 pe-4 d-flex justify-end d-print-none footer__login'
          : 'pt-4 pb-4 ps-4 pe-4 d-flex justify-end d-print-none'
      "
    >
      <a @click="$router.push({ name: 'contact_erft' })" class="me-6"
        >Kontakt</a
      >
      <a @click="$router.push({ name: 'imprint_erft' })" class="me-6"
        >Impressum</a
      >
      <a @click="$router.push({ name: 'privacy_erft' })">Datenschutz</a>
    </v-footer>
  </v-app>
</template>

<script>
import { logoutUser, isLoggedIn, getUserInfo } from '../utils/auth';
import helpers from './helpers.js';
const { toDashboard, toUserVerwaltung, toUserList, toPasswordChange } = helpers;
import { mapState } from 'vuex';
import CompanyName from './components/CompanyName.vue';
import ErftAlert from './components/ErftAlert.vue';
import AddressForm from './components/AddressForm.vue';

export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'Entnehmerportal Erftverband',
      meta: [
        { charset: 'utf-8' },
        { equiv: 'Content-Type', content: 'text/html' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'robots', content: 'noindex, nofollow' },
      ],
    };
  },
  components: {
    CompanyName,
    ErftAlert,
    AddressForm,
  },
  data: () => ({
    benutzerverwaltung: null,
  }),
  computed: {
    isMobile() {
      let res = null;
      if (this.$vuetify.breakpoint.xs) {
        res = true;
      } else {
        res = false;
      }
      return res;
    },
    ...mapState({
      loading: (state) => state.loading,
      username: (state) => state.username,
      usertype: (state) => state.usertype,
      printing: (state) => state.printing,
      printWindowWidth: (state) => state.printWindowWidth,
      firmaCommaBetrieb: (state) => state.firmaCommaBetrieb,
      mitglied: (state) => state.mitglied,
      pruefungAlert: (state) => state.pruefungAlert,
      approvedAlert: (state) => state.approvedAlert,
      dateOfFormSubmission: (state) => state.dateOfFormSubmission,
      alertShow: (state) => state.alertShow,
      alertMsg: (state) => state.alertMsg,
      alertType: (state) => state.alertType,
      addressDialog: (state) => state.addressDialog,
      addressData: (state) => state.addressData,
      printLoading: (state) => state.printLoading,
    }),
    route() {
      let route = '';
      if (this.$route.path.includes('dashboard')) {
        route = 'dashboard';
      } else if (this.$route.path.includes('entnehmer')) {
        route = 'entnehmer';
      } else if (this.$route.path.includes('form')) {
        route = 'form';
      } else if (this.$route.path.includes('userlist')) {
        route = 'userlist';
      } else if (this.$route.path.includes('login')) {
        route = 'login';
      }
      return route;
    },
  },
  async beforeUpdate() {
    await this.beforeLoad();
  },

  methods: {
    setHeaderForPrinting() {
      const fixedRow = document.getElementById('fixed-row');
      const timespan = document.getElementById('timespan');
      fixedRow ? fixedRow.classList.remove('sticky') : null;
      timespan ? timespan.classList.remove('d-none') : null;
    },
    resetHeaderAfterPrinting() {
      const fixedContainer = document.getElementById('fixed-container');
      const fixedRow = document.getElementById('fixed-row');
      const timespan = document.getElementById('timespan');
      if (window.scrollY > fixedContainer.offsetTop) {
        fixedRow.classList.add('sticky');
        timespan.classList.add('d-none');
      }
    },
    getCurrentTime() {
      const date = new Date();
      console.log('date: ', date);
      const localDate = date.toLocaleString('de-DE');
      return localDate;
    },
    openPrintDialog() {
      setTimeout(() => {
        window.print();
      }, 0);
    },
    afterPrintDialog(mainEl, formEl, expansionPanelsStates) {
      setTimeout(() => {
        afterPrintDialogFn(mainEl, formEl, expansionPanelsStates);
      }, 0);
      const afterPrintDialogFn = (mainEl, formEl, expansionPanelsStates) => {
        if (formEl) {
          for (let i = 0; i < expansionPanelsStates.length; i++) {
            if ((expansionPanelsStates[i] = false)) {
              console.log('expansionPanels[i]: ', expansionPanels[i]);
              expansionPanels[i]
                .querySelector('button.v-expansion-panel-header')
                .click();
            }
          }
        }
        mainEl.classList.remove('print__container__width');
        const printStyleEl = document.head.querySelector('#printStyle');
        printStyleEl.remove();
        this.$store.dispatch('setPrintingFalse');
        if (this.$route.name.includes('form')) {
          this.resetHeaderAfterPrinting();
        }
      };
    },
    expandExpansionPanels(formEl, expansionPanelsStates) {
      if (formEl) {
        const expansionPanels = formEl.querySelectorAll('.v-expansion-panel');
        for (let i = 0; i < expansionPanels.length; i++) {
          if (
            expansionPanels[i]
              .querySelector('button.v-expansion-panel-header')
              .classList.contains('v-expansion-panel-header--active')
          ) {
            expansionPanelsStates[i] = true;
          } else if (
            !expansionPanels[i]
              .querySelector('button.v-expansion-panel-header')
              .classList.contains('v-expansion-panel-header--active')
          ) {
            expansionPanelsStates[i] = false;
            expansionPanels[i]
              .querySelector('button.v-expansion-panel-header')
              .click();
          }
        }
      }
    },
    async printPage() {
      const exportDateEl = document.querySelector('#exportDate');
      exportDateEl.innerText = 'Exportiert am ' + this.getCurrentTime();
      await this.$store.dispatch('setPrintingTrue');
      const mainEl = document.querySelector('main');
      mainEl.classList.add('print__container__width');
      const formEl = mainEl.querySelector('form');
      const expansionPanelsStates = [];
      let styles = '';
      styles += `.v-input {font-weight: bold!important;}`;
      if (!this.$route.name.includes('form')) {
        styles += `html {font-size: 14px;}`;
      }
      const styleEl = document.createElement('style');
      styleEl.setAttribute('id', 'printStyle');
      styleEl.appendChild(document.createTextNode(styles));
      document.head.appendChild(styleEl);
      if (this.$route.name.includes('form')) {
        await this.waitForPrintLoading();
        await this.setHeaderForPrinting();
      }
      await this.expandExpansionPanels(formEl, expansionPanelsStates);
      this.openPrintDialog();
      this.afterPrintDialog(mainEl, formEl, expansionPanelsStates);
    },
    async waitForPrintLoading() {
      return new Promise((resolve) => {
        const checkLoading = () => {
          if (this.printLoading) {
            resolve();
          } else {
            requestAnimationFrame(checkLoading); // Check again on the next frame
          }
        };
        checkLoading();
      });
    },
    async beforeLoad() {
      const userInfo = getUserInfo();
      if (userInfo) {
        if (userInfo.benutzerverwaltung === '1') {
          this.benutzerverwaltung = true;
        } else {
          this.benutzerverwaltung = false;
        }
      }
    },
    async logout() {
      await logoutUser();
      await this.$store.dispatch('setResetState');
      alert('ausgeloggt');
      this.showNavDrawer = false;
      this.$router.push('/login');
    },
    isLoggedIn() {
      return isLoggedIn();
    },
    toDashboard,
    toUserVerwaltung,
    toUserList,
    toPasswordChange,
    toggleNavDrawer() {
      const navDrawer = document.querySelector('#nav-drawer');
      if (navDrawer.classList.contains('v-navigation-drawer--open')) {
        this.$store.dispatch('closeNavDrawer');
      } else {
        this.$store.dispatch('openNavDrawer');
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: 'frutiger-light-regular';
  src: url('./assets/Frutiger LT 45 Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --container-width: 100%;
  --blue: #007ba5;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
  hyphens: auto;
}

.v-application {
  font-family: 'frutiger-light-regular', sans-serif;
}

.v-app-bar.v-app-bar--fixed {
  z-index: 10;
}

.container:not(.container--fluid) {
  max-width: var(--container-width) !important;
}

.hl {
  font-size: 1.17rem;
}

.erft__blue {
  color: var(--blue);
}

.header__login .v-toolbar__content,
.footer__login {
  width: min(1120px, 100%) !important;
  margin: 0 auto;
  background-color: transparent !important;
}

.bemerkung__btn {
  width: fit-content;
  margin-left: calc(50% - 64px / 2);
}

fieldset {
  border: none;
}

.form__btn {
  width: fit-content;
}

.oberfl .v-expansion-panel-content__wrap {
  padding: 0;
}

.expansion__hl {
  margin-bottom: 0.5rem;
  font-size: 1.17rem;
}

button.v-expansion-panel-header {
  padding-left: 12px;
}

#eigen-fremd-summe .v-messages__message {
  color: red;
}

.print__container__width {
  --container-width: 1920px;
}

.input__text__right input {
  text-align: right;
}

/* vuetify override */
.v-menu__content.menuable__content__active {
  background-color: white;
}

.mail__tooltip ul {
  padding-left: 1rem;
}

@media (max-width: 340px) {
  .v-btn__content {
    font-size: 10px;
  }
}

@media (min-width: 960px) {
  :root {
    --container-width: 900px;
  }

  html {
    font-size: 12px;
  }
}

@media (min-width: 1264px) {
  :root {
    --container-width: 1244px;
  }

  html {
    font-size: 16px;
  }

  .note__del__col {
    flex-grow: 0 !important;
  }
}

@media (min-width: 1904px) {
  :root {
    --container-width: 1785px;
  }
}

.v-list-item__title {
  cursor: pointer;
}

.v-text-field__suffix {
  color: rgba(0, 0, 0, 0.38) !important;
  font-size: 0.8rem;
}

.txt-align-end input {
  text-align: end !important;
}

.yellow__bg input {
  background-color: #ffeaa3;
}

.blue__bg__textarea textarea {
  background-color: rgba(148, 199, 220, 0.7);
  margin-top: 0.5rem;
  padding: 0.25rem !important;
}

.blue__bg__input .v-input__slot {
  background-color: rgba(148, 199, 220, 0.7);
}

.white__bg input {
  background-color: white;
}

.orange__bg .v-input__slot {
  background-color: #f49600;
}

.v-card.blue__bg {
  background-color: rgba(148, 199, 220, 0.7) !important;
}

.v-card.blue__border {
  border: 3px solid rgba(148, 199, 220, 0.7) !important;
}

.red__border input {
  border: 3px solid #cf4607 !important;
}

.user__logo {
  max-width: 10rem !important;
  height: auto;
}

.v-overlay--active {
  height: 100vh;
}

.v-input,
.v-input .v-label {
  font-size: 1rem;
}

.red__txt input {
  color: #cf4607 !important;
}

.v-text-field:not(.v-input--is-disabled) .v-text-field__suffix {
  color: black !important;
}

.company {
  color: black;
  font-weight: 700;
}

@media (max-width: 599.98px) {
  .opacity-xs-0 {
    opacity: 0;
  }
}
</style>
