<template>
  <div>
    {{ firmaCommaBetrieb ? firmaCommaBetrieb : '' }}&nbsp;{{ mitglied ? '(' + mitglied + ')' : '' }}
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      firmaCommaBetrieb: (state) => state.firmaCommaBetrieb,
      mitglied: (state) => state.mitglied,
    }),
  },
};
</script>
