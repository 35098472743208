<template>
  <div>
    <div class="banner__wrapper" :style="`min-height: ${contentHeight};`">
      <video autoplay loop id="login-vid" class="login__vid" muted>
        <source src="../assets/erft.mp4" type="video/mp4" role="presentation" />
      </video>
    </div>
    <div
      :class="
        !this.$vuetify.breakpoint.mdAndUp
          ? 'd-flex flex-column align-center content__wrapper content__wrapper--centered'
          : 'd-flex flex-column align-center content__wrapper'
      "
      :style="!loaded ? 'opacity: 0' : null"
      ref="contentWrapper"
    >
      <div>
        <h1>ONLINEPORTAL</h1>
        <h2>zur Erfassung der Wassernutzung 2023/2024</h2>
      </div>
      <v-sheet
        id="login-sheet"
        class="pa-8 pa-sm-12 mb-12 login__sheet"
        height=""
        :width="!this.$vuetify.breakpoint.mdAndUp ? '100%' : 800"
        elevation="1"
        ref="loginSheet"
      >
        <v-form @submit.prevent="onLogin">
          <v-row align="center">
            <v-col cols="12" sm="5" md="4">
              <v-icon class="me-2" color="primary"> mdi-account-circle </v-icon>
              <div class="d-inline-flex flex-column">
                <div>Benutzername:</div>
                <div>(Ihre E-Mail-Adresse)</div>
              </div>
            </v-col>
            <v-col>
              <v-text-field v-model="username"> </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" sm="5" md="4">
              <v-icon class="me-2" color="primary"> mdi-lock </v-icon>
              <span>Passwort: </span>
            </v-col>
            <v-col>
              <v-text-field
                v-model="password"
                :type="showPw ? 'text' : 'password'"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col>
              <v-checkbox
                v-model="showPw"
                label="Passwort anzeigen"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-btn type="submit" color="primary" rounded>Login</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <ForgotPassword />
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { loginUser, getUserInfo } from '../../utils/auth';
import ForgotPassword from './ForgotPassword.vue';

export default {
  components: {
    ForgotPassword,
  },
  data: () => ({
    username: '',
    password: '',
    showPw: false,
    loaded: false,
    contentHeight: 0,
  }),
  async mounted() {
    const videoElem = document.querySelector('#login-vid');
    let startPlayPromise = videoElem.play();

    if (startPlayPromise !== undefined) {
      startPlayPromise
        .then(() => {
          // Start whatever you need to do only after playback
          // has begun.
        })
        .catch((error) => {
          if (error.name === 'NotAllowedError') {
            let playAttempt = setInterval(() => {
              videoElem
                .play()
                .then(() => {
                  clearInterval(playAttempt);
                })
                .catch((error) => {
                  console.log(
                    'Unable to play the video, User has not interacted yet.'
                  );
                });
            }, 3000);
          }
        });
    }
    this.contentHeight = await this.getContentHeight();
    this.loaded = true;
  },
  computed: {
    loginSheetWidth() {
      return this.$refs.loginSheet.$el.offsetWidth;
    },
  },
  methods: {
    async onLogin() {
      try {
        await loginUser(this.username, this.password);
        const userInfo = getUserInfo();
        console.log(userInfo);
        const username = userInfo.ANSPRECH;
        this.$store.dispatch('setUsermail', userInfo.MAIL);
        this.$store.dispatch('setMitglied', userInfo.MIMITGL);
        userInfo.erstes_login === '0'
          ? this.$store.dispatch('setFirstLogin', true)
          : this.$store.dispatch('setFirstLogin', false);
        if (userInfo.erftverband === '1') {
          this.$store.dispatch('setUsertype', 1);
          this.$router.push({
            name: 'dashboard_erft',
            params: { username: username },
          });
        } else {
          this.$store.dispatch('setUsertype', 0);
          this.$router.push({
            name: 'dashboard',
            params: { username: username },
          });
        }
      } catch (err) {
        alert('Login war nicht erfolgreich, bitte versuchen Sie es erneut');
      }
    },
    getContentHeight() {
      return (
        this.$refs.contentWrapper.offsetHeight +
        parseInt(window.getComputedStyle(this.$refs.contentWrapper).top) +
        'px'
      );
    },
  },
};
</script>

<style scoped>
.banner__wrapper {
  position: relative;
  max-width: 2560px;
  margin-left: auto;
  margin-right: auto;
  height: calc(100vh - 64px - 56px);
  overflow: hidden;
}

.banner__wrapper::after {
  content: '';
  background: transparent
    linear-gradient(180deg, #0082ae27 0%, #0082ae6c 19%, #0083af27 100%) 0% 0%
    no-repeat padding-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.content__wrapper {
  position: absolute;
  top: 3.375rem;
  left: calc(50% - (800px / 2));
  z-index: 1;
  max-width: 100vw;
}

.content__wrapper.content__wrapper--centered {
  margin: 0px auto;
  left: 0;
  right: 0;
  width: fit-content;
}

.login__sheet {
  border-radius: 15px;
  border-top: 1.125rem solid #e09900;
  margin-top: 3.125rem;
}

.login__vid {
  min-width: 100%;
  min-height: 100%;
}

h1,
h2 {
  color: rgba(255, 255, 255, 0.5);
  text-shadow: 0, 0.1875rem, 0.375rem, #00000090;
  font-family: 'Open Sans', sans-serif;
  width: fit-content;
  margin: 0 auto;
}

h1 {
  font-size: 6.4375rem;
  line-height: 8.75rem;
}

h2 {
  font-size: 2.4375rem;
  line-height: 3.0625rem;
  font-weight: 300;
}

@media screen and (max-width: 959.98px) {
  .content__wrapper {
    top: 5rem;
  }

  h1 {
    font-size: 11vw;
    line-height: 1.1;
  }

  h2 {
    font-size: 4.25vw;
    line-height: 1.1;
  }
}
</style>
