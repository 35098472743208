<template>
  <div>
    <v-container fluid class="mt-5 ps-2 pe-2">
      <h1 class="hl">
        Willkommen im Mitarbeiter-Portal zur Erfassung von Wassernutzungen im
        Bereich des Erftverbandes, <br />{{ address + ' ' + username }}
      </h1>
      <v-sheet v-if="usertype === 1">
        <v-row class="mt-0 mb-1">
          <v-col class="d-flex align-end pb-0 flex-wrap gap" cols="12">
            <v-btn
              :color="btns.allBtnColor"
              @click="showAllForms"
              class="btn__font d-print-none"
              v-if="userInfo.nur_lesen !== '1'"
              >Alle</v-btn
            >
            <v-btn
              :color="btns.pendingBtnColor"
              @click="showPendingForms"
              class="btn__font d-print-none"
              v-if="userInfo.nur_lesen !== '1'"
              >Ausstehende</v-btn
            >
            <v-btn
              :color="btns.auditBtnColor"
              @click="showFormsToReview"
              class="btn__font d-print-none"
              v-if="userInfo.nur_lesen !== '1'"
              >Zur Prüfung</v-btn
            >
            <v-btn
              :color="btns.toApproveBtnColor"
              @click="showFormsToApprove"
              class="btn__font d-print-none"
              v-if="userInfo.nur_lesen !== '1'"
              >Zur Freigabe</v-btn
            >
            <v-btn
              :color="btns.approvedBtnColor"
              @click="showApprovedForms"
              class="btn__font d-print-none"
              >Freigegebene</v-btn
            >
          </v-col>
          <v-col cols="8" md="4" class="pt-0" offset="4" offset-md="8">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
              clearable
              class="d-print-none"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="forms"
          :search="search"
          mobile-breakpoint="600"
          :custom-sort="customSort"
          :disable-pagination="printing"
          :hide-default-footer="printing"
          :loading="tableLoading"
          :loading-text="tableLoadingText"
          no-results-text="Keine Übereinstimmung gefunden"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:item.openForm="{ item }">
            <v-tooltip bottom content-class="">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="d-print-none"
                  @click="handleRowClick(item)"
                  >mdi-eye-outline</v-icon
                >
              </template>
              <span>anzeigen</span>
            </v-tooltip>
          </template>
          <template v-slot:item.inputReceived="{ item }">
            <div class="d-flex justify-center">
              <v-checkbox
                @change="changeInputReceived(item)"
                v-model="item.inputReceived"
              ></v-checkbox>
            </div>
          </template>
          <template v-slot:item.mailHistory="{ item }">
            <v-tooltip bottom content-class="mail__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" class="d-print-none"
                  >mdi-mailbox-outline</v-icon
                >
                <div
                  class="d-none d-print-block"
                  v-html="item.mailHistoryTable"
                ></div>
              </template>
              <div v-html="item.mailHistory"></div>
            </v-tooltip>
          </template>
          <template v-slot:item.approvalHistory="{ item }">
            <v-tooltip bottom content-class="mail__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" class="d-print-none"
                  >mdi-check-decagram-outline</v-icon
                >
                <div
                  class="d-none d-print-block"
                  v-html="item.approvalHistoryTable"
                ></div>
              </template>
              <div v-html="item.approvalHistory"></div>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { getUserInfo } from '../../utils/auth';
import { mapState } from 'vuex';
import axios from '../../utils/api';
import helpers from '../helpers.js';
const {
  customSort,
  buildDashboard,
  handleRowClick,
  currentYear,
  changeInputReceived,
} = helpers;

export default {
  data: () => ({
    sortBy: 'lastchange',
    sortDesc: true,
    tableLoading: false,
    tableLoadingText: 'Formulare werden geladen',
    search: '',
    categories: [],
    address: '',
    status: '',
    mail: '',
    year: '',
    btns: {
      allBtnColor: 'primary',
      pendingBtnColor: 'primary',
      auditBtnColor: 'primary',
      toApproveBtnColor: 'primary',
      approvedBtnColor: 'primary',
    },
    headers: [
      { text: '', value: 'openForm', sortable: false },
      { text: 'Eingangskontrolle', value: 'inputReceived' },
      { text: 'Ansprechpartner*in', value: 'address' },
      { text: 'Mail', value: 'mail' },
      { text: 'Mitgliedsnummer', value: 'mitglied' },
      {
        text: 'Entnehmer',
        align: 'start',
        filterable: true,
        value: 'name',
      },
      { text: 'Kategorie', value: 'category' },
      { text: 'Erfassungsjahr', value: 'year' },
      { text: 'Status', value: 'status' },
      {
        text: 'letzte Bearbeitung',
        value: 'lastchange',
      },
      {
        text: 'zuletzt bearbeitet von',
        value: 'lastchangeuser',
      },
      { text: 'Mailhistorie', value: 'mailHistory' },
      { text: 'Historie', value: 'approvalHistory' },
    ],
    forms: [],
    userInfo: {},
  }),
  computed: {
    ...mapState({
      username: (state) => state.username,
      usertype: (state) => state.usertype,
      showNavDrawer: (state) => state.showNavDrawer,
      printing: (state) => state.printing,
      printWindowWidth: (state) => state.printWindowWidth,
      lastDbCat: (state) => state.lastDbCat,
    }),
    colWidth() {
      return this.printWindowWidth / this.headers.length;
    },
  },
  mounted() {
    this.beforeLoad();
    this.$forceUpdate();
  },
  methods: {
    highlightBtn(btn) {
      Object.keys(this.btns).forEach((key) => {
        this.btns[key] = 'primary';
      });
      this.btns[btn] = 'green';
    },
    generateTimestamp() {
      const random = Math.floor(Math.random() * 10000000);
      const timestamp = Math.floor(Date.now());
      return timestamp + random;
    },
    customSort,
    handleRowClick,
    currentYear,
    async beforeLoad() {
      this.userInfo = getUserInfo();
      this.address = this.userInfo.ANSPRECH_ANREDE;
      this.$store.dispatch('setUser', this.userInfo.ANSPRECH);
      this.mail = this.userInfo.MAIL;
      this.year = this.currentYear();

      if (this.userInfo.nur_lesen === '1') {
        await this.showApprovedForms();
      } else if (this.lastDbCat) {
        switch (this.lastDbCat) {
          case 'all':
            await this.showAllForms();
            break;
          case 'pending':
            await this.showPendingForms();
            break;
          case 'to_approve':
            await this.showFormsToApprove();
            break;
          case 'approved':
            await this.showApprovedForms();
            break;
          default:
            await this.showFormsToReview();
            break;
        }
      } else {
        await this.showFormsToReview();
      }
    },
    buildDashboard,
    async showAllForms() {
      this.status = null;
      this.tableLoading = true;

      try {
        let res = await axios({
          url: `/API-USER/dashboard?mail=${this.mail}&jahr=&usertype=${this.usertype}`,
          method: 'GET',
        });
        let data = res.data;
        console.log('res.data ', data);
        this.buildDashboard(data);
        this.highlightBtn('allBtnColor');
        this.$store.dispatch('setlastDbCat', 'all');
      } catch (err) {
        console.error('Dashboard Request Error:', err);
      }
      this.tableLoading = false;
    },
    async showFormsToReview() {
      this.status = 'pruefung';
      this.tableLoading = true;

      try {
        let res = await axios({
          url: `/API-USER/dashboard?mail=${this.mail}&jahr=${this.year}&usertype=${this.usertype}&status=${this.status}`,
          method: 'GET',
        });
        let data = res.data;
        console.log('res.data ', data);
        this.buildDashboard(data);
        this.highlightBtn('auditBtnColor');
        this.$store.dispatch('setlastDbCat', 'review');
      } catch (err) {
        console.error('Dashboard Request Error:', err);
      }
      this.tableLoading = false;
    },
    async showFormsToApprove() {
      this.status = 'erste_freigabe';
      this.tableLoading = true;

      try {
        let res = await axios({
          url: `/API-USER/dashboard?mail=${this.mail}&jahr=${this.year}&usertype=${this.usertype}&status=${this.status}`,
          method: 'GET',
        });
        let data = res.data;
        console.log('res.data ', data);
        this.buildDashboard(data);
        this.highlightBtn('toApproveBtnColor');
        this.$store.dispatch('setlastDbCat', 'to_approve');
      } catch (err) {
        console.error('Dashboard Request Error:', err);
      }
      this.tableLoading = false;
    },
    async showPendingForms() {
      this.status = 'ausstehend';
      this.tableLoading = true;

      try {
        let res = await axios({
          url: `/API-USER/dashboard?mail=${this.mail}&jahr=${this.year}&usertype=${this.usertype}&status=${this.status}`,
          method: 'GET',
        });
        let data = res.data;
        console.log('res.data ', data);
        this.buildDashboard(data);
        this.highlightBtn('pendingBtnColor');
        this.$store.dispatch('setlastDbCat', 'pending');
      } catch (err) {
        console.error('Dashboard Request Error:', err);
      }
      this.tableLoading = false;
    },
    async showApprovedForms() {
      this.status = 'freigegeben';
      this.tableLoading = true;

      try {
        let res = await axios({
          url: `/API-USER/dashboard?mail=${this.mail}&jahr=${this.year}&usertype=${this.usertype}&status=${this.status}`,
          method: 'GET',
        });
        let data = res.data;
        console.log('res.data ', data);
        this.buildDashboard(data);
        this.highlightBtn('approvedBtnColor');
        this.$store.dispatch('setlastDbCat', 'approved');
      } catch (err) {
        console.error('Dashboard Request Error:', err);
      }
      this.tableLoading = false;
    },
    changeInputReceived,
  },
};
</script>

<style scoped>
.gap {
  gap: 1rem;
}

@media (max-width: 360px) {
  .btn__font {
    font-size: 0.75rem;
  }
}
</style>
