<template>
  <v-row dense data-section="row">
    <v-col
      :cols="labelInputComponent || labelInputComponent === '' ? 12 : 6"
      :sm="!labelInputComponent && labelInputComponent !== '' ? 8 : 12"
      :lg="!labelInputComponent && labelInputComponent !== '' ? 3 : 12"
      :xl="!labelInputComponent && labelInputComponent !== '' ? 4 : 12"
      order="first"
      :class="
        !labelInputComponent && labelInputComponent !== ''
          ? 'align-self-center pe-0 mt-3 mt-lg-0'
          : ''
      "
    >
      <h3 :style="headlineStyles">{{ headline }}</h3>
      <div v-if="subline">{{ subline }}</div>
    </v-col>
    <v-col
      :cols="variantInputComponent || variantInputComponent === '' ? 4 : 6"
      :sm="variantInputComponent || variantInputComponent === '' ? 6 : 8"
      :lg="variantInputComponent || variantInputComponent === '' ? 2 : 3"
      :xl="variantInputComponent || variantInputComponent === '' ? 3 : 4"
      order="2"
      v-if="labelInputComponent || labelInputComponent === ''"
    >
      <v-text-field
        :value="labelInputComponent"
        @input="handleLabelInput"
        placeholder=""
        :class="[
          readonly
            ? ''
            : labelCommentComponent || data.labelDif === 'dif'
            ? 'orange__bg'
            : 'blue__bg__input',
        ]"
        :maxlength="labelFieldMaxLength ? labelFieldMaxLength : ''"
        persistent-hint
        :hint="
          labelCommentComponent
            ? labelHint + ' - ' + labelCommentComponent
            : labelHint
        "
        :readonly="readonly"
        data-tabindex="2"
      >
        <template
          v-slot:prepend-inner
          v-if="
            status === 'pruefung' ||
            (status !== 'bearbeitung' &&
              labelCommentComponent &&
              labelCommentComponent !== '')
          "
        >
          <v-menu
            offset-y
            :close-on-content-click="false"
            :value="labelCommentMenuComponent"
            @input="
              (e) => $emit('updateValue', fieldGroup, 'labelCommentMenu', e)
            "
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-icon
                    v-show="!printing"
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    v-if="
                      labelCommentComponent === '' || !labelCommentComponent
                    "
                    @click="
                      $emit('toggleMenu', dataComponent, 'labelCommentMenu')
                    "
                    data-tabindex="1"
                  >
                    mdi-note-edit-outline</v-icon
                  >
                  <v-icon
                    v-show="!printing"
                    v-else
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    :color="
                      labelCommentOpenedComponent || readonly ? '' : 'white'
                    "
                    @click="
                      $emit('toggleMenu', dataComponent, 'labelCommentMenu')
                    "
                    data-tabindex="1"
                    >mdi-note-text
                  </v-icon>
                </template>
                <span>Kommentar</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-textarea
                    type="text"
                    :value="labelCommentComponent"
                    @input="
                      (e) => $emit('updateValue', fieldGroup, 'labelComment', e)
                    "
                    label="Kommentar"
                    class="blue__bg__textarea"
                    :disabled="status !== 'pruefung'"
                  >
                  </v-textarea>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-btn
              class="mb-4 bemerkung__btn"
              @click="$emit('toggleMenu', dataComponent, 'labelCommentMenu')"
              >OK</v-btn
            >
          </v-menu>
        </template>
      </v-text-field>
    </v-col>
    <v-col
      cols="2"
      lg="1"
      order="2"
      v-if="variantInputComponent || variantInputComponent === ''"
    >
      <v-text-field
        :value="variantInputComponent"
        :label="variantFieldLabel"
        @input="handleVariantInput"
        placeholder=""
        :maxlength="variantFieldMaxLength ? variantFieldMaxLength : ''"
        :hint="variantHint"
        :readonly="readonly"
        :data-tabindex="
          usertype === 1 &&
          (variantInputComponent || variantInputComponent == '')
            ? 3
            : null
        "
      >
      </v-text-field>
    </v-col>
    <v-col
      cols="6"
      sm="4"
      class="justify-end d-flex note__del__col mr-lg-6"
      :order="labelInputComponent ? 3 : 2"
      order-lg="8"
      :lg="lg"
    >
      <div class="d-flex align-center pe-1">
        <v-menu
          offset-y
          :close-on-content-click="false"
          :value="bemerkungMenuComponent"
          @input="(e) => handleToggleBemerkung(e)"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  v-show="!printing"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  v-if="noteComponent === ''"
                  @click="(e) => handleToggleBemerkung(e)"
                  class="d-print-none"
                  :data-tabindex="
                    usertype === 1 && !variantInputComponent ? 8 : 7
                  "
                >
                  mdi-note-edit-outline</v-icon
                >
                <v-icon
                  v-show="!printing"
                  v-else
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  color="orange"
                  @click="(e) => handleToggleBemerkung(e)"
                  class="d-print-none"
                  :data-tabindex="
                    usertype === 1 && !variantInputComponent ? 8 : 7
                  "
                  >mdi-note-text
                </v-icon>
              </template>
              <span>Bemerkung</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-textarea
                  type="text"
                  :value="noteComponent"
                  @input="
                    (e) => $emit('updateValue', fieldGroup, noteFieldName, e)
                  "
                  label="Bemerkung"
                  class="white__bg"
                >
                </v-textarea>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-btn
            class="mb-4 bemerkung__btn"
            @click="(e) => handleToggleBemerkung(e)"
            >OK</v-btn
          >
        </v-menu>
      </div>
    </v-col>
    <v-col
      cols="12"
      :order="labelInputComponent ? 4 : 3"
      sm="4"
      class="d-lg-none"
    >
      <v-text-field
        :value="'Juli - Dez. ' + (year - 1)"
        readonly
      ></v-text-field>
    </v-col>
    <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="2"
      :order="labelInputComponent ? 5 : 4"
    >
      <v-text-field
        :value="inputsComponent[val1Name]"
        @input="(e) => $emit('updateValue', fieldGroup, val1Name, e, 'inputs')"
        :class="[
          'txt-align-end',
          readonly
            ? ''
            : value1CommentComponent || val1Dif === 'dif'
            ? 'orange__bg'
            : 'blue__bg__input',
        ]"
        suffix="m³"
        @blur="$emit('handleInputSingleRow', dataComponent, val1Name)"
        @keyup="(e) => $emit('formatDigitsOnlySingleRow', e)"
        @focusin="(e) => $emit('formatDigitsOnlySingleRow', e)"
        persistent-hint
        :hint="value1CommentComponent"
        :readonly="readonly"
        :data-tabindex="usertype === 1 && !variantInputComponent ? 5 : 4"
        maxlength="9"
      >
        <template
          v-slot:prepend-inner
          v-if="
            status === 'pruefung' ||
            (status !== 'bearbeitung' &&
              value1CommentComponent &&
              value1CommentComponent !== '')
          "
        >
          <v-menu
            offset-y
            :close-on-content-click="false"
            :value="value1CommentMenuComponent"
            @input="
              (e) => $emit('updateValue', fieldGroup, 'value1CommentMenu', e)
            "
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-icon
                    v-show="!printing"
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    v-if="
                      value1CommentComponent === '' || !value1CommentComponent
                    "
                    @click="
                      $emit('toggleMenu', dataComponent, 'value1CommentMenu')
                    "
                    :data-tabindex="
                      usertype === 1 && !variantInputComponent ? 4 : 3
                    "
                  >
                    mdi-note-edit-outline</v-icon
                  >
                  <v-icon
                    v-show="!printing"
                    v-else
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    :color="
                      value1CommentOpenedComponent || readonly ? '' : 'white'
                    "
                    @click="
                      $emit('toggleMenu', dataComponent, 'value1CommentMenu')
                    "
                    :data-tabindex="
                      usertype === 1 && !variantInputComponent ? 4 : 3
                    "
                    >mdi-note-text
                  </v-icon>
                </template>
                <span>Kommentar</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-textarea
                    type="text"
                    :value="value1CommentComponent"
                    @input="
                      (e) =>
                        $emit('updateValue', fieldGroup, 'value1Comment', e)
                    "
                    label="Kommentar"
                    class="blue__bg__textarea"
                    :disabled="status !== 'pruefung'"
                  >
                  </v-textarea>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-btn
              class="mb-4 bemerkung__btn"
              @click="$emit('toggleMenu', dataComponent, 'value1CommentMenu')"
              >OK</v-btn
            >
          </v-menu>
        </template>
      </v-text-field>
    </v-col>
    <!-- <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="3"
      :order="labelInputComponent ? 6 : 5"
      class="pr-lg-4"
    >
      <v-text-field
        disabled
        :class="val1Dif ? 'txt-align-end red__txt' : 'txt-align-end'"
        suffix="m³"
        :value="val1_ly"
      ></v-text-field>
    </v-col> -->
    <v-col
      cols="12"
      :order="labelInputComponent ? 7 : 6"
      sm="4"
      class="d-lg-none"
    >
      <v-text-field :value="'Jan. - Juni ' + year" readonly></v-text-field>
    </v-col>
    <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="4"
      :order="labelInputComponent ? 8 : 7"
    >
      <v-text-field
        :value="inputsComponent[val2Name]"
        @input="(e) => $emit('updateValue', fieldGroup, val2Name, e, 'inputs')"
        :class="[
          'txt-align-end',
          readonly
            ? ''
            : value2CommentComponent || val2Dif === 'dif'
            ? 'orange__bg'
            : 'blue__bg__input',
        ]"
        suffix="m³"
        @blur="$emit('handleInputSingleRow', dataComponent, val2Name)"
        @keyup="(e) => $emit('formatDigitsOnlySingleRow', e)"
        @focusin="(e) => $emit('formatDigitsOnlySingleRow', e)"
        persistent-hint
        :hint="value2CommentComponent"
        :readonly="readonly"
        :data-tabindex="usertype === 1 && !variantInputComponent ? 7 : 6"
        maxlength="9"
      >
        <template
          v-slot:prepend-inner
          v-if="
            status === 'pruefung' ||
            (status !== 'bearbeitung' &&
              value2CommentComponent &&
              value2CommentComponent !== '')
          "
        >
          <v-menu
            offset-y
            :close-on-content-click="false"
            :value="value2CommentMenuComponent"
            @input="
              (e) => $emit('updateValue', fieldGroup, 'value2CommentMenu', e)
            "
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-icon
                    v-show="!printing"
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    v-if="
                      value2CommentComponent === '' || !value2CommentComponent
                    "
                    @click="
                      $emit('toggleMenu', dataComponent, 'value2CommentMenu')
                    "
                    :data-tabindex="
                      usertype === 1 && !variantInputComponent ? 6 : 5
                    "
                  >
                    mdi-note-edit-outline</v-icon
                  >
                  <v-icon
                    v-show="!printing"
                    v-else
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    :color="
                      value2CommentOpenedComponent || readonly ? '' : 'white'
                    "
                    @click="
                      $emit('toggleMenu', dataComponent, 'value2CommentMenu')
                    "
                    :data-tabindex="
                      usertype === 1 && !variantInputComponent ? 6 : 5
                    "
                    >mdi-note-text
                  </v-icon>
                </template>
                <span>Kommentar</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-textarea
                    type="text"
                    :value="value2CommentComponent"
                    @input="
                      (e) =>
                        $emit('updateValue', fieldGroup, 'value2Comment', e)
                    "
                    label="Kommentar"
                    class="blue__bg__textarea"
                    :disabled="status !== 'pruefung'"
                  >
                  </v-textarea>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-btn
              class="mb-4 bemerkung__btn"
              @click="$emit('toggleMenu', dataComponent, 'value2CommentMenu')"
              >OK</v-btn
            >
          </v-menu>
        </template>
      </v-text-field>
    </v-col>
    <!-- <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="5"
      :order="labelInputComponent ? 9 : 8"
      class="pr-lg-4"
    >
      <v-text-field
        disabled
        :class="val2Dif ? 'txt-align-end red__txt' : 'txt-align-end'"
        suffix="m³"
        :value="val2_ly"
      ></v-text-field>
    </v-col> -->
    <v-col
      cols="12"
      sm="4"
      class="d-lg-none"
      :order="labelInputComponent ? 10 : 9"
    >
      <v-text-field value="Gesamt" readonly></v-text-field>
    </v-col>
    <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="6"
      :order="labelInputComponent ? 11 : 10"
    >
      <v-text-field
        :value="sumComponent"
        readonly
        :class="[
          'txt-align-end',
          readonly
            ? ''
            : (data.sumChanged || val1Dif === 'dif' || val2Dif === 'dif') &&
              status === 'pruefung'
            ? 'orange__bg'
            : '',
        ]"
        suffix="m³"
      >
      </v-text-field>
    </v-col>
    <!-- <v-col
      cols="6"
      sm="4"
      :lg="lg"
      order-lg="7"
      :order="labelInputComponent ? 12 : 11"
    >
      <v-text-field
        disabled
        class="txt-align-end"
        suffix="m³"
        :value="sum_ly"
      ></v-text-field>
    </v-col> -->
    <v-col
      v-if="
        printing &&
        ((labelCommentComponent && labelCommentComponent !== '') ||
          (value1CommentComponent && value1CommentComponent !== '') ||
          (value2CommentComponent && value2CommentComponent !== '') ||
          (noteComponent && noteComponent !== ''))
      "
      cols="12"
      order="last"
      style="padding-top: 0; margin-top: 0"
    >
      <v-row>
        <v-col cols="6" sm="8" lg="3" xl="4" order="13" order-lg="8">
          <v-text-field
            v-model="labelCommentComponent"
            hint="Kommentar Bezeichnung"
            persistent-hint
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="4" :lg="lg" order="14" order-lg="9">
          <v-text-field
            v-model="value1CommentComponent"
            hint="Kommentar 1. Halbjahr"
            persistent-hint
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          :lg="lg"
          order="15"
          order-lg="10"
          v-if="
            printing &&
            ((labelCommentComponent && labelCommentComponent !== '') ||
              (value1CommentComponent && value1CommentComponent !== '') ||
              (value2CommentComponent && value2CommentComponent !== '') ||
              (noteComponent && noteComponent !== ''))
          "
        >
          <v-text-field
            v-model="value2CommentComponent"
            hint="Kommentar 2. Halbjahr"
            persistent-hint
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="4" :lg="lg" order="16" order-lg="11">
          <v-text-field
            hint="Bemerkung"
            persistent-hint
            v-model="noteComponent"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          class="justify-end d-flex note__del__col"
          order="17"
          order-lg="12"
          :lg="lg"
        >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import helpers from '../helpers.js';
const { sumValues } = helpers;
import { mapState } from 'vuex';

export default {
  props: [
    'headline',
    'headlineStyles',
    'subline',
    'data',
    'labelComment',
    'labelHint',
    'val1Name',
    'value1Comment',
    'val1_ly',
    'val2Name',
    'value2Comment',
    'val2_ly',
    'val1Dif',
    'val2Dif',
    'sum',
    'sum_ly',
    'labelFieldName',
    'labelFieldMaxLength',
    'variantFieldName',
    'variantFieldMaxLength',
    'variantFieldLabel',
    'variantHint',
    'noteFieldName',
    'fieldGroup',
    'year',
    'lg',
    'readonly',
    'status',
  ],
  emits: [
    'handleInputSingleRow',
    'formatDigitsOnlySingleRow',
    'toggleMenu',
    'updateValue',
    'toggleBemerkung',
  ],
  computed: {
    labelInputComponent() {
      return this.data[this.labelFieldName];
    },
    labelCommentMenuComponent() {
      return this.data.labelCommentMenu;
    },
    labelCommentComponent() {
      return this.labelComment;
    },
    labelCommentOpenedComponent() {
      return this.data.labelCommentOpened;
    },
    variantInputComponent() {
      return this.data[this.variantFieldName];
    },
    bemerkungMenuComponent() {
      return this.data.bemerkungMenu;
    },
    noteComponent() {
      return this.data[this.noteFieldName];
    },
    inputsComponent() {
      return this.data.inputs;
    },
    value1CommentMenuComponent() {
      return this.data.value1CommentMenu;
    },
    value1CommentComponent() {
      return this.value1Comment;
    },
    value1CommentOpenedComponent() {
      return this.data.value1CommentOpened;
    },
    value2CommentMenuComponent() {
      return this.data.value2CommentMenu;
    },
    value2CommentComponent() {
      return this.value2Comment;
    },
    value2CommentOpenedComponent() {
      return this.data.value2CommentOpened;
    },
    sumComponent() {
      return this.sum;
    },
    dataComponent() {
      return this.data;
    },
    ...mapState({
      printing: (state) => state.printing,
      usertype: (state) => state.usertype,
    }),
  },
  methods: {
    sumValues,
    handleLabelInput(val) {
      this.$emit('updateValue', this.fieldGroup, this.labelFieldName, val);
    },
    handleVariantInput(val) {
      this.$emit('updateValue', this.fieldGroup, this.variantFieldName, val);
    },
    // handleLabelCommentMenu(val) {
    //   this.$emit('updateValue', fieldGroup, 'labelCommentMenu', val);
    // },
    handleToggleBemerkung(e) {
      console.log('event: ', e);
      this.$emit('toggleBemerkung', this.dataComponent);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
