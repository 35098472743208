<template>
  <v-sheet width="500" elevation="4" class="pa-4 mb-4" data-section="row">
    <v-checkbox
      dense
      color="primary"
      :label="checkbox1Label"
      v-model="checkbox1"
      @click="(e) => $emit('updateValue', name, checkbox1Name, checkbox1)"
      data-tabindex="1"
    ></v-checkbox>
    <v-checkbox
      dense
      color="primary"
      :label="checkbox2Label"
      v-model="checkbox2"
      @click="(e) => $emit('updateValue', name, checkbox2Name, checkbox2)"
      data-tabindex="2"
    ></v-checkbox>
    <template v-if="year === '2023'">
      <v-text-field
        :label="input6Label"
        :value="input6"
        @blur="(e) => $emit('updateValue', name, input6Name, e.target.value)"
        data-tabindex="3"
      ></v-text-field>
    </template>
    <template v-else>
      <div>veranlagte Wassermenge</div>
      <v-text-field
        :label="input1Label"
        :value="input1"
        @blur="(e) => handleStampInput(input1Name, e.target.value)"
        @keyup="(e) => formatDigitsOnly(e)"
        @focusin="(e) => formatDigitsOnly(e)"
        data-tabindex="3"
        class="form__stamp__input"
      ></v-text-field>
      <v-text-field
        :label="input2Label"
        :value="input2"
        @blur="(e) => handleStampInput(input2Name, e.target.value)"
        @keyup="(e) => formatDigitsOnly(e)"
        @focusin="(e) => formatDigitsOnly(e)"
        data-tabindex="4"
        class="form__stamp__input"
      ></v-text-field>
    </template>
    <v-checkbox
      dense
      color="primary"
      :label="checkbox3Label"
      v-model="checkbox3"
      @click="(e) => $emit('updateValue', name, checkbox3Name, checkbox3)"
      data-tabindex="4"
    ></v-checkbox>
    <v-checkbox
      dense
      color="primary"
      :label="checkbox4Label"
      v-model="checkbox4"
      @click="(e) => $emit('updateValue', name, checkbox4Name, checkbox4)"
      data-tabindex="5"
    ></v-checkbox>
    <template v-if="year === '2023'">
      <v-text-field
        :label="input7Label"
        :value="input7"
        @blur="(e) => $emit('updateValue', name, input7Name, e.target.value)"
        data-tabindex="6"
      ></v-text-field>
    </template>
    <template v-else>
      <div>abgesetzte Wassermenge</div>
      <v-text-field
        :label="input3Label"
        :value="input3"
        @blur="(e) => handleStampInput(input3Name, e.target.value)"
        @keyup="(e) => formatDigitsOnly(e)"
        @focusin="(e) => formatDigitsOnly(e)"
        data-tabindex="6"
        class="form__stamp__input"
      ></v-text-field>
      <v-select
        :label="input4Label"
        :items="input4Options"
        :value="input4"
        data-tabindex="7"
        class="form__stamp__input"
        @change="(e) => $emit('updateValue', name, input4Name, e)"
      ></v-select>
    </template>

    <v-checkbox
      dense
      color="primary"
      :label="checkbox5Label"
      v-model="checkbox5"
      @click="(e) => $emit('updateValue', name, checkbox5Name, checkbox5)"
      data-tabindex="8"
    ></v-checkbox>
    <v-text-field
      :label="input5Label"
      :value="input5"
      @blur="(e) => $emit('updateValue', name, input5Name, e.target.value)"
      data-tabindex="9"
    ></v-text-field>
  </v-sheet>
</template>

<script>
import helpers from '../helpers.js';
const { formatDigitsOnly } = helpers;
export default {
  data: () => ({
    checkbox1: null,
    checkbox1Name: null,
    checkbox2: null,
    checkbox2Name: null,
    checkbox3: null,
    checkbox3Name: null,
    checkbox4: null,
    checkbox4Name: null,
    checkbox5: null,
    checkbox5Name: null,
    input1Name: 'hydrologie',
    input2Name: 'wasserversorgung',
    input3Name: 'menge',
    input4Name: 'grund',
    input4Options: [
      { text: 'ohne Nutzung', value: 1 },
      { text: 'Rückleitung Kiessee', value: 2 },
      { text: 'Förderung eingestellt', value: 3 },
      { text: 'Nichtmitglied & Erftverbandsanlagen', value: 4 },
      { text: 'Überlauf Quellen', value: 5 },
      { text: 'Anreicherung', value: 6 },
      { text: 'Ersatzwasser < 30.000 m³', value: 7 },
    ],
    input5Name: 'naechste_befragung',
    input6Name: 'veranlagte_wassermenge',
    input7Name: 'abgesetzte_wassermenge',
  }),
  props: ['data', 'name'],
  emits: ['updateValue', 'formatDigitsOnly'],
  created() {
    this.getCheckboxValues();
  },
  computed: {
    checkbox1Label() {
      return 'weiterhin Mitglied';
    },
    checkbox2Label() {
      return 'wird zum Mitglied';
    },
    checkbox3Label() {
      return 'weiterhin Nichtmitglied';
    },
    checkbox4Label() {
      return 'wird zum Nichtmitglied';
    },
    checkbox5Label() {
      return 'Informationsschreiben';
    },
    input1() {
      return this.data['hydrologie'];
    },
    input1Label() {
      return 'Hydrologie';
    },
    input2() {
      return this.data['wasserversorgung'];
    },
    input2Label() {
      return 'Wasserversorgung';
    },
    input3() {
      return this.data['menge'];
    },
    input3Label() {
      return 'Menge';
    },
    input4() {
      return this.data['grund'];
    },
    input4Label() {
      return 'Grund';
    },
    input5() {
      return this.data['naechste_befragung'];
    },
    input5Label() {
      return 'nächste Befragung';
    },
    input6() {
      return this.data['veranlagte_wassermenge'];
    },
    input6Label() {
      return 'veranlagte Wassermenge';
    },
    input7() {
      return this.data['abgesetzte_wassermenge'];
    },
    input7Label() {
      return 'abgesetzte Wassermenge';
    },
    year() {
      return this.$route.params.year;
    },
  },
  methods: {
    getCheckboxValues() {
      this.checkbox1 = this.data['weiterhin_mitglied'];
      this.checkbox1Name = 'weiterhin_mitglied';
      this.checkbox2 = this.data['wird_zum_mitglied'];
      this.checkbox2Name = 'wird_zum_mitglied';
      this.checkbox3 = this.data['weiterhin_nichtmitglied'];
      this.checkbox3Name = 'weiterhin_nichtmitglied';
      this.checkbox4 = this.data['wird_zum_nichtmitglied'];
      this.checkbox4Name = 'wird_zum_nichtmitglied';
      this.checkbox5 = this.data['informationsschreiben'];
      this.checkbox5Name = 'informationsschreiben';
    },
    handleStampInput(inputName, val) {
      let displayVal = val.replace(/[^\d]/g, '');
      isNaN(displayVal) || displayVal === '' ? (displayVal = 0) : null;
      displayVal = parseInt(displayVal).toLocaleString('de-DE');
      this.$emit('updateValue', this.name, inputName, displayVal);
    },
    formatDigitsOnly,
  },
};
</script>

<style lang="css" scoped>
.form__stamp__input .v-select__selection--comma {
  margin: 7px 4px 6px 0;
}

@media (min-width: 600px) {
  .form__stamp__input {
    width: 50%;
    display: inline-block;
  }
}
</style>
