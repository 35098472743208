import { render, staticRenderFns } from "./ErftAlert.vue?vue&type=template&id=5c79cbe7&scoped=true&"
import script from "./ErftAlert.vue?vue&type=script&lang=js&"
export * from "./ErftAlert.vue?vue&type=script&lang=js&"
import style0 from "./ErftAlert.vue?vue&type=style&index=0&id=5c79cbe7&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c79cbe7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})
