<template>
  <div>
    <table class="table table-bordered table-condensed">
      <thead>
        <!-- Legend Row -->
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th style="text-align: end">{{ 'Juli - Dez. ' + (year - 1) }}</th>
          <th style="text-align: end">{{ 'Jan. - Juni ' + year }}</th>
          <th style="text-align: end">Gesamtzeitraum</th>
        </tr>
      </thead>
      <tbody>
        <!-- Dynamic Rows -->
        <template v-for="(row, index) in data.data">
          <tr :key="'rd_'+index">
            <td :style="row.bold ? 'font-weight: bold;' : ''">
              {{ row.label }}
            </td>
            <td>{{ row.additionalLabel }}</td>
            <td>{{ row.additionalInfo }}</td>
            <td style="text-align: end">{{ row.timeframe1 }}</td>
            <td style="text-align: end">{{ row.timeframe2 }}</td>
            <td style="text-align: end">{{ row.timeframeTotal }}</td>
          </tr>
          <tr :key="'rc_'+index" v-if="row.comment1 || row.comment2 || row.commentTotal">
            <td></td>
            <td></td>
            <td></td>
            <td style="text-align: end"><small>{{ row.comment1 }}</small></td>
            <td style="text-align: end"><small>{{ row.comment2 }}</small></td>
            <td style="text-align: end"><small>{{ row.commentTotal }}</small></td>
          </tr>
        </template>
      </tbody>
    </table>
    <table class="table table-bordered table-condensed">
      <thead>
        <th>weiterhin Mitglied</th>
        <th>wird zum Mitglied</th>
        <th><b>veranlagte Wassermenge</b> - Hydrologie</th>
        <th>Wasserversorgung</th>
        <th>weiterhin Nichtmitglied</th>
        <th>wird zum Nichtmitglied</th>
        <th>abgesetzte Wassermenge</th>
        <th>Grund</th>
        <th>Informationsschreiben</th>
        <th>nächste Befragung</th>
      </thead>
      <tbody>
        <tr>
          <td style="text-align: center;">{{ data.stampData.weiterhin_mitglied ? 'X' : '' }}</td>
          <td style="text-align: center;">{{ data.stampData.wird_zum_mitglied ? 'X' : '' }}</td>
          <td style="text-align: end;">{{ data.stampData.hydrologie }}</td>
          <td style="text-align: end;">{{ data.stampData.wasserversorgung }}</td>
          <td style="text-align: center;">{{ data.stampData.weiterhin_nichtmitglied ? 'X' : '' }}</td>
          <td style="text-align: center;">{{ data.stampData.wird_zum_nichtmitglied ? 'X' : '' }}</td>
          <td style="text-align: end;">{{ data.stampData.menge }}</td>
          <td style="text-align: end;">{{ data.stampData.grund }}</td>
          <td style="text-align: center;">{{ data.stampData.informationsschreiben ? 'X' : '' }}</td>
          <td style="text-align: end;">{{ data.stampData.naechste_befragung }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    year: String,
  },
};
</script>

<style lang="scss" scoped>
/* -- Material Design Table style -------------- */

// Variables
// ---------------------
$table-header-font-weight: 400;
$table-header-font-color: #757575;

$table-cell-padding: 1.6rem;
$table-condensed-cell-padding: $table-cell-padding/2;

$table-bg: #fff;
$table-bg-accent: #f5f5f5;
$table-bg-hover: rgba(0, 0, 0, 0.12);
$table-bg-active: $table-bg-hover;
$table-border-color: #e0e0e0;

// Mixins
// -----------------
@mixin transition($transition) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin shadow-z-1 {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}

// Tables
//
// -----------------

// Baseline styles
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  background-color: $table-bg;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      @include transition(all 0.3s ease);
      > th,
      > td {
        text-align: left;
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: 0;
        @include transition(all 0.3s ease);
      }
    }
  }
  > thead > tr > th {
    font-weight: $table-header-font-weight;
    color: $table-header-font-color;
    vertical-align: bottom;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  > tbody + tbody {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  // Nesting
  .table {
    background-color: $table-bg;
  }

  // Remove border
  .no-border {
    border: 0;
  }
}

// Condensed table w/ half padding
.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-condensed-cell-padding;
      }
    }
  }
}

// Bordered version
//
// Add horizontal borders between columns.
.table-bordered {
  border: 0;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 0;
        border-bottom: 1px solid $table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.table-striped {
  > tbody > tr:nth-child(odd) {
    > td,
    > th {
      background-color: $table-bg-accent;
    }
  }
}

// Hover effect
//
.table-hover {
  > tbody > tr:hover {
    > td,
    > th {
      background-color: $table-bg-hover;
    }
  }
}

// CSS/LESS Color variations
//
// --------------------------------

.table-striped.table-mc-red > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-red > tbody > tr:nth-child(odd) > th {
  background-color: #fde0dc;
}
.table-hover.table-mc-red > tbody > tr:hover > td,
.table-hover.table-mc-red > tbody > tr:hover > th {
  background-color: #f9bdbb;
}
.table-striped.table-mc-pink > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-pink > tbody > tr:nth-child(odd) > th {
  background-color: #fce4ec;
}
.table-hover.table-mc-pink > tbody > tr:hover > td,
.table-hover.table-mc-pink > tbody > tr:hover > th {
  background-color: #f8bbd0;
}
.table-striped.table-mc-purple > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-purple > tbody > tr:nth-child(odd) > th {
  background-color: #f3e5f5;
}
.table-hover.table-mc-purple > tbody > tr:hover > td,
.table-hover.table-mc-purple > tbody > tr:hover > th {
  background-color: #e1bee7;
}
.table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) > th {
  background-color: #ede7f6;
}
.table-hover.table-mc-deep-purple > tbody > tr:hover > td,
.table-hover.table-mc-deep-purple > tbody > tr:hover > th {
  background-color: #d1c4e9;
}
.table-striped.table-mc-indigo > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-indigo > tbody > tr:nth-child(odd) > th {
  background-color: #e8eaf6;
}
.table-hover.table-mc-indigo > tbody > tr:hover > td,
.table-hover.table-mc-indigo > tbody > tr:hover > th {
  background-color: #c5cae9;
}
.table-striped.table-mc-blue > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-blue > tbody > tr:nth-child(odd) > th {
  background-color: #e7e9fd;
}
.table-hover.table-mc-blue > tbody > tr:hover > td,
.table-hover.table-mc-blue > tbody > tr:hover > th {
  background-color: #d0d9ff;
}
.table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) > th {
  background-color: #e1f5fe;
}
.table-hover.table-mc-light-blue > tbody > tr:hover > td,
.table-hover.table-mc-light-blue > tbody > tr:hover > th {
  background-color: #b3e5fc;
}
.table-striped.table-mc-cyan > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-cyan > tbody > tr:nth-child(odd) > th {
  background-color: #e0f7fa;
}
.table-hover.table-mc-cyan > tbody > tr:hover > td,
.table-hover.table-mc-cyan > tbody > tr:hover > th {
  background-color: #b2ebf2;
}
.table-striped.table-mc-teal > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-teal > tbody > tr:nth-child(odd) > th {
  background-color: #e0f2f1;
}
.table-hover.table-mc-teal > tbody > tr:hover > td,
.table-hover.table-mc-teal > tbody > tr:hover > th {
  background-color: #b2dfdb;
}
.table-striped.table-mc-green > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-green > tbody > tr:nth-child(odd) > th {
  background-color: #d0f8ce;
}
.table-hover.table-mc-green > tbody > tr:hover > td,
.table-hover.table-mc-green > tbody > tr:hover > th {
  background-color: #a3e9a4;
}
.table-striped.table-mc-light-green > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-light-green > tbody > tr:nth-child(odd) > th {
  background-color: #f1f8e9;
}
.table-hover.table-mc-light-green > tbody > tr:hover > td,
.table-hover.table-mc-light-green > tbody > tr:hover > th {
  background-color: #dcedc8;
}
.table-striped.table-mc-lime > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-lime > tbody > tr:nth-child(odd) > th {
  background-color: #f9fbe7;
}
.table-hover.table-mc-lime > tbody > tr:hover > td,
.table-hover.table-mc-lime > tbody > tr:hover > th {
  background-color: #f0f4c3;
}
.table-striped.table-mc-yellow > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-yellow > tbody > tr:nth-child(odd) > th {
  background-color: #fffde7;
}
.table-hover.table-mc-yellow > tbody > tr:hover > td,
.table-hover.table-mc-yellow > tbody > tr:hover > th {
  background-color: #fff9c4;
}
.table-striped.table-mc-amber > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-amber > tbody > tr:nth-child(odd) > th {
  background-color: #fff8e1;
}
.table-hover.table-mc-amber > tbody > tr:hover > td,
.table-hover.table-mc-amber > tbody > tr:hover > th {
  background-color: #ffecb3;
}
.table-striped.table-mc-orange > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-orange > tbody > tr:nth-child(odd) > th {
  background-color: #fff3e0;
}
.table-hover.table-mc-orange > tbody > tr:hover > td,
.table-hover.table-mc-orange > tbody > tr:hover > th {
  background-color: #ffe0b2;
}
.table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) > th {
  background-color: #fbe9e7;
}
.table-hover.table-mc-deep-orange > tbody > tr:hover > td,
.table-hover.table-mc-deep-orange > tbody > tr:hover > th {
  background-color: #ffccbc;
}
</style>
