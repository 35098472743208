import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueRouter from '../node_modules/vue-router';
import Login from '@/components/Login.vue';
import Dashboard from '@/components/Dashboard.vue';
import Dashboard_erft from '@/components/Dashboard_erft.vue';
import Form_oeff from '@/components/Form_oeff.vue';
import Form_land from '@/components/Form_land.vue';
import Form_gew from '@/components/Form_gew.vue';
import Form_kibe from '@/components/Form_kibe.vue';
import Form_spofr from '@/components/Form_spofr.vue';
import Form_spofr_land from '@/components/Form_spofr_land.vue';
import { isLoggedIn, getUserInfo, getAuthToken } from '../utils/auth';
import UserVerwaltung from '@/components/UserVerwaltung.vue';
import UserList from '@/components/UserList.vue';
import UserPage from '@/components/UserPage.vue';
import PasswordChange from '@/components/PasswordChange.vue';
import ImprintErft from "@/components/ImprintErft.vue";
import PrivacyErft from "@/components/PrivacyErft.vue";
import axios from '../utils/api';
import ContactErft from "@/components/ContactErft.vue";

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_DEV_MODE;

Vue.use(VueRouter);

const routes = [
  {
    name: 'login',
    path: '/login', component: Login,
    meta: {
      allowAnonymous: true,
      title: 'Wasserentnahmeportal des Erftverbandes',
      metaTags: [
        {
          name: 'description',
          content: 'Eingabeportal für wasserwirtschaftliche Erfassung des Erftverbandes'
        },
      ]
    },
    beforeEnter: (to, from, next) => {
      const userInfo = getUserInfo();
      if (isLoggedIn()) {
        if (store.state.usertype === 0) {
          next({ name: 'dashboard', params: { username: userInfo.ANSPRECH } });
        } else if (store.state.usertype === 1) {
          next({ name: 'dashboard_erft', params: { username: userInfo.ANSPRECH } });
        } else if (!store.state.usertype || store.state.usertype === null) {
          if (userInfo.erftverband === '1') {
            store.dispatch('setUsertype', 1);
            next({ name: 'dashboard_erft', params: { username: userInfo.ANSPRECH } });
          } else if (userInfo.erftverband === '0') {
            store.dispatch('setUsertype', 0);
            next({ name: 'dashboard', params: { username: userInfo.ANSPRECH } });
          } else {
            next();
          }
        }
      } else {
        next();
      }
    }
  },
  {
    name: 'dashboard',
    path: '/dashboard/:username',
    component: Dashboard,
    meta: {
      title: 'Wasserentnahmeportal des Erftverbandes - Dashboard',
      metaTags: [
        {
          name: 'description',
          content: 'Dashboard des Eingabeportals für wasserwirtschaftliche Erfassung des Erftverbandes'
        },
      ]
    }
  },
  {
    name: 'dashboard_erft',
    path: '/dashboard_erft/:username',
    component: Dashboard_erft,
    meta: {
      title: 'Wasserentnahmeportal des Erftverbandes - Dashboard',
      metaTags: [
        {
          name: 'description',
          content: 'Dashboard des Eingabeportals für wasserwirtschaftliche Erfassung des Erftverbandes'
        },
      ]
    }
  },
  {
    path: '/form_oeff/:username/:year/:mode?', component: Form_oeff, name: 'form_oeff', meta: {
      title: 'Öffentliche Wasserversorgung',
      metaTags: [
        {
          name: 'description',
          content: 'Erfassung von Wassernutzungen im Bereich des Erftverbandes - Öffentliche Wasserversorgung'
        },
      ]
    },
  },
  {
    path: '/form_land/:username/:year/:mode?', component: Form_land, name: 'form_land', meta: {
      title: 'Landwirtschaft',
      metaTags: [
        {
          name: 'description',
          content: 'Erfassung von Wassernutzungen im Bereich des Erftverbandes - Landwirtschaft'
        },
      ]
    },
  },
  {
    path: '/form_gew/:username/:year/:mode?', component: Form_gew, name: 'form_gew', meta: {
      title: 'Gewerbliche Betriebe',
      metaTags: [
        {
          name: 'description',
          content: 'Erfassung von Wassernutzungen im Bereich des Erftverbandes - Gewerbliche Betriebe'
        },
      ]
    },
  },
  {
    path: '/form_kibe/:username/:year/:mode?', component: Form_kibe, name: 'form_kibe', meta: {
      title: 'Kies- und Betonwerke',
      metaTags: [
        {
          name: 'description',
          content: 'Erfassung von Wassernutzungen im Bereich des Erftverbandes - Kies- und Betonwerke'
        },
      ]
    },
  },
  {
    path: '/form_spofr/:username/:year/:mode?', component: Form_spofr, name: 'form_spofr', meta: {
      title: 'Sport-/Freizeitanlagen',
      metaTags: [
        {
          name: 'description',
          content: 'Erfassung von Wassernutzungen im Bereich des Erftverbandes - Sport-/Freizeitanlagen'
        },
      ]
    },
  },
  {
    path: '/form_spofr_land/:username/:year/:mode?', component: Form_spofr_land, name: 'form_spofr_land', meta: {
      title: 'Sport-/Freizeitanlagen',
      metaTags: [
        {
          name: 'description',
          content: 'Erfassung von Wassernutzungen im Bereich des Erftverbandes - Sport-/Freizeitanlagen'
        },
      ]
    },
  },
  { path: '/', redirect: '/login' },
  {
    path: '/userverwaltung', component: UserVerwaltung, name: 'userverwaltung', meta: {
      title: 'Benutzerverwaltung',
      metaTags: [
        {
          name: 'description',
          content: 'Benutzerverwaltung des Wasserentnahmeportals des Erftverbandes'
        },
      ]
    }
  },
  {
    path: '/userlist', component: UserList, name: 'userlist', meta: {
      title: 'Entnehmerliste',
      metaTags: [
        {
          name: 'description',
          content: 'Entnehmerliste des Eingabeportals für wasserwirtschaftliche Erfassung des Erftverbandes'
        },
      ]
    }
  },
  {
    path: '/userpage/:userno', component: UserPage, name: 'userpage', meta: {
      title: 'Entnehmerseite',
      metaTags: [
        {
          name: 'description',
          content: 'Entnehmerseite des Eingabeportals für wasserwirtschaftliche Erfassung des Erftverbandes'
        },
      ]
    }
  },
  {
    path: '/change-password', component: PasswordChange, name: 'change_password', meta: {
      title: 'Passwort ändern',
      metaTags: [
        {
          name: 'description',
          content: 'Passwort für das Eingabeportal für wasserwirtschaftliche Erfassung des Erftverbandes ändern'
        },
      ]
    }
  },
  {
    path: '/impressum', component: ImprintErft, name: 'imprint_erft', meta: {
      allowAnonymous: true,
      title: 'Impressum',
      metaTags: [
        {
          name: 'description',
          content: 'Impressum - Eingabeportal für wasserwirtschaftliche Erfassung des Erftverbandes'
        },
      ]
    },
  },
  {
    path: '/datenschutz', component: PrivacyErft, name: 'privacy_erft', meta: {
      allowAnonymous: true,
      title: 'Datenschutz',
      metaTags: [
        {
          name: 'description',
          content: 'Datenschutz - Eingabeportal für wasserwirtschaftliche Erfassung des Erftverbandes'
        },
      ]
    },
  },
  {
    path: '/kontakt', component: ContactErft, name: 'contact_erft', meta: {
      allowAnonymous: true,
      title: 'Kontakt',
      metaTags: [
        {
          name: 'description',
          content: 'Kontakt - Eingabeportal für wasserwirtschaftliche Erfassung des Erftverbandes'
        },
      ]
    },
  },
];

const router = new VueRouter({
  routes
});

// Check if logged in before each route is accessed
router.beforeEach((to, from, next) => {
  store.commit('setPruefungAlert', false);
  store.commit('setApprovedAlert', false);
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  }
  else {
    const token = getAuthToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    next();
  }
  if (to.path === '/dashboard' || (to.name === 'dashboard' && (!to.params.username || store.state.usertype !== 0))) {
    const userInfo = getUserInfo();
    const username = userInfo.ANSPRECH;
    if (!store.state.usertype || store.state.usertype === null) {
      if (userInfo.erftverband === '1') {
        store.dispatch('setUsertype', 1);
        next({ name: 'dashboard_erft', params: { username: username } });
      } else if (userInfo.erftverband === '0') {
        store.dispatch('setUsertype', 0);
        next({ name: 'dashboard', params: { username: username } });
      } else {
        next('/');
      }
    } else if (!to.params.username && store.state.usertype === 0) {
      next({ name: 'dashboard', params: { username: username } });
    } else if (store.state.usertype === 1) {
      next({ name: 'dashboard_erft', params: { username: username } });
    } else {
      next();
    }
  }
  if (to.path === '/dashboard_erft' || (to.name === 'dashboard_erft' && (!to.params.username || store.state.usertype !== 1))) {
    const userInfo = getUserInfo();
    const username = userInfo.ANSPRECH;
    if (!store.state.usertype || store.state.usertype === null) {
      if (userInfo.erftverband === '1') {
        store.dispatch('setUsertype', 1);
        next({ name: 'dashboard_erft', params: { username: username } });
      } else if (userInfo.erftverband === '0') {
        store.dispatch('setUsertype', 0);
        next({ name: 'dashboard', params: { username: username } });
      } else {
        next('/');
      }
    } else if (!to.params.username && store.state.usertype === 1) {
      next({ name: 'dashboard_erft', params: { username: username } });
    } else if (store.state.usertype === 0) {
      if (to.params.username) {
        next({ name: 'dashboard_erft', params: { username: username } });
      }
    } else {
      next();
    }
  }
  if (to.name === 'userverwaltung' || to.path === '/userverwaltung') {
    const userInfo = getUserInfo();
    if (userInfo && userInfo.benutzerverwaltung !== '1') {
      next('/');
    } else {
      next();
    }
  }
});

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
