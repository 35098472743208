<template>
  <v-container class="mt-5">
    <v-alert v-if="saveUserAlert != ''" text type="info">
      {{ saveUserAlert }}
    </v-alert>
    <h1 class="hl">Userverwaltung Erftverband Mitarbeiter</h1>
    <v-sheet>
      <v-row class="mt-0 mb-1">
        <v-col cols="8" md="4" class="pt-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="addresslist"
        :items-per-page="25"
        :loading="false"
        :search="search"
        multi-sort
        loading-text="Daten werden geladen..."
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="mb-2"
                  @click="newAddress()"
                  fab
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Nutzer hinzufügen</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{
                    isNew ? 'Neue Adresse' : 'Adresse bearbeiten'
                  }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form ref="addressForm" id="addressForm">
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="editedItem.LOGIN"
                            label="Email-Adresse"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            type="password"
                            v-model="editedItem.PASSWORD"
                            label="Passwort"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            type="password"
                            v-model="confirm_password"
                            label="Passwort wiederholen"
                            :rules="confirmPasswordRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="editedItem.ANSPRECH_ANREDE"
                            label="Anrede"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="editedItem.ANSPRECH_VN"
                            label="Vorname"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="editedItem.ANSPRECH"
                            label="Name"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-checkbox
                            v-model="editedItem.erste_freigabe"
                            label="Erste Freigabe"
                            ref="firstConf"
                          >
                          </v-checkbox>
                          <v-checkbox
                            v-model="editedItem.zweite_freigabe"
                            label="Zweite Freigabe"
                            ref="secondConf"
                          >
                          </v-checkbox>
                          <v-checkbox
                            v-model="editedItem.benutzerverwaltung"
                            label="Benutzerverwaltung"
                            ref="userMan"
                          >
                          </v-checkbox>
                          <v-checkbox
                            v-model="editedItem.nur_lesen"
                            label="Nur lesen"
                          >
                          </v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row
                        ><p style="font-weight: bold; color: red">
                          {{ message }}
                        </p></v-row
                      >
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancel">
                    Abbrechen
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save()">
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Adresse wirklich löschen?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >abbrechen</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <!--<template v-slot:item.actions="{ item }">-->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span title="Adresse bearbeiten" v-bind="attrs" v-on="on">
                <v-icon @click="editAddress(item)"> mdi-pencil </v-icon>
              </span>
            </template>
            <span>bearbeiten</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span title="Adresse löschen" v-bind="attrs" v-on="on">
                <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
              </span>
            </template>
            <span>löschen</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
import axios from '../../utils/api';
import { isLoggedIn, getUserInfo } from '../../utils/auth';
import helpers from '../helpers.js';
const { generateId } = helpers;

export default {
  name: 'UserVerwaltung',
  data: () => ({
    drawer: false,
    group: null,
    dialog_modal: false,
    addresslist: [],
    shiftPressed: false,
    previousSelected: null,
    confirm_password: '',
    message: '',
    selected: [],
    search: '',
    headers: [
      // { text: 'Mitgliedsnr.', value: 'MIMITGL', width: '10%' },
      { text: 'Login', value: 'LOGIN', width: '20%' },
      { text: 'Anrede', value: 'ANSPRECH_ANREDE', width: '5%' },
      { text: 'Vorname', value: 'ANSPRECH_VN', width: '20%' },
      { text: 'Name', value: 'ANSPRECH', width: '20%' },
      {
        text: 'Berechtigung',
        value: 'berechtigungDisplay',
        width: '5%',
        align: 'start',
      },
      { text: 'Aktionen', value: 'actions', sortable: false, width: '10%' },
    ],

    loading: true,
    dialog: false,
    dialogDelete: false,
    saveUserAlert: '',

    editedIndex: -1,
    editedItem: {
      ID: '',
      MIMITGL: '',
      LOGIN: '',
      PASSWORD: '',
      ANSPRECH_ANREDE: '',
      ANSPRECH_VN: '',
      ANSPRECH: '',
      berechtigung: false,
      erste_freigabe: false,
      zweite_freigabe: false,
      benutzerverwaltung: false,
      nur_lesen: false,
    },
    defaultItem: {
      ID: '',
      MIMITGL: '',
      LOGIN: '',
      PASSWORD: '',
      ANSPRECH_ANREDE: '',
      ANSPRECH_VN: '',
      ANSPRECH: '',
      erste_freigabe: false,
      zweite_freigabe: false,
      benutzerverwaltung: false,
      nur_lesen: false,
    },
  }),

  computed: {
    isNew() {
      return this.editedIndex === -1;
    },

    confirmPasswordRules() {
      return [
        (v) =>
          v === this.editedItem.PASSWORD || 'Passwörter stimmen nicht überein',
      ];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    'editedItem.nur_lesen': function (newVal) {
      if (newVal === true) {
        this.editedItem.erste_freigabe = false;
        this.editedItem.zweite_freigabe = false;
        this.editedItem.benutzerverwaltung = false;
        this.$refs.firstConf.disabled = true;
        this.$refs.secondConf.disabled = true;
        this.$refs.userMan.disabled = true;
      } else if (newVal === false) {
        this.$refs.firstConf.disabled = false;
        this.$refs.secondConf.disabled = false;
        this.$refs.userMan.disabled = false;
      }
    },
    deep: true,
  },

  closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  },

  methods: {
    generateId,
    getUserInfo,
    isLoggedIn,
    async loadAddressList() {
      try {
        let res = await axios({
          url: `/API-USER/showErftuser`,
          method: 'GET',
        });
        console.log('res.data ', res);
        let addressList = [];
        for (let i = 0; i < res.data.length; i++) {
          const el = res.data[i];
          let berechtigungDisplay = '';
          let ersteFreigabe = false;
          let zweiteFreigabe = false;
          let userManagement = false;
          let readOnly = false;
          if (el.erste_freigabe === '1') {
            berechtigungDisplay += 'Erste Freigabe ';
            ersteFreigabe = true;
          }
          if (el.zweite_freigabe === '1') {
            berechtigungDisplay += 'Zweite Freigabe ';
            zweiteFreigabe = true;
          }
          if (el.benutzerverwaltung === '1') {
            berechtigungDisplay += 'Benutzerverwaltung';
            userManagement = true;
          }
          if (el.nur_lesen === '1') {
            berechtigungDisplay += 'Nur lesen';
            readOnly = true;
          }

          addressList.push({
            ANSPRECH: el.ANSPRECH,
            ANSPRECH_ANREDE: el.ANSPRECH_ANREDE,
            ANSPRECH_VN: el.ANSPRECH_VN,
            ID: el.ID,
            LOGIN: el.LOGIN,
            MIMITGL: el.MIMITGL,
            PASSWORD: el.PASSWORD,
            berechtigungDisplay: berechtigungDisplay,
            erste_freigabe: ersteFreigabe,
            zweite_freigabe: zweiteFreigabe,
            benutzerverwaltung: userManagement,
            nur_lesen: readOnly,
          });
        }
        this.addresslist = addressList;
      } catch (err) {
        console.error('Addresslist Request Error:', err);
      }
    },

    editAddress(item) {
      this.editedIndex = this.addresslist.indexOf(item);
      console.log('item: ', item);
      this.editedItem = Object.assign({}, item);
      this.open();
    },

    newAddress() {
      this.editedIndex = -1;
      this.dialog = true;
      this.editedItem = { ...this.defaultItem };
    },

    cancel() {
      this.close();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.confirm_password = '';
      this.$refs.addressForm.resetValidation();
    },

    deleteItem(item) {
      this.editedIndex = this.addresslist.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      const params = new URLSearchParams({
        user: this.editedItem.MIMITGL,
      }).toString();
      const url = '/API-USER/deleteErftuser?' + params;

      axios
        .get(url, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          console.log('response: ', response);
          this.loadAddressList();
        })
        .catch((error) => {
          console.log('error: ', error);
        });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (
        !document.querySelector('#addressForm').reportValidity() ||
        !this.$refs.addressForm.validate()
      ) {
        return;
      }

      this.saveUserAlert = '';

      const params = new URLSearchParams({
        id: this.editedItem.ID,
        mode: this.isNew ? 'new' : 'edit',
      }).toString();
      const url = '/API-USER/saveErftuser?' + params;
      const saveObj = this.editedItem;
      if (saveObj['berechtigung'] === false) {
        saveObj['berechtigung'] = 0;
      } else if (saveObj['berechtigung'] === true) {
        saveObj['berechtigung'] = 1;
      }
      if (saveObj['erste_freigabe'] === false) {
        saveObj['erste_freigabe'] = 0;
      } else if (saveObj['erste_freigabe'] === true) {
        saveObj['erste_freigabe'] = 1;
      }
      if (saveObj['zweite_freigabe'] === false) {
        saveObj['zweite_freigabe'] = 0;
      } else if (saveObj['zweite_freigabe'] === true) {
        saveObj['zweite_freigabe'] = 1;
      }
      if (saveObj['benutzerverwaltung'] === false) {
        saveObj['benutzerverwaltung'] = 0;
      } else if (saveObj['benutzerverwaltung'] === true) {
        saveObj['benutzerverwaltung'] = 1;
      }
      if (saveObj['nur_lesen'] === false) {
        saveObj['nur_lesen'] = 0;
      } else if (saveObj['nur_lesen'] === true) {
        if (
          saveObj['erste_freigabe'] === 1 ||
          saveObj['zweite_freigabe'] === 1 ||
          saveObj['benutzerverwaltung'] === 1
        ) {
          this.saveUserAlert = `Der Benutzer konnte nicht gespeichert werden. Wenn "nur lesen" ausgewählt ist, dürfen keine anderen Berechtigungen ausgewählt sein.`;
          return;
        }
        saveObj['nur_lesen'] = 1;
      }
      if (this.isNew) {
        saveObj['MIMITGL'] = this.generateId('erft/', 11);
      }
      console.log('saveObj: ', saveObj);
      axios
        .post(url, saveObj, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          console.log('response: ', response);
          if (response.data.message) {
            this.message = response.data.message;
          } else {
            this.loadAddressList();
            this.close();
          }
        });
      this.close();
      this.loadAddressList();
    },

    keyUp: function () {
      this.shiftPressed = false;
    },
    keyDown: function () {
      this.shiftPressed = true;
    },
  },
  mounted() {
    this.loadAddressList();
  },
};
</script>
