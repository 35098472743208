<template>
  <v-container fluid class="d-flex justify-center">
    <v-sheet
      :max-width="this.$vuetify.breakpoint.width < 600 ? '80%' : '580px'"
    >
      <v-form
        @submit.prevent="handleChangePassword"
        ref="changePwForm"
        :value="valid"
        lazy-validation
      >
        <v-text-field
          v-model="newPw"
          :error-messages="newPwErrors"
          class="mt-4 mb-4"
          clearable
          id="newPw"
          :append-icon="showNewPw ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showNewPw = !showNewPw"
          :type="showNewPw ? 'text' : 'password'"
          label="Neues Passwort"
          :hint="newPwHint"
          :rules="[
            rules.length,
            rules.specialChar,
            rules.capitalLetter,
            rules.digit,
            rules.space,
            rules.noPlus,
          ]"
        >
          <template v-slot:message="{ message, key }">
            <div v-html="message" :key="key"></div>
          </template>
        </v-text-field>
        <v-text-field
          v-model="pwConfirmation"
          :rules="[validatePasswordConfirmation]"
          class="mt-4 mb-4"
          clearable
          id="pwConfirmation"
          :append-icon="ShowPwConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="ShowPwConfirmation = !ShowPwConfirmation"
          :type="ShowPwConfirmation ? 'text' : 'password'"
          label="Neues Passwort wiederholen"
        >
        </v-text-field>
        <div class="d-flex justify-center">
          <v-btn type="submit" color="primary" rounded :disabled="!valid"
            >Passwort ändern</v-btn
          >
        </div>
      </v-form>
      <v-alert class="mt-4" v-if="successMsg" type="success"
        >Das Passwort wurde geändert!</v-alert
      >
      <v-alert class="mt-4" v-if="errMsg" type="error"
        >Das Passwort konnte nicht geändert werden, bitte versuchen Sie es
        erneut. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an
        den Erftverband.</v-alert
      >
    </v-sheet>
  </v-container>
</template>

<script>
import axios from '../../utils/api';
export default {
  data: () => ({
    successMsg: false,
    errMsg: false,
    newPw: null,
    pwConfirmation: null,
    showNewPw: false,
    ShowPwConfirmation: false,
    newPwHint:
      'Mindestens 8 Zeichen <br>Nur Kleinbuchstaben <br>Mindestens eine Ziffer (0-9) <br>Mindestens ein Sonderzeichen (! - * @ _ ) <br>Kein Leerzeichen <br>Kein + ',
    newPwErrors: null,
    rules: {
      length: (v) => (v && v.length >= 8) || 'Mindestens 8 Zeichen',
      specialChar: (v) =>
        new RegExp(/[!\-\*@_]/, 'g').test(v) ||
        'Mindestens ein Sonderzeichen (! - * @ _ )',
      capitalLetter: (v) =>
        !new RegExp('[A-Z]', 'g').test(v) || 'Nur Kleinbuchstaben',
      digit: (v) =>
        new RegExp(/\d/, 'g').test(v) || 'Mindestens eine Ziffer (0-9)',
      space: (v) => !new RegExp(' ', 'g').test(v) || 'Kein Leerzeichen',
      noPlus: (v) => !new RegExp(/\+/, 'g').test(v) || 'Kein +',
    },
  }),
  methods: {
    async handleChangePassword() {
      this.successMsg = false;
      this.errMsg = false;
      if (this.$refs.changePwForm.validate()) {
        this.$store.dispatch('setLoadingTrue');
        let formData = new FormData();
        formData.append('pw_neu', this.newPw);
        try {
          await axios.post('/API-USER/PWaendern', formData);
          this.successMsg = true;
        } catch (err) {
          console.error(err);
          this.errMsg = true;
        }
        this.$store.dispatch('setLoadingFalse');
      }
    },
    validatePassword(input) {
      let result = false;
      if (input) {
        result = true;
        if (input.length < 8) {
          result = false;
        }
        const specialCharRegex = new RegExp(/[!\-\*@_]/, 'g');
        if (!specialCharRegex.test(input)) {
          result = false;
        }
        const capitalLettersRegex = new RegExp('[A-Z]', 'g');
        if (capitalLettersRegex.test(input)) {
          result = false;
        }
        const digitRegex = new RegExp(/\d/, 'g');
        if (!digitRegex.test(input)) {
          result = false;
        }
        if (input.includes(' ')) {
          result = false;
        }
        if (input.includes('+')) {
          result = false;
        }
      }
      return result;
    },
    validatePasswordConfirmation() {
      if (this.newPw === this.pwConfirmation) {
        return true;
      } else {
        return 'Die eingegebenen Passwörter stimmen nicht überein';
      }
    },
  },
  computed: {
    valid() {
      if (
        this.validatePassword(this.newPw) === true &&
        this.validatePasswordConfirmation(this.pwConfirmation) === true
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
