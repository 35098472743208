var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('h1',{staticClass:"hl"},[_vm._v(" Willkommen im Portal zur "),_c('br',{staticClass:"d-none d-lg-block"}),_vm._v("Erfassung von Wassernutzungen im Bereich des Erftverbandes, "),_c('br'),_vm._v(_vm._s(_vm.address + ' ' + _vm.username)+" ")]),_c('v-sheet',{staticClass:"pa-12 mt-8",attrs:{"elevation":"1"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"justify-end d-flex"},[_c('a',{attrs:{"href":_vm.hrefAttr,"rel":"noreferrer","target":"_blank"}},[_vm._v("E-Mail an den Erftverband")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('h3',[_vm._v("Ihre Formulare")])])],1),_vm._l((_vm.categories),function(category){return _c('v-container',{key:category.id,staticClass:"ps-0",attrs:{"fluid":""}},[_c('h4',[_vm._v(_vm._s(category.name))]),_vm._l((category.betriebe),function(betrieb){return _c('v-row',{key:betrieb.name,attrs:{"dense":""}},[(Object.keys(betrieb.forms).length > 0)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"4"}},[_c('span',[_vm._v(_vm._s(betrieb.name)+" ("+_vm._s(betrieb.mitglied)+")")])]):_vm._e(),_vm._l((betrieb.forms),function(form){return _c('v-col',{key:form.id,staticClass:"d-flex"},[_c('v-btn',{staticClass:"form__btn",attrs:{"color":form.status === 'pruefung' || form.status === 'erste_freigabe'
                  ? 'primary'
                  : form.status === 'freigegeben'
                  ? 'green'
                  : form.status === 'korrektur'
                  ? 'error'
                  : 'nicht ausgefüllt'
                  ? ''
                  : ''},on:{"click":function($event){return _vm.handleFormClick(form)}}},[_vm._v(" "+_vm._s(form.year)+" ")]),_c('v-btn',{attrs:{"depressed":"","icon":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var tooltip = ref.on;
return [_c('v-icon',_vm._g({},Object.assign({}, tooltip)),[_vm._v("mdi-calendar-edit ")])]}}],null,true)},[_c('span',[_vm._v("zuletzt bearbeitet: "+_vm._s(form.lastChanged))]),(form.lastChangedUser)?_c('span',[_vm._v(" von: "+_vm._s(form.lastChangedUser))]):_vm._e()])],1),_c('v-btn',{attrs:{"depressed":"","icon":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var tooltip = ref.on;
return [_c('v-icon',_vm._g({},Object.assign({}, tooltip)),[_vm._v(" mdi-email-fast-outline ")])]}}],null,true)},[_c('div',{domProps:{"innerHTML":_vm._s(form.mailHistory)}})])],1)],1)})],2)})],2)}),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"#f5f5f5"}},[_vm._v("mdi-circle")]),_c('span',[_vm._v(" = nicht ausgefüllt")])],1),_c('v-col',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-circle")]),_c('span',[_vm._v(" = in Prüfung")])],1),_c('v-col',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-circle")]),_c('span',[_vm._v(" = Korrektur erforderlich")])],1),_c('v-col',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-circle")]),_c('span',[_vm._v(" = abgeschlossen")])],1),_c('v-col',{staticClass:"d-flex align-center"},[_c('v-icon',[_vm._v("mdi-calendar-edit")]),_c('span',[_vm._v(" = letzte Bearbeitung")])],1)],1)],2),_c('v-divider',{staticClass:"mt-5 mb-5"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }