<template>
  <div>
    <v-container class="mt-5">
      <h1 class="hl">Entnehmerliste</h1>
      <v-sheet>
        <v-row class="mt-0 mb-1">
          <v-col cols="8" md="4" class="pt-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          mobile-breakpoint="600"
          @click:row="handleRowClickUserList"
          :loading="tableLoading"
          loading-text="Entnehmer werden geladen"
          no-results-text="Keine Übereinstimmung gefunden"
        >
        </v-data-table>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { getUserInfo } from '../../utils/auth';
import { mapState } from 'vuex';
import axios from '../../utils/api';
import helpers from '../helpers.js';
const { currentYear, toUser } = helpers;
export default {
  data: () => ({
    tableLoading: false,
    mail: '',
    year: '',
    search: '',
    users: [],
    headers: [
      { text: 'Mitgliedsnummer', value: 'MIMITGL' },
      { text: 'Firma', value: 'FIRMA1' },
      {
        text: 'Ansprechpartner*in',
        align: 'start',
        filterable: true,
        value: 'name',
      },
      { text: 'Mail', value: 'LOGIN' },
    ],
  }),
  computed: {
    ...mapState({
      usertype: (state) => state.usertype,
    }),
  },
  mounted() {
    this.beforeLoad();
    this.$forceUpdate();
  },
  methods: {
    buildList(data) {
      data.forEach((el) => {
        let name = '';
        if (el.ANSPRECH_ANREDE && el.ANSPRECH) {
          name = el.ANSPRECH_ANREDE + ' ' + el.ANSPRECH;
        } else if (
          (!el.ANSPRECH_ANREDE || el.ANSPRECH_ANREDE === '') &&
          el.ANSPRECH
        ) {
          name = el.ANSPRECH;
        }
        const userObj = {
          name,
          LOGIN: el.LOGIN,
          MIMITGL: el.MIMITGL,
          FIRMA1: el.FIRMA1,
        };
        this.users.push(userObj);
      });
    },
    toUser,
    generateTimestamp() {
      const random = Math.floor(Math.random() * 10000000);
      const timestamp = Math.floor(Date.now());
      return timestamp + random;
    },
    currentYear,
    async beforeLoad() {
      const userInfo = getUserInfo();
      const username = userInfo.ANSPRECH;
      const address = userInfo.ANSPRECH_ANREDE;
      this.address = address;
      this.$store.dispatch('setUser', username);
      this.mail = userInfo.MAIL;
      this.year = this.currentYear();

      await this.showAllUsers();
    },
    handleRowClickUserList(item) {
      this.toUser(item);
    },
    async showAllUsers() {
      this.status = '';
      this.tableLoading = true;
      try {
        let res = await axios({
          url: `/API-USER/userlist`,
          method: 'GET',
        });
        let data = res.data;
        console.log('res.data ', data);
        this.buildList(data);
      } catch (err) {
        console.error('UserList Request Error:', err);
      }
      this.tableLoading = false;
    },
  },
};
</script>

<style></style>
