var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex justify-center",attrs:{"fluid":""}},[_c('v-sheet',{attrs:{"max-width":this.$vuetify.breakpoint.width < 600 ? '80%' : '580px'}},[_c('v-form',{ref:"changePwForm",attrs:{"value":_vm.valid,"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleChangePassword.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mt-4 mb-4",attrs:{"error-messages":_vm.newPwErrors,"clearable":"","id":"newPw","append-icon":_vm.showNewPw ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showNewPw ? 'text' : 'password',"label":"Neues Passwort","hint":_vm.newPwHint,"rules":[
          _vm.rules.length,
          _vm.rules.specialChar,
          _vm.rules.capitalLetter,
          _vm.rules.digit,
          _vm.rules.space,
          _vm.rules.noPlus ]},on:{"click:append":function($event){_vm.showNewPw = !_vm.showNewPw}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
        var message = ref.message;
        var key = ref.key;
return [_c('div',{key:key,domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.newPw),callback:function ($$v) {_vm.newPw=$$v},expression:"newPw"}}),_c('v-text-field',{staticClass:"mt-4 mb-4",attrs:{"rules":[_vm.validatePasswordConfirmation],"clearable":"","id":"pwConfirmation","append-icon":_vm.ShowPwConfirmation ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.ShowPwConfirmation ? 'text' : 'password',"label":"Neues Passwort wiederholen"},on:{"click:append":function($event){_vm.ShowPwConfirmation = !_vm.ShowPwConfirmation}},model:{value:(_vm.pwConfirmation),callback:function ($$v) {_vm.pwConfirmation=$$v},expression:"pwConfirmation"}}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"type":"submit","color":"primary","rounded":"","disabled":!_vm.valid}},[_vm._v("Passwort ändern")])],1)],1),(_vm.successMsg)?_c('v-alert',{staticClass:"mt-4",attrs:{"type":"success"}},[_vm._v("Das Passwort wurde geändert!")]):_vm._e(),(_vm.errMsg)?_c('v-alert',{staticClass:"mt-4",attrs:{"type":"error"}},[_vm._v("Das Passwort konnte nicht geändert werden, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an den Erftverband.")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }