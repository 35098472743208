var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":"","data-section":"row"}},[_c('v-col',{class:!_vm.labelInputComponent && _vm.labelInputComponent !== ''
        ? 'align-self-center pe-0 mt-3 mt-lg-0'
        : '',attrs:{"cols":_vm.labelInputComponent || _vm.labelInputComponent === '' ? 12 : 6,"sm":!_vm.labelInputComponent && _vm.labelInputComponent !== '' ? 8 : 12,"lg":!_vm.labelInputComponent && _vm.labelInputComponent !== '' ? 3 : 12,"xl":!_vm.labelInputComponent && _vm.labelInputComponent !== '' ? 4 : 12,"order":"first"}},[_c('h3',{style:(_vm.headlineStyles)},[_vm._v(_vm._s(_vm.headline))]),(_vm.subline)?_c('div',[_vm._v(_vm._s(_vm.subline))]):_vm._e()]),(_vm.labelInputComponent || _vm.labelInputComponent === '')?_c('v-col',{attrs:{"cols":_vm.variantInputComponent || _vm.variantInputComponent === '' ? 4 : 6,"sm":_vm.variantInputComponent || _vm.variantInputComponent === '' ? 6 : 8,"lg":_vm.variantInputComponent || _vm.variantInputComponent === '' ? 2 : 3,"xl":_vm.variantInputComponent || _vm.variantInputComponent === '' ? 3 : 4,"order":"2"}},[_c('v-text-field',{class:[
        _vm.readonly
          ? ''
          : _vm.labelCommentComponent || _vm.data.labelDif === 'dif'
          ? 'orange__bg'
          : 'blue__bg__input' ],attrs:{"value":_vm.labelInputComponent,"placeholder":"","maxlength":_vm.labelFieldMaxLength ? _vm.labelFieldMaxLength : '',"persistent-hint":"","hint":_vm.labelCommentComponent
          ? _vm.labelHint + ' - ' + _vm.labelCommentComponent
          : _vm.labelHint,"readonly":_vm.readonly,"data-tabindex":"2"},on:{"input":_vm.handleLabelInput},scopedSlots:_vm._u([(
          _vm.status === 'pruefung' ||
          (_vm.status !== 'bearbeitung' &&
            _vm.labelCommentComponent &&
            _vm.labelCommentComponent !== '')
        )?{key:"prepend-inner",fn:function(){return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"value":_vm.labelCommentMenuComponent},on:{"input":function (e) { return _vm.$emit('updateValue', _vm.fieldGroup, 'labelCommentMenu', e); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
        var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [(
                    _vm.labelCommentComponent === '' || !_vm.labelCommentComponent
                  )?_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.printing),expression:"!printing"}],attrs:{"data-tabindex":"1"},on:{"click":function($event){return _vm.$emit('toggleMenu', _vm.dataComponent, 'labelCommentMenu')}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-note-edit-outline")]):_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.printing),expression:"!printing"}],attrs:{"color":_vm.labelCommentOpenedComponent || _vm.readonly ? '' : 'white',"data-tabindex":"1"},on:{"click":function($event){return _vm.$emit('toggleMenu', _vm.dataComponent, 'labelCommentMenu')}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v("mdi-note-text ")])]}}],null,true)},[_c('span',[_vm._v("Kommentar")])])]}}],null,false,1015514261)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-textarea',{staticClass:"blue__bg__textarea",attrs:{"type":"text","value":_vm.labelCommentComponent,"label":"Kommentar","disabled":_vm.status !== 'pruefung'},on:{"input":function (e) { return _vm.$emit('updateValue', _vm.fieldGroup, 'labelComment', e); }}})],1)],1)],1),_c('v-btn',{staticClass:"mb-4 bemerkung__btn",on:{"click":function($event){return _vm.$emit('toggleMenu', _vm.dataComponent, 'labelCommentMenu')}}},[_vm._v("OK")])],1)]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.variantInputComponent || _vm.variantInputComponent === '')?_c('v-col',{attrs:{"cols":"2","lg":"1","order":"2"}},[_c('v-text-field',{attrs:{"value":_vm.variantInputComponent,"label":_vm.variantFieldLabel,"placeholder":"","maxlength":_vm.variantFieldMaxLength ? _vm.variantFieldMaxLength : '',"hint":_vm.variantHint,"readonly":_vm.readonly,"data-tabindex":_vm.usertype === 1 &&
        (_vm.variantInputComponent || _vm.variantInputComponent == '')
          ? 3
          : null},on:{"input":_vm.handleVariantInput}})],1):_vm._e(),_c('v-col',{staticClass:"justify-end d-flex note__del__col mr-lg-6",attrs:{"cols":"6","sm":"4","order":_vm.labelInputComponent ? 3 : 2,"order-lg":"8","lg":_vm.lg}},[_c('div',{staticClass:"d-flex align-center pe-1"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"value":_vm.bemerkungMenuComponent},on:{"input":function (e) { return _vm.handleToggleBemerkung(e); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var menu = ref.on;
          var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var tooltip = ref.on;
return [(_vm.noteComponent === '')?_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.printing),expression:"!printing"}],staticClass:"d-print-none",attrs:{"data-tabindex":_vm.usertype === 1 && !_vm.variantInputComponent ? 8 : 7},on:{"click":function (e) { return _vm.handleToggleBemerkung(e); }}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-note-edit-outline")]):_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.printing),expression:"!printing"}],staticClass:"d-print-none",attrs:{"color":"orange","data-tabindex":_vm.usertype === 1 && !_vm.variantInputComponent ? 8 : 7},on:{"click":function (e) { return _vm.handleToggleBemerkung(e); }}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v("mdi-note-text ")])]}}],null,true)},[_c('span',[_vm._v("Bemerkung")])])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-textarea',{staticClass:"white__bg",attrs:{"type":"text","value":_vm.noteComponent,"label":"Bemerkung"},on:{"input":function (e) { return _vm.$emit('updateValue', _vm.fieldGroup, _vm.noteFieldName, e); }}})],1)],1)],1),_c('v-btn',{staticClass:"mb-4 bemerkung__btn",on:{"click":function (e) { return _vm.handleToggleBemerkung(e); }}},[_vm._v("OK")])],1)],1)]),_c('v-col',{staticClass:"d-lg-none",attrs:{"cols":"12","order":_vm.labelInputComponent ? 4 : 3,"sm":"4"}},[_c('v-text-field',{attrs:{"value":'Juli - Dez. ' + (_vm.year - 1),"readonly":""}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","lg":_vm.lg,"order-lg":"2","order":_vm.labelInputComponent ? 5 : 4}},[_c('v-text-field',{class:[
        'txt-align-end',
        _vm.readonly
          ? ''
          : _vm.value1CommentComponent || _vm.val1Dif === 'dif'
          ? 'orange__bg'
          : 'blue__bg__input' ],attrs:{"value":_vm.inputsComponent[_vm.val1Name],"suffix":"m³","persistent-hint":"","hint":_vm.value1CommentComponent,"readonly":_vm.readonly,"data-tabindex":_vm.usertype === 1 && !_vm.variantInputComponent ? 5 : 4,"maxlength":"9"},on:{"input":function (e) { return _vm.$emit('updateValue', _vm.fieldGroup, _vm.val1Name, e, 'inputs'); },"blur":function($event){return _vm.$emit('handleInputSingleRow', _vm.dataComponent, _vm.val1Name)},"keyup":function (e) { return _vm.$emit('formatDigitsOnlySingleRow', e); },"focusin":function (e) { return _vm.$emit('formatDigitsOnlySingleRow', e); }},scopedSlots:_vm._u([(
          _vm.status === 'pruefung' ||
          (_vm.status !== 'bearbeitung' &&
            _vm.value1CommentComponent &&
            _vm.value1CommentComponent !== '')
        )?{key:"prepend-inner",fn:function(){return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"value":_vm.value1CommentMenuComponent},on:{"input":function (e) { return _vm.$emit('updateValue', _vm.fieldGroup, 'value1CommentMenu', e); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
        var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [(
                    _vm.value1CommentComponent === '' || !_vm.value1CommentComponent
                  )?_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.printing),expression:"!printing"}],attrs:{"data-tabindex":_vm.usertype === 1 && !_vm.variantInputComponent ? 4 : 3},on:{"click":function($event){return _vm.$emit('toggleMenu', _vm.dataComponent, 'value1CommentMenu')}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-note-edit-outline")]):_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.printing),expression:"!printing"}],attrs:{"color":_vm.value1CommentOpenedComponent || _vm.readonly ? '' : 'white',"data-tabindex":_vm.usertype === 1 && !_vm.variantInputComponent ? 4 : 3},on:{"click":function($event){return _vm.$emit('toggleMenu', _vm.dataComponent, 'value1CommentMenu')}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v("mdi-note-text ")])]}}],null,true)},[_c('span',[_vm._v("Kommentar")])])]}}],null,false,1041467881)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-textarea',{staticClass:"blue__bg__textarea",attrs:{"type":"text","value":_vm.value1CommentComponent,"label":"Kommentar","disabled":_vm.status !== 'pruefung'},on:{"input":function (e) { return _vm.$emit('updateValue', _vm.fieldGroup, 'value1Comment', e); }}})],1)],1)],1),_c('v-btn',{staticClass:"mb-4 bemerkung__btn",on:{"click":function($event){return _vm.$emit('toggleMenu', _vm.dataComponent, 'value1CommentMenu')}}},[_vm._v("OK")])],1)]},proxy:true}:null],null,true)})],1),_c('v-col',{staticClass:"d-lg-none",attrs:{"cols":"12","order":_vm.labelInputComponent ? 7 : 6,"sm":"4"}},[_c('v-text-field',{attrs:{"value":'Jan. - Juni ' + _vm.year,"readonly":""}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","lg":_vm.lg,"order-lg":"4","order":_vm.labelInputComponent ? 8 : 7}},[_c('v-text-field',{class:[
        'txt-align-end',
        _vm.readonly
          ? ''
          : _vm.value2CommentComponent || _vm.val2Dif === 'dif'
          ? 'orange__bg'
          : 'blue__bg__input' ],attrs:{"value":_vm.inputsComponent[_vm.val2Name],"suffix":"m³","persistent-hint":"","hint":_vm.value2CommentComponent,"readonly":_vm.readonly,"data-tabindex":_vm.usertype === 1 && !_vm.variantInputComponent ? 7 : 6,"maxlength":"9"},on:{"input":function (e) { return _vm.$emit('updateValue', _vm.fieldGroup, _vm.val2Name, e, 'inputs'); },"blur":function($event){return _vm.$emit('handleInputSingleRow', _vm.dataComponent, _vm.val2Name)},"keyup":function (e) { return _vm.$emit('formatDigitsOnlySingleRow', e); },"focusin":function (e) { return _vm.$emit('formatDigitsOnlySingleRow', e); }},scopedSlots:_vm._u([(
          _vm.status === 'pruefung' ||
          (_vm.status !== 'bearbeitung' &&
            _vm.value2CommentComponent &&
            _vm.value2CommentComponent !== '')
        )?{key:"prepend-inner",fn:function(){return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"value":_vm.value2CommentMenuComponent},on:{"input":function (e) { return _vm.$emit('updateValue', _vm.fieldGroup, 'value2CommentMenu', e); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
        var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [(
                    _vm.value2CommentComponent === '' || !_vm.value2CommentComponent
                  )?_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.printing),expression:"!printing"}],attrs:{"data-tabindex":_vm.usertype === 1 && !_vm.variantInputComponent ? 6 : 5},on:{"click":function($event){return _vm.$emit('toggleMenu', _vm.dataComponent, 'value2CommentMenu')}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-note-edit-outline")]):_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.printing),expression:"!printing"}],attrs:{"color":_vm.value2CommentOpenedComponent || _vm.readonly ? '' : 'white',"data-tabindex":_vm.usertype === 1 && !_vm.variantInputComponent ? 6 : 5},on:{"click":function($event){return _vm.$emit('toggleMenu', _vm.dataComponent, 'value2CommentMenu')}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v("mdi-note-text ")])]}}],null,true)},[_c('span',[_vm._v("Kommentar")])])]}}],null,false,1113857802)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-textarea',{staticClass:"blue__bg__textarea",attrs:{"type":"text","value":_vm.value2CommentComponent,"label":"Kommentar","disabled":_vm.status !== 'pruefung'},on:{"input":function (e) { return _vm.$emit('updateValue', _vm.fieldGroup, 'value2Comment', e); }}})],1)],1)],1),_c('v-btn',{staticClass:"mb-4 bemerkung__btn",on:{"click":function($event){return _vm.$emit('toggleMenu', _vm.dataComponent, 'value2CommentMenu')}}},[_vm._v("OK")])],1)]},proxy:true}:null],null,true)})],1),_c('v-col',{staticClass:"d-lg-none",attrs:{"cols":"12","sm":"4","order":_vm.labelInputComponent ? 10 : 9}},[_c('v-text-field',{attrs:{"value":"Gesamt","readonly":""}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","lg":_vm.lg,"order-lg":"6","order":_vm.labelInputComponent ? 11 : 10}},[_c('v-text-field',{class:[
        'txt-align-end',
        _vm.readonly
          ? ''
          : (_vm.data.sumChanged || _vm.val1Dif === 'dif' || _vm.val2Dif === 'dif') &&
            _vm.status === 'pruefung'
          ? 'orange__bg'
          : '' ],attrs:{"value":_vm.sumComponent,"readonly":"","suffix":"m³"}})],1),(
      _vm.printing &&
      ((_vm.labelCommentComponent && _vm.labelCommentComponent !== '') ||
        (_vm.value1CommentComponent && _vm.value1CommentComponent !== '') ||
        (_vm.value2CommentComponent && _vm.value2CommentComponent !== '') ||
        (_vm.noteComponent && _vm.noteComponent !== ''))
    )?_c('v-col',{staticStyle:{"padding-top":"0","margin-top":"0"},attrs:{"cols":"12","order":"last"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"8","lg":"3","xl":"4","order":"13","order-lg":"8"}},[_c('v-text-field',{attrs:{"hint":"Kommentar Bezeichnung","persistent-hint":""},model:{value:(_vm.labelCommentComponent),callback:function ($$v) {_vm.labelCommentComponent=$$v},expression:"labelCommentComponent"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","lg":_vm.lg,"order":"14","order-lg":"9"}},[_c('v-text-field',{attrs:{"hint":"Kommentar 1. Halbjahr","persistent-hint":""},model:{value:(_vm.value1CommentComponent),callback:function ($$v) {_vm.value1CommentComponent=$$v},expression:"value1CommentComponent"}})],1),(
          _vm.printing &&
          ((_vm.labelCommentComponent && _vm.labelCommentComponent !== '') ||
            (_vm.value1CommentComponent && _vm.value1CommentComponent !== '') ||
            (_vm.value2CommentComponent && _vm.value2CommentComponent !== '') ||
            (_vm.noteComponent && _vm.noteComponent !== ''))
        )?_c('v-col',{attrs:{"cols":"6","sm":"4","lg":_vm.lg,"order":"15","order-lg":"10"}},[_c('v-text-field',{attrs:{"hint":"Kommentar 2. Halbjahr","persistent-hint":""},model:{value:(_vm.value2CommentComponent),callback:function ($$v) {_vm.value2CommentComponent=$$v},expression:"value2CommentComponent"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"4","lg":_vm.lg,"order":"16","order-lg":"11"}},[_c('v-text-field',{attrs:{"hint":"Bemerkung","persistent-hint":""},model:{value:(_vm.noteComponent),callback:function ($$v) {_vm.noteComponent=$$v},expression:"noteComponent"}})],1),_c('v-col',{staticClass:"justify-end d-flex note__del__col",attrs:{"cols":"6","sm":"4","order":"17","order-lg":"12","lg":_vm.lg}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }