import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#007ba5',
        secondary: '#e09900',
        error: '#cf4607',
        green: '#95ab22',
        orange: '#f49600',
      },
      dark: {
        primary: '#007ba5',
        secondary: '#e09900',
        error: '#cf4607',
        green: '#95ab22',
        orange: '#f49600',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
});
